import React, { useState } from 'react';
import { connect } from 'react-redux';
import { selectActiveRecEvent } from '../../actions/recommendationsActions';
import { Grid, MobileStepper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LargeEventCard from './LargeEventCard';

const useStyles = makeStyles((theme) => ({
  root: {
    overflowX: 'hidden',
    paddingBottom: '8px',
    '& .MuiMobileStepper-root': {
      justifyContent: 'center',
      background: 'unset',
    },
    '& .MuiMobileStepper-dot': {
      width: '4px',
      height: '4px',
      backgroundColor: '#E0E0E0',
    },
    '& .MuiMobileStepper-dotActive': {
      width: '8px',
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const CardCarousel = (props) => {
  const { events, isMemoryless, activeEventIdxRedux } = props;
  const [activeTouchX, setActiveTouchX] = useState(null);
  const [activeTouchId, setActiveTouchId] = useState(null);
  const [activeEventIdxMemoryless, setActiveEventIdxMemoryless] = useState(0);

  const classes = useStyles();
  const SWIPE_LENGTH = 50;

  let activeEventIdx;
  activeEventIdx = isMemoryless
    ? activeEventIdxMemoryless
    : activeEventIdxRedux;

  const handleTouchStart = (e) => {
    if (activeTouchId) return;
    setActiveTouchX(e.changedTouches[0].pageX);
    setActiveTouchId(e.changedTouches[0].identifier);
  };

  const handleTouchMove = (idx, e) => {
    e.preventDefault();
    let touch = e.changedTouches[0];
    if (touch.identifier === activeTouchId) {
      if (touch.pageX - activeTouchX > SWIPE_LENGTH && idx > 0) {
        if (isMemoryless) setActiveEventIdxMemoryless(idx - 1);
        else props.selectActiveRecEvent(idx - 1);
      } else if (
        touch.pageX - activeTouchX < -1 * SWIPE_LENGTH &&
        idx < events.length - 1
      ) {
        if (isMemoryless) setActiveEventIdxMemoryless(idx + 1);
        else props.selectActiveRecEvent(idx + 1);
      }
    }
  };

  const handleTouchEnd = (e) => {
    setActiveTouchId(null);
  };

  const handlers = {
    click: props.selectActiveRecEvent,
    touchStart: handleTouchStart,
    touchMove: handleTouchMove,
    touchEnd: handleTouchEnd,
    touchCancel: handleTouchEnd,
  };

  const gridStyles = {
    transform: `translateX(calc(13vw - (${activeEventIdx} * 83vw)))`,
    transition: 'transform ease-out 0.25s',
    padding: '8px 0px 8px 0px',
  };

  let eventCards;
  if (events) {
    eventCards = events.map((item, idx) => (
      <LargeEventCard
        key={`large-carousel-card-${idx}`}
        itemId={item.eventId}
        idx={idx}
        handlers={handlers}
        downloadUrl={item.imageUrl}
      />
    ));
  } else {
    eventCards = null;
  }

  return (
    <div className={classes.root}>
      <Grid
        direction="row"
        justify="flex-start"
        wrap="nowrap"
        container
        spacing={2}
        style={gridStyles}
      >
        {eventCards}
      </Grid>
      <MobileStepper
        variant="dots"
        steps={events ? events.length : 0}
        position="static"
        activeStep={activeEventIdx}
      />
    </div>
  );
};

export default connect(
  ({ firebase, recommendations }) => ({
    activeEventIdxRedux: recommendations.activeEventIdx,
    //activeEvent: recommendations.activeEvent,
    //uid: firebase.auth?.uid,
  }),
  { selectActiveRecEvent }
)(CardCarousel);
