import React from 'react';
import TwineLytics from '../../twineLytics';
import { Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { ReactComponent as HeartIcon } from '../shared/images/heart.svg';
import { getCalendarLink } from '../utils';
import Paper from '@material-ui/core/Paper';
import { ReactComponent as GoogleGIcon } from './google.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    background: (props) => (props.disabled ? 'darkgray' : 'unset'),
    margin: `0px ${theme.spacing(2)}px`,
  },
  buttons: {
    padding: theme.spacing(2),
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
  },
  popup: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      height: '20px',
    },
    '& .MuiButton-root': {
      width: '100%',
      textTransform: 'none',
      backgroundColor: '#f1f1f1',
    },
  },
  button: {
    width: '93px',
  },
}));

const FullEngagementButtons = (props) => {
  const {
    uninterestedHandler,
    interestedHandler,
    goingHandler,
    isUninterested,
    isInterested,
  } = props;
  const { disabled, event, zoomLink, isGoing } = props;
  const tl = new TwineLytics();

  const CalendarPopup = ({ className }) => (
    <Paper className={className}>
      <Button
        onClick={() => tl.logEvent('add_to_calendar')}
        component="a"
        target="_blank"
        rel="noopener noreferrer"
        href={getCalendarLink(event, zoomLink)}
        variant="contained"
        color="default"
        startIcon={<GoogleGIcon />}
      >
        Add To Your Calendar
      </Button>
    </Paper>
  );

  const classes = useStyles(props);
  return (
    <Paper className={classes.root} style={{}}>
      {/*isGoing*/ false ? <CalendarPopup className={classes.popup} /> : null}
      <div className={classes.buttons}>
        <Paper>
          <Button
            onClick={uninterestedHandler(isUninterested)}
            variant={isUninterested ? 'contained' : 'outlined'}
            color={isUninterested ? 'secondary' : 'default'}
            disabled={disabled}
            className={classes.button}
          >
            <CloseIcon />
          </Button>
        </Paper>
        <Paper>
          <Button
            onClick={interestedHandler(isInterested)}
            variant={isInterested ? 'contained' : 'outlined'}
            color={isInterested ? 'secondary' : 'default'}
            disabled={disabled}
            className={classes.button}
          >
            <HeartIcon fill="none" />
          </Button>
        </Paper>
        <Paper>
          <Button
            onClick={goingHandler(isGoing)}
            color={isGoing ? 'secondary' : 'default'}
            variant={isGoing ? 'contained' : 'outlined'}
            disabled={disabled}
            className={classes.button}
          >
            <DoneIcon id="DoneIcon" style={{ display: 'block' }} />
            <div id="goingStatement"></div>
          </Button>
        </Paper>
      </div>
    </Paper>
  );
};
export default FullEngagementButtons;
