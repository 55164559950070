import React from 'react';
import ProfileHeader from '../shared/ProfileHeader';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import TabHeader from '../TabHeader';
import CardCarousel from '../CardCarousel';
import { filterByFriend, sortEventsByStartTime } from '../utils';
import { Drawer } from '@material-ui/core';
import UnfriendConfirmation from './UnfriendConfirmation';

const OtherProfile = (props) => {
  const {
    otherUserProfile = {},
    profile,
    eventsMap,
    showUnfriendConfirm,
  } = props;
  let filteredEvents, title;
  const isFriends = profile.friends?.includes(otherUserProfile.uid);
  const allEvents = [];
  if (eventsMap)
    Object.keys(eventsMap).forEach((eventId) =>
      allEvents.push(eventsMap[eventId])
    );
  if (isFriends) {
    filteredEvents = allEvents?.filter(
      filterByFriend(
        profile?.events,
        otherUserProfile?.events,
        otherUserProfile?.firstName
      )
    );
    sortEventsByStartTime(filteredEvents);
    title =
      otherUserProfile &&
      `Recommended Events with ${otherUserProfile.firstName}`;
  } else {
    title = `Become friends with ${otherUserProfile.firstName} to see event recommendations.`;
  }
  return (
    <div className="otherProfile" style={{ height: '100%', width: '100%' }}>
      <TabHeader backButton />
      <ProfileHeader uid={otherUserProfile.uid} />
      <h3>{title}</h3>
      {isFriends ? (
        <CardCarousel events={filteredEvents} isMemoryless={true} />
      ) : null}
      <Drawer
        anchor="bottom"
        open={showUnfriendConfirm}
        PaperProps={{ square: false }}
      >
        <UnfriendConfirmation
          uid={profile.uid}
          friendUid={otherUserProfile.uid}
          friendName={otherUserProfile.firstName}
        />
      </Drawer>
    </div>
  );
};

export default compose(
  firestoreConnect(({ match }) => [
    { collection: 'users', doc: match.params.uid },
  ]),
  connect(({ firebase, firestore, ui }, { match }) => ({
    eventsMap: firestore?.composite?.visibleEvents,
    profile: firebase.profile,
    otherUserProfile: firestore?.data?.users?.[match.params.uid],
    showUnfriendConfirm: ui.showUnfriendConfirm,
  }))
)(OtherProfile);
