import { makeStyles } from '@material-ui/core/styles';

export const makeOrganizationDetailsStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
  },
  content: {
    margin: `0px ${theme.spacing(3)}px`,
    '& h3': {
      margin: `${theme.spacing(3)}px 0px 0px 0px`,
      lineHeight: '20px',
      fontWeight: '500',
    },
    '& p': {
      margin: `${theme.spacing(0.5)}px 0px 0px 0px`,
      lineHeight: '20px',
    },
    '& .MuiCardContent-root p': {
      margin: 'unset',
      lineHeight: 'unset',
    },
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
  leadershipCards: {
    '& .MuiCard-root': {
      marginTop: theme.spacing(1),
    },
  },
}));
