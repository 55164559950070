import { analytics } from 'firebase/app';

// Flag for logging call to Twinelytics
const LOG_DEV_ANALYTICS = true;

export default class TwineLytics {
    constructor() {
        if (process.env.NODE_ENV !== 'development') {
            this.ga = analytics();
        }
    }

    logEvent(eventName, eventParams, options) {
        if (process.env.NODE_ENV !== 'development') {
            return this.ga.logEvent(eventName, eventParams, options);
        }
        if (LOG_DEV_ANALYTICS) {
            console.log('logEvent called with', eventName, eventParams, options);
        }
    }
}