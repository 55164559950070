/**
 * Recommender/index.js
 * Container that generates event recommendation for current user
 *
 * Minh Lam
 * 5/22/2020
 */

import { useSelector } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';

/** returns an array of tuples [eventId,score] */
export const useRecommend = (uid) => {
  useFirestoreConnect([{ collection: 'users', doc: uid }]);

  const friends =
    useSelector(({ firestore }) => firestore.data.users?.[uid]?.friends) ?? [];

  useFirestoreConnect([
    ...friends.map((friendId) => ({
      collection: 'users',
      doc: friendId,
    })),
  ]);

  const behaviorRec = useSelector(
    (state) => state.firestore.data.users?.[uid]?.events?.recommended
  );
  const users = useSelector((state) => state.firestore.data.users);
  const socialRec = getSocialRec(friends, users);
  const interests = useSelector(
    (state) => state.firestore.data.users?.[uid]?.interests
  );

  const eventsMap = useSelector(
    (state) => state.firestore?.composite?.visibleEvents
  );
  const interestRec = eventsMap ? getInterestRec(interests, eventsMap) : [];
  const toReturn = combineRec(behaviorRec, socialRec, interestRec);
  return filterNonExistent(toReturn, eventsMap);
};

const filterNonExistent = (eventTuples, eventsMap = {}) => {
  const eventIds = Object.keys(eventsMap);
  return eventTuples.filter((tuple) => eventIds.includes(tuple[0]));
};

/**
 * Returns an Object of {event: n} where each event have n interested friends
 * Note: If the friend's records cannot be found, she does not impact the recommendation
 * @param {uid[]} friendUids array of uids
 * @param {User[]} users array of Users
 */
const getSocialRec = (friendUids, users) => {
  const toReturn = {};
  friendUids.forEach((friendUid) => {
    const interested = users[friendUid]?.events?.interested || [];
    if (!users[friendUid]) return;
    interested
      .map((event) => event.trim()) // Remove white spaces on the edges
      .forEach((event) =>
        toReturn[event] ? toReturn[event]++ : (toReturn[event] = 1)
      );
    const going = users[friendUid]?.events?.going || [];
    going
      .map((event) => event.trim()) // Remove white spaces on the edges
      .forEach((event) =>
        toReturn[event] ? toReturn[event]++ : (toReturn[event] = 1)
      );
  });
  return toReturn;
};

/**
 * returns an array of event IDs with attributes that match are in the inputted interests
 * @param {string[]} interests
 * @param {Object} eventsMap a hashmap of Events
 */
const getInterestRec = (interests = [], eventsMap) => {
  const toReturn = [];
  Object.entries(eventsMap).forEach(([id, event]) => {
    const attributes = event?.filters?.attributes || [];
    const departments = event?.filters?.departments || [];
    const filters = attributes.concat(departments);
    for (const filter of filters) {
      if (interests.includes(filter)) {
        toReturn.push(id);
        return;
      }
    }
  });
  if (toReturn.length > 30)
    console.error(
      `Interest Rec is not supposed to be longer than 30 items. There's ${toReturn} recs`
    );
  return toReturn;
};

/**
 * Returns the combined recommendation array
 * @param {string[]} behaviorRec
 * @param {Object} socialRec {eventId: interestedfriends}
 * @param {string[]} interestRec
 */
export const combineRec = (
  behaviorRec = [],
  socialRec = {},
  interestRec = []
) => {
  let nonSocialRec = [...interestRec, ...behaviorRec];
  socialRec = { ...socialRec }; // Creating a shallow copy
  nonSocialRec = nonSocialRec.filter(
    (rec, i) => nonSocialRec.indexOf(rec) === i
  ); // Remove duplicates
  const topRecs = []; // topRecs is an array of tuples [id, friendsCount] and
  // will contain recs that are in both social and non-social
  Object.entries(socialRec).forEach(([id, count]) => {
    if (nonSocialRec.includes(id)) {
      topRecs.push([id, count]);
      delete socialRec[id];
      nonSocialRec.splice(nonSocialRec.indexOf(id), 1);
    }
  });
  topRecs.sort((rec1, rec2) => rec2[1] - rec1[1]);

  const socialRecArr = Object.entries(socialRec);
  socialRecArr.sort((rec1, rec2) => rec2[1] - rec1[1]);

  return topRecs
    .concat(socialRecArr)
    .concat(nonSocialRec.map((rec) => [rec, 0]));
};
