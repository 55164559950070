import React, { useState } from 'react';
import TabHeader from '../TabHeader';
import { connect } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import BigPrimaryButton from '../shared/BigPrimaryButton';
import { useHistory } from 'react-router-dom';
import TagsPicker from '../Onboarding/TagsPicker';
import { getLocationObj } from '../utils';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    width: '100%',
    position: 'fixed',
    bottom: '48px',
    pointerEvents: 'none',
    '& button': {
      pointerEvents: 'auto',
    },
  },
}));

const EditInterests = (props) => {
  const { profile } = props;
  const history = useHistory();
  const [selectedTags, setSelectedTags] = useState({});
  const firestore = useFirestore();

  const [profileIsLoaded, setProfileIsLoaded] = useState(false);
  if (profile.isLoaded && !profileIsLoaded) {
    const profileTags = profile.interests ?? [];
    const tagsToUpdate = {};
    profileTags.forEach((attr) => (tagsToUpdate[attr] = true));
    setSelectedTags({
      ...selectedTags,
      ...tagsToUpdate,
    });
    setProfileIsLoaded(true);
  }

  const handleUpdateInterests = async (attrs) => {
    try {
      await firestore.update(
        { collection: 'users', doc: profile.uid },
        {
          interests: attrs,
        }
      );
      console.log('Interests added');
    } catch (error) {
      console.log('Error adding interests', error.toString());
    }
  };

  const updateProfile = () => {
    const attrs = [];
    for (let attr in selectedTags) {
      if (selectedTags[attr]) attrs.push(attr);
    }
    handleUpdateInterests(attrs).then(() =>
      history.push(getLocationObj('/profile'))
    );
  };

  const classes = useStyles();
  return (
    <div>
      <TabHeader text="Tags" backButton />
      <TagsPicker
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <div className={classes.buttonWrapper}>
        <BigPrimaryButton
          variant="contained"
          color="primary"
          onClick={updateProfile}
        >
          Finish!
        </BigPrimaryButton>
      </div>
    </div>
  );
};

export default connect(({ firebase }) => ({
  profile: firebase.profile,
}))(EditInterests);
