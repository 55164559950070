import React from 'react';
import { connect } from 'react-redux';
import CardCarousel from '../CardCarousel';
import { useRecommend } from '../Recommender';
import { makeStyles } from '@material-ui/core';

const MAX_EVENT_COUNT = 20;

const useStyles = makeStyles((theme) => ({
  carouselTitle: {
    position: 'relative',
    fontSize: '16px',
    textAlign: 'left',
    display: 'block',
    margin: '0px',
    paddingLeft: '8vw',
  },
}));

const getEventFilter = (profile) => (event) =>
  !profile?.events?.uninterested?.includes(event.eventId);

const orderEvents = (events, recommendation) => {
  const recommendedEventsSet = new Set();
  // Sort events with the same score by start time
  recommendation.sort((rec1, rec2) => {
    const compare = rec2[1] - rec1[1];
    if (compare !== 0) return compare;
    else
      return (
        events[rec1[0]]?.time.start.seconds -
        events[rec2[0]]?.time.start.seconds
      );
  });
  const toReturn = recommendation.map(
    (rec) => recommendedEventsSet.add(rec[0]) && events[rec[0]]
  );
  Object.keys(events).forEach(
    (eventId) =>
      recommendedEventsSet.has(eventId) || toReturn.push(events[eventId])
  );
  return toReturn.slice(0, MAX_EVENT_COUNT - 1);
};

const RecommendedEventsCarousel = (props) => {
  const { profile, eventsMap, uid } = props;
  const recommendation = useRecommend(uid);
  let orderedEvents = [];
  const removeUninterestedHiddenEvents = getEventFilter(profile);
  if (eventsMap) orderedEvents = orderEvents(eventsMap, recommendation);
  orderedEvents = orderedEvents.filter(removeUninterestedHiddenEvents);
  const classes = useStyles();
  return (
    <div>
      <h2 className={classes.carouselTitle}>Recommended For You</h2>
      <CardCarousel isMemoryless={false} events={orderedEvents} />
    </div>
  );
};

export default connect(({ firebase, firestore, recommendations }) => ({
  activeEvent: recommendations.activeEvent,
  activeEventIdx: recommendations.activeEventIdx,
  eventsMap: firestore?.composite?.visibleEvents,
  uid: firebase.auth?.uid,
  profile: firebase.profile,
}))(RecommendedEventsCarousel);
