import React, { useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import AttendeesViewer from './AttendeesViewer';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AvatarGroup } from '@material-ui/lab';
import { generateAvatarColor, generateUserSlices } from '../utils';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  avatarsDiv: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& .MuiAvatarGroup-root': {
      marginRight: theme.spacing(1),
    },
    '& p': {
      margin: '0px',
      fontSize: '12px',
      lineHeight: '16px',
    },
  },
  avatar: {
    width: '24px',
    height: '24px',
    fontSize: '12px',
  },
}));

const DisabledDiv = styled.div`
  background: darkgray;
  width: 100%;
  padding: 5px 0px;
`;

const innerTextSwitch = (firstNames) => [
  'Be the first of your friends to respond!',
  `${firstNames[0]}`,
  `${firstNames[0]} and ${firstNames[1]}`,
  `${firstNames[0]}, ${firstNames[1]}, and ${firstNames[2]}`,
  `${firstNames[0]}, ${firstNames[1]}, ${firstNames[2]}, +${
    firstNames.length - 3
  } other friends`,
];

function PeekRespondents(props) {
  const { friends, allUsers = {}, event } = props;
  const { disabled, hasTitle, disableOnClick } = props;
  const [showRespondents, toggleShowRespondents] = useState(false);
  const classes = useStyles();

  const isFriend = (uid) => friends.includes(uid);

  const goingUsers = event?.goingUsers ?? [];
  const interestedUsers = event?.interestedUsers ?? [];
  const respondents = goingUsers.concat(interestedUsers);

  const goingFriends = goingUsers.filter(isFriend);
  const interestedFriends = interestedUsers.filter(isFriend);
  const respondedFriends = goingFriends.concat(interestedFriends);
  const friendSlices = generateUserSlices(respondedFriends, allUsers);

  let numPeople = hasTitle ? respondedFriends.length : respondents.length;
  let innerText;
  if (hasTitle) {
    const firstNames = friendSlices.map((slice) => slice.firstName);
    innerText = innerTextSwitch(firstNames)[numPeople];
  } else {
    if (numPeople === 1) innerText = `1 person responded`;
    else innerText = `${numPeople} people responded`;
  }

  const friendAvatars = friendSlices
    .sort((a, b) => {
      if (a.photoUrl && b.photoUrl) return 0;
      else if (a.photoUrl) return -1;
      else return 1;
    })
    .slice(0, 3)
    .map((slice) => {
      let initials = '';
      if (slice.firstName) initials += slice.firstName.charAt(0);
      if (slice.lastName) initials += slice.lastName.charAt(0);
      return (
        <Avatar
          src={slice.photoUrl ? slice.photoUrl : null}
          key={slice.uid}
          alt={`${slice.firstName} avatar`}
          className={classes.avatar}
          style={{ backgroundColor: generateAvatarColor(slice.uid) }}
        >
          {initials}
        </Avatar>
      );
    });

  if (disabled) {
    return (
      <DisabledDiv>
        <h3>Sorry!</h3>
        <p>You will need to sign in to see friends here.</p>
      </DisabledDiv>
    );
  }

  const handleClick = () => {
    if (!showRespondents && !disableOnClick) toggleShowRespondents(true);
  };

  return (
    <div>
      {hasTitle ? (
        <h3>{`${respondents.length} ${
          respondents.length === 1 ? 'Person' : 'People'
        } Responded`}</h3>
      ) : null}
      <div className={classes.avatarsDiv} onClick={handleClick}>
        {friendAvatars.length > 0 ? (
          <AvatarGroup spacing="small">{friendAvatars}</AvatarGroup>
        ) : null}
        <p>{innerText}</p>
      </div>
      <AttendeesViewer
        goingUsers={goingFriends}
        interestedUsers={interestedFriends}
        open={showRespondents}
        toggle={toggleShowRespondents}
      />
    </div>
  );
}

PeekRespondents.propTypes = {
  eventId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  hasTitle: PropTypes.bool,
  disableOnClick: PropTypes.bool,
};

const mapStateToProps = ({ firebase, firestore }, { eventId }) => {
  return {
    friends: firebase.profile?.friends ?? [],
    event: firestore.data.events?.[eventId] ?? {},
    allUsers: firestore.data.meta?.users,
  };
};
export default connect(mapStateToProps)(PeekRespondents);
