import React from 'react';
import OrganizationCard from '../shared/OrganizationCard';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `6px ${theme.spacing(3)}px 0px ${theme.spacing(3)}px`,
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateColumns: 'auto auto',
    gridRowGap: '8px',
    gridColumnGap: '8px',
    justifyContent: 'space-evenly',
    justifyItems: 'center',
    alignItems: 'center',
  },
}));

const sortOrgAlphabetically = (a, b) => {
  a = a.name.toUpperCase();
  b = b.name.toUpperCase();
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

// TODO(CP): These tabs should be the same tabs as in SocialTab/PeopleViewer
function OrgsList(props) {
  const { orgs, orgsToNumEvents } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {orgs.sort(sortOrgAlphabetically).map(({ name, orgId, imageUrl }) => (
        <LazyLoad height={50} offset={100} key={orgId} once>
          <OrganizationCard
            name={name}
            key={orgId}
            orgId={orgId}
            imageSrc={imageUrl}
            orangeHighlight={orgsToNumEvents?.[orgId] ? true : false}
          />
        </LazyLoad>
      ))}
    </div>
  );
}

OrgsList.propTypes = {
  orgs: PropTypes.arrayOf(
    PropTypes.shape({
      orgId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
};

export default OrgsList;
