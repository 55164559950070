import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  DivWithPseudos,
  makeCarouselStyles,
  StyledDisabledDiv,
} from '../EventsTab/styles';
import SmallEventCard from './SmallEventCard';
import PropTypes from 'prop-types';
import OrganizationCard from './OrganizationCard';

const DisabledDiv = () => (
  <StyledDisabledDiv>
    <h3>Sorry!</h3>
    <p>You'll have to sign in to see this content.</p>
  </StyledDisabledDiv>
);

const useStyles = makeCarouselStyles;

const SmallCardCarousel = (props) => {
  const { type, disabled, linkText, linkUrl } = props;
  const { title, items, headerAvatar, showIfEmpty, itemImageUrls } = props;
  const ref = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollLeft = scrollLeft;
    }
  }, [scrollLeft, title]);

  const handleScroll = (e) => {
    setScrollLeft(e.target.scrollLeft);
  };

  if (type === 'organization' && itemImageUrls === undefined) {
    throw new Error('Argument itemImageUrls is missing for type organization');
  }

  const classes = useStyles(props);
  // TODO(CP): Should display placeholder here
  if (items.length === 0 && !showIfEmpty) return null;

  return (
    <div className={classes.root}>
      <div className={classes.carouselHeader}>
        <div className={classes.headerTitleDiv}>
          <div className={classes.headerAvatar}>{headerAvatar || null}</div>
          <h2>{title}</h2>
        </div>
        {linkUrl ? <Link to={linkUrl}>{linkText ?? 'See More'}</Link> : null}
      </div>
      <DivWithPseudos
        className={classes.gridContainer}
        onScroll={handleScroll}
        ref={ref}
      >
        {type === 'events'
          ? items.map((eventId) => (
              <SmallEventCard
                key={eventId}
                eventId={eventId}
                type="SmallCardCarousel"
              />
            ))
          : items.map((org) => (
              <OrganizationCard
                key={org.orgId}
                name={org.name}
                orgId={org.orgId}
                imageSrc={org.imageUrl}
              />
            ))}
      </DivWithPseudos>
      {disabled ? <DisabledDiv /> : null}
    </div>
  );
};

SmallCardCarousel.propTypes = {
  type: PropTypes.oneOf(['events', 'orgs']).isRequired,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  headerAvatar: PropTypes.element,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  showIfEmpty: PropTypes.bool,
};

export default SmallCardCarousel;
