import React from 'react';
import { ReactComponent as VirtualIcon } from './images/computer.svg';
import { ReactComponent as MembersIcon } from './images/members.svg';
import { ReactComponent as InPersonIcon } from './images/map-pin.svg';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
  ribbonContainer: {
    display: 'flex',
  },
  ribbonSmall: {
    flex: 1,
    maxWidth: '24px',
    height: '28px',
    flexFlow: 'row',
    justifyContent: 'center',
    marginRight: '6px',
    padding: '6px 4px',
    boxSizing: 'border-box',
    borderRadius: '0px 0px 2px 2px',
  },
  ribbonLarge: {
    position: 'relative',
    flex: 1,
    marginLeft: '8px',
    borderRadius: '2px',
    height: '32px',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '-0.25px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-around',
    padding: '8px',
    boxSizing: 'border-box',
    '& svg': {
      height: '16px',
      width: '16px',
      position: 'absolute',
      left: '8px',
      bottom: '8px',
      stroke: 'white',
    },
    '& span': {
      position: 'absolute',
      bottom: '8px',
      right: '8px',
    },
  },
});

const RibbonArray = (props) => {
  const ribbonArray = props.ribbonArray;
  const eventCardType = props.eventCardType;
  const classes = useStyles(props);
  let background, typeLabel, maxWidth;

  const RibbonIcon = (props) => {
    const { type } = props;
    switch (type) {
      case 'web':
        return <VirtualIcon />;
      case 'place':
        return <InPersonIcon />;
      case 'organization':
        return <MembersIcon />;
      default:
    }
  };

  const setIcon = (icon, eventCardType) => {
    if (eventCardType === 'large' || eventCardType === 'header') {
      switch (icon) {
        case 'web':
          typeLabel = 'Virtual';
          background = '#2f80ed';
          maxWidth = '75px';
          break;
        case 'place':
          typeLabel = 'In-Person';
          background = '#f2c94c';
          maxWidth = '93px';
          break;
        case 'organization':
          typeLabel = 'Members-only';
          background = '#FF521E';
          maxWidth = '120px';
          break;
        default:
      }
      return (
        <div
          className={classes.ribbonLarge}
          style={{ background: background, maxWidth: maxWidth }}
          key={icon}
        >
          <RibbonIcon type={icon} />
          <span>{typeLabel}</span>
        </div>
      );
    } else {
      switch (icon) {
        case 'web':
          background = '#2f80ed';
          break;
        case 'place':
          background = '#f2c94c';
          break;
        case 'organization':
          background = '#FF521E';
          break;
        default:
      }
      return (
        <div
          className={classes.ribbonSmall}
          style={{ background: background }}
          key={icon}
        >
          <RibbonIcon key={icon} type={icon} />
        </div>
      );
    }
  };
  return (
    <div className={classes.ribbonContainer}>
      {ribbonArray.map((icon) => setIcon(icon, eventCardType))}
    </div>
  );
};

RibbonArray.propTypes = {
  eventCardType: PropTypes.oneOf(['large', 'header', 'small']).isRequired,
};

export default RibbonArray;
