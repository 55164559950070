// Specifying action types allowed in the app

/** Redux action: User marks "Going", "Interested", or "Show me Less" on an event*/
export const ENGAGE_EVENT = 'ENGAGE_EVENT';
/** Redux action: User cancels their engagement of "Going", "Interested", or "Show me Less" on an event*/
export const CANCEL_ENGAGE_EVENT = 'CANCEL_ENGAGE_EVENT';
/** Redux actions: Rate event function is successful or failed*/
export const RATE_EVENT_SUCCESSFUL = 'RATE_EVENT_SUCCESSFUL';
export const RATE_EVENT_ERROR = 'RATE_EVENT_ERROR';
/** Redux action: Select an event on the Recommendations Tab*/
export const SELECT_ACTIVE_REC_EVENT = 'SELECT_ACTIVE_REC_EVENT';
/** Redux action: Successfully retrieved image download URL */
export const RETRIEVE_IMAGE_URL = 'RETRIEVE_IMAGE_URL';
/** Redux action: Failed to retrieve image download URL */
export const RETRIEVE_IMAGE_URL_ERROR = 'RETRIEVE_IMAGE_URL_ERROR';
/** Redux action: Follow relationship written */
export const FOLLOWING = 'FOLLOWING';
/** Redux action: Follow relationship unwritten */
export const UNFOLLOWING = 'UNFOLLOWING';
/** Redux action: Follow request sent */
export const FOLLOW_REQUEST_SENT = 'FOLLOW_REQUEST_SENT';
/** Redux action: Follow request rejected */
export const FOLLOW_REQUEST_REJECTED = 'FOLLOW_REQUEST_REJECTED';
/** Redux action: Trouble sending follow request */
export const FOLLOW_ERROR = 'FOLLOW_ERROR';
/** Redux action: Select a tab on the profile page */
export const SELECT_ACTIVE_PROFILE_TAB = 'SELECT_ACTIVE_PROFILE_TAB';
/** Redux action: Show "Add to homescreen" popup */
export const SET_SHOW_INSTALL_PROMPT = 'SET_SHOW_INSTALL_PROMPT';
/** Redux action: Select active org tab on the Organizations Tab*/
export const SELECT_ACTIVE_ORG_TAB = 'SELECT_ACTIVE_ORG_TAB';
/** Redux action: Show unfriend confirmation modal */
export const SET_SHOW_UNFRIEND_CONFIRM = 'SET_SHOW_UNFRIEND_CONFIRMATION';
/** Redux action: Update orgsToNumEvents map */
export const LOAD_ORGS_TO_NUM_EVENTS = 'LOAD_ORGS_TO_NUM_EVENTS';
