import React from 'react';
import styled from 'styled-components';
import { Chip, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const StyledWrapperDiv = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  touch-action: pan-x pan-y;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const StyledTagsRow = styled.div`
  width: ${(props) => `${110 * Math.floor(props.numTags / 3)}px`};
  min-width: 60vw;
  padding: 5px 0px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `0px 0px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
  },
  carouselHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: (props) =>
      props.editLink ? 'flex-start' : 'space-between',
    alignItems: 'center',
    textAlign: 'right',
    paddingBottom: theme.spacing(1),
    '& h2': {
      display: 'inline',
      fontSize: '16px',
      margin: '0px',
    },
    '& a': {
      fontSize: '13px',
      color: theme.palette.secondary.main,
    },
  },
}));

const toTagChip = (tag) => (
  <Grid item key={tag}>
    <Link to={`/search?tags[]=${tag}`}>
      <Chip label={tag} onClick={() => null} />
    </Link>
  </Grid>
);

const toRowChildren = (children, currentChip, rowLengths) => {
  const label = currentChip.key;
  let lowestIndex = 0;
  if (rowLengths[1] < rowLengths[lowestIndex]) lowestIndex = 1;
  if (rowLengths[2] < rowLengths[lowestIndex]) lowestIndex = 2;
  children[lowestIndex].push(currentChip);
  rowLengths[lowestIndex] = rowLengths[lowestIndex] + label.length;
  return children;
};

const TagBrowse = (props) => {
  const { title, tags, editLink } = props;

  const makeTagRows = (tags) => {
    const containerProps = { numTags: tags.length };
    const tagRowChildren = [[], [], []];
    let rowLengths = [0, 0, 0];
    tags
      .map(toTagChip)
      .reduce(
        (children, chip) => toRowChildren(children, chip, rowLengths),
        tagRowChildren
      );
    const tagRows = [];
    for (let row = 0; row < 3; row++) {
      tagRows.push(
        <StyledTagsRow key={row} {...containerProps}>
          {tagRowChildren[row]}
        </StyledTagsRow>
      );
    }
    return tagRows;
  };

  const tagRows = makeTagRows(tags);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.carouselHeader}>
        <h2>{title}</h2>
        {editLink ? <Link to="/profile/edit/tags">Edit</Link> : null}
      </div>
      <StyledWrapperDiv>{tagRows}</StyledWrapperDiv>
    </div>
  );
};

TagBrowse.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
  editLink: PropTypes.bool,
};

export default TagBrowse;
