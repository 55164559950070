import React from 'react';
import TwineLytics from '../../twineLytics';
import { useLocation } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';
import { engagements } from '../../shared/constants.js';
import FullEngagementButtons from '../EventDetails/FullEngagementButtons.js';
import CardEngagementButton from './CardEngagementButton.js';
import { connect } from 'react-redux';
import {
  engageEvent,
  cancelEngageEvent,
} from '../../actions/recommendationsActions';
import PropTypes from 'prop-types';

const EngagementButtonContainer = (props) => {
  const { engageEvent, cancelEngageEvent } = props;
  const { uid, profile, disabled, event, zoomLink } = props;
  const { locationType, cardType } = props;
  const activeEventId = event?.eventId ?? null;
  const firestore = useFirestore();

  let goingEvents = profile.events?.going ?? [];
  let interestedEvents = profile.events?.interested ?? [];
  let uninterestedEvents = profile.events?.uninterested ?? [];

  const location = useLocation();
  const tl = new TwineLytics();
  const getEngagementHandler = (type) => (toggleOff) => async () => {
    if (!uid) {
      return;
    } else if (!Object.values(engagements).includes(type))
      throw new Error(`Unrecognized type ${type}.`);

    tl.logEvent('engage_event', { type, toggleOff, path: location.pathname });

    const eventRef = { collection: 'events', doc: activeEventId };
    const userRef = { collection: 'users', doc: uid };

    let userDocUpdates = {};
    let eventDocUpdates = {};

    const userUpdateFields = {
      [engagements.GOING]: 'events.going',
      [engagements.INTERESTED]: 'events.interested',
      [engagements.UNINTERESTED]: 'events.uninterested',
    };

    const eventUpdateFields = {
      [engagements.GOING]: 'goingUsers',
      [engagements.INTERESTED]: 'interestedUsers',
    };

    for (let engagementType in userUpdateFields) {
      const field = userUpdateFields[engagementType];
      if (toggleOff) {
        userDocUpdates[field] = firestore.FieldValue.arrayRemove(activeEventId);
      } else if (type === engagementType) {
        userDocUpdates[field] = firestore.FieldValue.arrayUnion(activeEventId);
      } else
        userDocUpdates[field] = firestore.FieldValue.arrayRemove(activeEventId);
    }

    for (let engagementType in eventUpdateFields) {
      const field = eventUpdateFields[engagementType];
      if (toggleOff) {
        eventDocUpdates[field] = firestore.FieldValue.arrayRemove(uid);
      } else if (type === engagementType) {
        eventDocUpdates[field] = firestore.FieldValue.arrayUnion(uid);
      } else {
        eventDocUpdates[field] = firestore.FieldValue.arrayRemove(uid);
      }
    }

    try {
      await firestore.update(userRef, userDocUpdates);
      await firestore.update(eventRef, eventDocUpdates);
      toggleOff
        ? cancelEngageEvent(uid, event, type)
        : engageEvent(uid, event, type);
    } catch (err) {
      console.error('Something went wrong with the Engagement button:');
      console.error(err);
    }
  };
  const uninterestedHandler = getEngagementHandler(engagements.UNINTERESTED);
  const interestedHandler = getEngagementHandler(engagements.INTERESTED);
  const goingHandler = getEngagementHandler(engagements.GOING);
  const isUninterested = uninterestedEvents.includes(activeEventId);
  const isInterested = interestedEvents.includes(activeEventId);
  const isGoing = goingEvents.includes(activeEventId);

  if (cardType === 'EventDetails') {
    return (
      <FullEngagementButtons
        style={{ margin: 'auto' }}
        zoomLink={zoomLink}
        event={event}
        disabled={disabled}
        uninterestedHandler={uninterestedHandler}
        interestedHandler={interestedHandler}
        goingHandler={goingHandler}
        isUninterested={isUninterested}
        isInterested={isInterested}
        isGoing={isGoing}
      />
    );
  } else if (cardType === 'LargeEventCard') {
    return (
      <CardEngagementButton
        // event={event}
        interestedHandler={interestedHandler}
        uid={uid}
        isInterested={isInterested}
        isGoing={isGoing}
        goingHandler={goingHandler}
        type="LargeEventCard"
        locationType={locationType}
      />
    );
  } else {
    return (
      <CardEngagementButton
        // event={event}
        interestedHandler={interestedHandler}
        goingHandler={goingHandler}
        isGoing={isGoing}
        isInterested={isInterested}
        uid={uid}
        type={cardType}
        locationType={locationType}
      />
    );
  }
};

EngagementButtonContainer.propTypes = {
  engageEvent: PropTypes.func,
  cancelEngageEvent: PropTypes.func,
  profile: PropTypes.object,
  disabled: PropTypes.bool,
  uid: PropTypes.string,
  cardType: PropTypes.oneOf([
    'LargeEventCard',
    'SmallCardCarousel',
    'EventDetails',
    'EventsList',
  ]),
  locationType: PropTypes.oneOf(['web', 'place']),
  event: PropTypes.object,
};

const mapStateToProps = ({ firebase }) => {
  return {
    profile: firebase.profile,
    uid: firebase.auth.uid,
  };
};
export default connect(mapStateToProps, { engageEvent, cancelEngageEvent })(
  EngagementButtonContainer
);
