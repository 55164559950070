import React from 'react';
import TwineLytics from '../../twineLytics';
import { connect } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { Button } from '@material-ui/core';
import { ReactComponent as FollowedIcon } from '../shared/images/user-check.svg';
import { ReactComponent as RequestIcon } from '../shared/images/user-plus.svg';
import { ReactComponent as HasRequestedIcon } from '../shared/images/user-minus.svg';
import {
  writeFriendRequest,
  unwriteFriendRequest,
  writeAcceptFriendRequest,
  writeDenyFriendRequest,
} from './socialUtils';
import { setShowUnfriendConfirm } from '../../actions/uiActions';

const tl = new TwineLytics();

const BEING_REQUESTED = 'BEING_REQUESTED';
const HAS_REQUESTED = 'HAS_REQUESTED';
const FRIENDS = 'FRIENDS';

const FollowButton = (props) => {
  const { profile, friendUid, className, setShowUnfriendConfirm } = props;

  const firestore = useFirestore();
  const friends = profile.friends || [];
  const sentRequests = profile.sentRequests || [];
  const friendRequests = profile.friendRequests || [];
  const uid = profile.uid;

  let status;

  if (friends.includes(friendUid)) status = FRIENDS;
  else if (sentRequests.includes(friendUid)) status = HAS_REQUESTED;
  else if (friendRequests.includes(friendUid)) status = BEING_REQUESTED;

  const iconStyle = { width: '18px', height: '18px', paddingRight: '0' };
  let buttonText, clickHandler, StartIcon;
  switch (status) {
    case FRIENDS:
      buttonText = 'Friend';
      clickHandler = () => setShowUnfriendConfirm(true);
      StartIcon = <FollowedIcon style={iconStyle} />;
      break;
    case HAS_REQUESTED:
      buttonText = 'Request sent';
      clickHandler = () => unwriteFriendRequest(firestore, uid, friendUid);
      StartIcon = <HasRequestedIcon style={iconStyle} />;
      break;
    case BEING_REQUESTED:
      const accept = () => writeAcceptFriendRequest(firestore, uid, friendUid);
      const deny = () => writeDenyFriendRequest(firestore, uid, friendUid);
      return (
        <span>
          <Button
            onClick={accept}
            style={{ paddingRight: '16px' }}
            className={className}
            startIcon={<RequestIcon style={iconStyle} />}
          >
            {'Accept Request'}
          </Button>{' '}
          <Button
            onClick={deny}
            className={className}
            startIcon={<HasRequestedIcon style={iconStyle} />}
          >
            {'Ignore'}
          </Button>
        </span>
      );
    default:
      buttonText = 'Add Friend';
      clickHandler = () => {
        tl.logEvent('send_friend_request');
        writeFriendRequest(firestore, uid, friendUid);
      };
      StartIcon = <RequestIcon style={iconStyle} />;
  }

  return (
    <Button onClick={clickHandler} className={className} startIcon={StartIcon}>
      {buttonText}
    </Button>
  );
};

const mapStateToProps = ({ firebase }) => ({
  profile: firebase.profile,
});

export default connect(mapStateToProps, { setShowUnfriendConfirm })(
  FollowButton
);
