import React from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import SplashHeader from './SplashHeader';
import SplashCarousel from './SplashCarousel';
import styled from 'styled-components';
import GetStartedButton from '../Welcome/GetStartedButton';

const StyledWrapperDiv = styled.div`
  text-align: center;
  .splash-header {
    box-sizing: border-box;
    height: 23vh;
    padding: 40px 20px 20px 20px;
  }
  .splash-carousel {
    box-sizing: border-box;
    height: 180px;
    width: 100%;
    margin: auto;
  }
  .splash-links {
    box-sizing: border-box;
    bottom: 69px;
    position: absolute;
    width: 100%;
    a {
      color: blue;
    }
  }
`;

const Splash = () => {
  const [, setCookie] = useCookies();
  const addSplashCookie = () =>
    setCookie('pastSplash', 'true', { path: '/', maxAge: 3600 * 6 });
  return (
    <StyledWrapperDiv>
      <SplashHeader className="splash-header" />
      <SplashCarousel className="splash-carousel" />
      <GetStartedButton buttonLabel="Get Started" onClick={addSplashCookie} />
      <div className="splash-links">
        <p>
          or{' '}
          <Link to="/" onClick={addSplashCookie}>
            Start browsing
          </Link>
        </p>
      </div>
    </StyledWrapperDiv>
  );
};

export default Splash;
