import React from 'react';
import BeginTutorial from './tutorial_begin.png';
import ForYouTutorial from './tutorial_forYou.png';
import BrowseTutorial from './tutorial_browse.png';
import DetailsTutorial from './tutorial_details.png';
import OrgsTutorial from './tutorial_orgs.png';
import SocialTutorial from './tutorial_social.png';
import ProfileTutorial from './tutorial_profile.png';
// eslint-disable-next-line no-unused-vars
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .carousel .slider-wrapper': { paddingBottom: '40px' },
    '& .carousel .slide': {
      touchAction: 'pan-x',
      background: 'white',
      '& img': {
        marginTop: theme.spacing(1),
        width: 'unset',
        height: '70vh',
      },
    },
    '& .carousel .control-dots': {
      margin: '4px 0px',
      bottom: theme.spacing(3.5),
      '& .dot': {
        background: '#E0E0E0',
        width: '4px',
        height: '4px',
        boxShadow: 'none',
        margin: '0px 4px',
      },
      '& .dot.selected': {
        background: theme.palette.primary.main,
        width: '8px',
        borderRadius: '25px',
      },
    },
    '& p': {
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      width: '280px',
      margin: 'auto',
    },
    '& h1': {
      color: '#ff521e',
      margin: '8px 0px',
    },
  },
}));

const NUM_SLIDES = 7;

const TutorialCarousel = ({ className, ...props }) => {
  const { setTutorialComplete } = props;
  const classes = useStyles();

  const handleCarouselChange = (index) => {
    if (index === NUM_SLIDES - 1) setTutorialComplete(true);
  };

  return (
    <Carousel
      className={`${className} ${classes.root}`}
      showArrows={false}
      showStatus={false}
      infiniteLoop={false}
      showThumbs={false}
      onChange={handleCarouselChange}
    >
      <div>
        <img src={BeginTutorial} alt="Begin tutorial" />
      </div>
      <div>
        <h1>For You</h1>
        <p>Get recommendations for interesting events.</p>
        <img src={ForYouTutorial} alt="For You page" />
      </div>
      <div>
        <h1>Events</h1>
        <p>
          Join virtual events, add them to your calendar, and share with
          friends.
        </p>
        <img src={DetailsTutorial} alt="Event Details page" />
      </div>
      <div>
        <h1>Browse Events</h1>
        <p>Browse, search, and filter through everything happening.</p>
        <img src={BrowseTutorial} alt="Browse Events page" />
      </div>
      <div>
        <h1>Organizations</h1>
        <p>Learn about clubs and their upcoming events.</p>
        <img src={OrgsTutorial} alt="Organizations page" />
      </div>
      <div>
        <h1>Go With Friends</h1>
        <p>Add friends and get suggestions for stuff to do together.</p>
        <img src={SocialTutorial} alt="Friends page" />
      </div>
      <div>
        <h1>Profile</h1>
        <p>See your events and interests in one place.</p>
        <img src={ProfileTutorial} alt="Profile page" />
      </div>
    </Carousel>
  );
};

export default TutorialCarousel;
