import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory, useLocation } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ClampLines from 'react-clamp-lines';
import { makeHeaderStyles as useStyles } from './styles';
import RibbonArray from '../shared/RibbonArray.js';
import { getLocationObj } from '../utils';

const Header = (props) => {
  const event = props.event;
  const history = useHistory();
  const classes = useStyles(props);
  let ribbonArray = [event?.location?.type];
  if (event.visibility === 'organization') ribbonArray.push(event.visibility);
  const location = useLocation();

  let handler;
  if (location.state?.fromTwine) {
    handler = () => history.goBack();
  } else {
    handler = () => history.push(getLocationObj('/'));
  }

  return (
    <div className={classes.root}>
      <div className={classes.vignette}>
        <div className={classes.wrapper}>
          <div className={classes.buttons}>
            <IconButton onClick={handler}>
              <ArrowBackIcon fontSize="large" />
            </IconButton>
          </div>
          <div className={classes.bottomWrapper}>
            <RibbonArray ribbonArray={ribbonArray} eventCardType={'header'} />
            <ClampLines
              text={event.title}
              lines={4}
              innerElement="h1"
              buttons={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
