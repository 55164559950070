import styled from 'styled-components';
import { Card } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const DivWithPseudos = styled.div`
  ::-webkit-scrollbar {
    display: none;
  }
  ::before,
  ::after {
    content: '';
  }
`;

export const StyledSmallEventCard = styled(Card)`
  display: flex;
  flex-flow: column nowrap;

  .eventCard-small-titleDiv {
    position: relative;
    height: 70%;
    background: black url(${(props) => props.src}) center/contain no-repeat;
    color: white;
  }

  h4 {
    position: absolute;
    bottom: 0px;
  }

  .eventCard-small-infoDiv {
    font-size: 9px;
    text-align: left;
    height: 25%;
    p {
      position: relative;
      margin: 0px 9px;
      top: 50%;
    }
    div {
      height: 50%;
    }
  }
`;

export const StyledDisabledDiv = styled.div`
  background: gray;
  grid-column: 1 / -1;
  grid-row: 2 / -1;
  z-index: 1;
`;

export const makeFilterStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '18px',
    padding: '5px',
    width: 'max-content',
    minWidth: '80%',
    height: '100%',
  },
  formControl: {
    '& .MuiInputLabel-root': {
      color: '#000000',
    },
  },
  active: {
    background: theme.palette.secondary.main,
    '& .MuiInputBase-root': {
      color: theme.palette.secondary.contrastText,
    },
    '& .MuiButton-label': {
      color: theme.palette.secondary.contrastText,
    },
  },
  select: {
    '&:focus': {
      background: 'inherit',
    },
  },
  outlined: {
    padding: 2,
  },
  popper: {
    height: 400,
    width: '50vw',
    background: theme.palette.background.paper,
    padding: 16,
    overflow: 'hidden',
    '& #tags-select-header': {
      height: '23%',
      '& h4': {
        display: 'inline',
      },
    },
    '& #tags-select-list': {
      display: 'flex',
      flexFlow: 'row wrap',
      alignContent: 'flex-start',
      overflowY: 'scroll',
      height: '78%',
      paddingBottom: '15px',
    },
    '& .selected-tag': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
    '& .MuiChip-root': {
      margin: 2,
    },
  },
  tagsButton: {
    paddingRight: 26,
    textTransform: 'none',
  },
}));

export const StyledFilterSelectorsWrapper = styled.div`
  width: 100%;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledSelectorsWrapper = styled.div`
  width: max-content;
  padding: 15px 0px;

  ::-webkit-scrollbar {
    display: none;
  }

  .MuiFormControl-root {
    min-width: 100px;
    height: 40px;
    margin: 0px 5px;
  }

  .MuiInputBase-root {
    display: inherit;
    text-align: left;
  }

  .MuiSelect-select.MuiSelect-select {
    padding: 5px 20px 5px 5px;
  }
`;

export const makeCarouselStyles = makeStyles((theme) => ({
  root: {
    display: 'grid',
    gridTemplateColumns: '24px 1fr 24px',
    gridTemplateRows: 'auto auto',
    fontSize: '12px',
    marginBottom: theme.spacing(3),
  },
  carouselHeader: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gridRow: '1 / 2',
    gridColumn: '2 / -2',
    textAlign: 'right',
    paddingBottom: theme.spacing(1),
    '& h2': {
      display: 'inline',
      fontSize: '16px',
      margin: '0px',
    },
    '& a': {
      fontSize: '13px',
      color: theme.palette.secondary.main,
    },
  },
  gridContainer: {
    display: 'grid',
    gridGap: theme.spacing(1.5),
    gridTemplateColumns: (props) =>
      `${theme.spacing(1.5)}px repeat(${
        props.items.length
      }, auto) ${theme.spacing(1.5)}px`,
    gridTemplateRows: (props) => (props.type === 'events' ? '155px' : '100px'),
    gridColumn: '1 / -1',
    gridRow: '2 / -1',
    overflowX: 'scroll',
    touchAction: 'pan-x pan-y',
  },
  headerAvatar: {
    marginRight: theme.spacing(1),
  },
  headerTitleDiv: {
    display: 'flex',
    alignItems: 'center',
  },
}));
