import React from 'react';
import TabHeader from '../TabHeader';
import SmallCardCarousel from '../shared/SmallCardCarousel';
import TagBrowse from './TagBrowse';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import {
  filterEvent,
  filterByTags,
  pseudoRandomGenerator,
  getDayOfYear,
  sortEventsByStartTime,
} from '../utils';
import { IconButton, useTheme } from '@material-ui/core';
import { filterTypes } from '../../shared/constants';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SearchFilterIcon } from './feather_searchfilter.svg';
import LoadingView from '../LoadingView';
import { getLocationObj } from '../utils';
import RecommendedEventsCarousel from './RecommendedEventsCarousel.js';
const qs = require('query-string');

const ALL_EVENTS_FILTER = {
  title: 'All Events',
  filter: {
    type: filterTypes.MOST_POPULAR,
  },
};

const toCarouselFilter = (tag) => ({
  title: `${tag}`,
  filter: {
    type: filterTypes.TAGS,
    value: [tag],
  },
});

const getFilterUrl = (filter) => {
  const { type, value } = filter;
  const queryParams = { [type]: value || true };
  const qsStringifyParams = { arrayFormat: 'bracket' };
  const searchString = qs.stringify(queryParams, qsStringifyParams);
  return `/search?${searchString}`;
};

const getFilterGenerator = (allEvents, profile) => {
  const MY_ORGS_FILTER = {
    title: 'My Organizations',
    filter: {
      type: filterTypes.MY_ORGS,
      value: {
        orgs: profile?.orgs,
      },
    },
  };
  return (tags) => {
    const filters = [];
    tags = [...tags];
    filters.push(ALL_EVENTS_FILTER);
    if (!profile.isEmpty) filters.push(MY_ORGS_FILTER);
    const random = pseudoRandomGenerator(getDayOfYear(new Date()));
    while (filters.length < 5 && tags.length) {
      const num = random();
      const idx = Math.floor(num * 10000) % tags.length;
      const tag = tags.splice(idx, 1).pop();
      if (allEvents.some(filterByTags([tag])))
        filters.push(toCarouselFilter(tag));
    }
    return filters;
  };
};

const EventsTab = (props) => {
  let { eventsMap, attributes, profile } = props;
  const tags = attributes ? attributes.all : [];
  const allEvents = [];
  if (eventsMap)
    Object.keys(eventsMap).forEach((eventId) =>
      allEvents.push(eventsMap[eventId])
    );

  const history = useHistory();
  const theme = useTheme();
  if (!profile.isLoaded) return <LoadingView />;

  const generateFiltersFromTags = getFilterGenerator(allEvents, profile);
  const filterTags = (profile && profile.interests) || tags;
  const carouselFilters = generateFiltersFromTags(filterTags);

  const allCarouselProps = carouselFilters.map((carouselData) => {
    const { title, filter } = carouselData;
    const linkUrl = getFilterUrl(filter);
    let events;
    if (filter) events = allEvents.filter(filterEvent(filter));
    else events = allEvents;
    sortEventsByStartTime(events);
    events = events.map((event) => event.eventId);
    return {
      title,
      items: events,
      type: 'events',
      linkUrl,
    };
  });

  const FilterButton = (
    <IconButton
      aria-label="search and filter events"
      onClick={() =>
        history.push(getLocationObj(`${history.location.pathname}/search`))
      }
    >
      <SearchFilterIcon />
    </IconButton>
  );

  return (
    <div>
      <TabHeader text="Events" actionButton={FilterButton} />
      <RecommendedEventsCarousel />
      <SmallCardCarousel {...allCarouselProps[0]} />
      {!profile.isEmpty ? <SmallCardCarousel {...allCarouselProps[1]} /> : null}
      <TagBrowse title="All tags" tags={tags || []} />
      {allCarouselProps.slice(2).map((props) => (
        <SmallCardCarousel key={props.title} {...props} />
      ))}
      <a
        style={{ color: theme.palette.secondary.main }}
        href="https://go.gettwine.app/submit"
        target="_blank"
        rel="noopener noreferrer"
      >
        Submit your event to Twine
      </a>
    </div>
  );
};

export default compose(
  firestoreConnect(() => [{ collection: 'meta', doc: 'attributes' }]),
  connect(({ firebase, firestore }) => ({
    eventsMap: firestore?.composite && firestore?.composite?.visibleEvents,
    attributes:
      firestore.data && firestore.data.meta && firestore.data.meta.attributes,
    profile: firebase.profile,
  }))
)(EventsTab);
