import React from 'react';
import TabHeader from '../TabHeader';
import FriendsScreen from './friends.jpg';
import GetStartedButton from '../Welcome/GetStartedButton';

const SocialPromo = (props) => {
  const promoText =
    'Experiences are better with people. Twine brings the two together.';
  return (
    <div>
      <TabHeader text="Go With Friends" />
      <img
        style={{ height: 'auto', width: '100%' }}
        src={FriendsScreen}
        alt="friends screen"
      />
      <GetStartedButton
        buttonLabel="Find Events with Friends"
        promoText={promoText}
      />
    </div>
  );
};

export default SocialPromo;
