import React, { useState } from 'react';
import TabHeader from '../TabHeader';
import SearchBar from '../SearchBar';
import PeopleViewer from './PeopleViewer';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'recompose';

const FriendsList = (props) => {
  const [searchText, setSearchText] = useState('');

  return (
    <div>
      <TabHeader text="Friends List" backButton />
      <SearchBar setSearchText={setSearchText} />
      <PeopleViewer searchText={searchText} />
    </div>
  );
};

export default compose(
  connect(({ firebase }) => ({ uid: firebase.auth?.uid })),
  firestoreConnect(({ uid }) => [{ collection: 'users', doc: uid }])
)(FriendsList);
