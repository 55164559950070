import React from 'react';
import { connect } from 'react-redux';
import { setShowUnfriendConfirm } from '../../actions/uiActions';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { writeUnfriend } from './socialUtils';
import { useFirestore } from 'react-redux-firebase';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '24px',
    position: 'relative',
    height: '100%',
    '& .MuiButton-root': {
      display: 'flex',
      margin: 'auto',
      padding: '12px',
    },
    textAlign: 'center',
  },
  unfriend: {
    color: 'red',
  },
}));

const UnfriendConfirmation = (props) => {
  const classes = useStyles();
  const { friendName, uid, friendUid, setShowUnfriendConfirm } = props;
  const firestore = useFirestore();

  const onConfirm = () => {
    writeUnfriend(firestore, uid, friendUid);
    setShowUnfriendConfirm(false);
  };
  const onCancel = () => {
    setShowUnfriendConfirm(false);
  };

  return (
    <div className={classes.root}>
      <p>Are you sure you want to unfriend {friendName}?</p>
      <Button className={classes.unfriend} onClick={onConfirm} component="a">
        Unfriend
      </Button>
      <Button onClick={onCancel} component="a">
        Cancel
      </Button>
    </div>
  );
};

export default connect(undefined, { setShowUnfriendConfirm })(
  UnfriendConfirmation
);
