import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const StyledGridContainer = styled(Grid)({
  padding: '5vw',
});

export const makePeopleViewerStyles = () =>
  makeStyles((theme) => ({
    root: {
      fontSize: '16px',
      fontWeight: '500',
      color: '#BDBDBD',
      '& .Mui-selected': {
        color: 'black',
      },
      lineHeight: '21px',
      '& .MuiTab-root': {
        textTransform: 'capitalize',
        whiteSpace: 'pre-line',
      },
    },
    indicator: {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      '& > span': {
        backgroundColor: theme.palette.primary.main,
        maxWidth: 40,
        width: '100%',
      },
    },
  }));

export const makeUserCardStyles = () =>
  makeStyles((theme) => ({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      height: '64px',
      '& .MuiCardActionArea-root': {
        height: '100%',
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'flex-start',
      },
      '& .MuiAvatar-root': {
        margin: '0px 8px',
      },
    },
    cardNoActionArea: {
      height: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& .MuiCardContent-root:last-child': {
        paddingBottom: '0px',
      },
    },
    content: {
      flexGrow: '2',
      width: '66%',
      padding: '0px 8px',
      '& h4, p': {
        display: 'block',
        margin: '0px',
        textAlign: 'left',
      },
      '& p': {
        fontSize: '9px',
      },
    },
    actions: {
      display: 'inline-flex',
      '& .MuiIconButton-root': {
        padding: '8px',
      },
    },
  }));

export const StyledInnerPageWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: white;
`;
