import React from 'react';
import { useFirestore } from 'react-redux-firebase';

const RemovePhotoButton = (props) => {
  const firestore = useFirestore();
  const { uid } = props;
  const handlePhotoRemove = () => {
    try {
      firestore.update(
        { collection: 'users', doc: uid },
        {
          photoUrl: null,
        }
      );
    } catch (err) {
      console.error('Error removing photo: ', err);
    }
  };
  return (
    <div style={{ color: 'red' }} onClick={handlePhotoRemove}>
      Remove Photo
    </div>
  );
};

export default RemovePhotoButton;
