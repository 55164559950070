import { makeStyles } from '@material-ui/core/styles';

export const makeGetStartedButtonStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: '35px 0px',
    position: 'absolute',
    bottom: '85px',
    '& p': {
      margin: '16px 44px',
      fontSize: '14px',
    },
  },
  paper: {
    height: `75%`,
    boxSizing: 'border-box',
    padding: '16px 24px',
  },
}));
