import React from 'react';
import { Card, CardActionArea } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PlaceholderImage from '../../shared/placeholder.jpg';
import ClampLines from 'react-clamp-lines';
import EventDateTime from './EventDateTime';
import EngagementButtonContainer from './EngagementButtonContainer';
import RibbonArray from './RibbonArray';
import { getLocationObj } from '../utils';
import { useSelector } from 'react-redux';
import _ from 'underscore';

const useStyles = makeStyles({
  root: {
    width: '173px',
    height: '150px',
    position: 'relative',
  },
  cover: {
    position: 'relative',
    height: '96px',
    width: '100%',
    background: (props) => ` url(${props.src}) center/cover no-repeat`,
    '& h4': {
      margin: '8px',
      fontSize: '16px',
      fontWeight: '300',
      lineHeight: '20px',
      position: 'absolute',
      bottom: '0',
      left: '0',
      textAlign: 'left',
      color: 'white',
    },
  },
  vignette: {
    boxSizing: 'border-box',
    padding: '0px 8px 8px 8px',
    width: '100%',
    height: '100%',
    boxShadow: 'inset 0px -76px 40px -36px rgba(10, 0, 0, 1)',
    background: `linear-gradient(rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 30%),
    linear-gradient(to top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 20%)`,
  },
  info: {
    padding: '7px 8px',
    boxSizing: 'border-box',
    position: 'relative',
    height: '50px',
    width: '100%',
    backgroundColor: 'white',
    textAlign: 'left',
    '& p': {
      margin: '1px 0px',
      fontSize: '12px',
      lineHeight: '16px',
      '& span': {
        color: '#c7003a',
      },
    },
  },
});

const SmallEventCard = React.memo((props) => {
  const history = useHistory();
  const { eventId, type } = props;
  const event = useSelector(
    (state) => state.firestore?.composite?.visibleEvents[eventId],
    _.isEqual
  );
  const { location, time, visibility } = event;

  let ribbonArray = [location.type];
  if (visibility === 'organization') ribbonArray.push(visibility);

  const classes = useStyles({ src: event.imageUrl, ...props });
  return (
    <div>
      <Card src={event.imageUrl || PlaceholderImage} className={classes.root}>
        <EngagementButtonContainer
          event={event}
          cardType={type}
          locationType={location.type}
        />
        <CardActionArea
          onClick={() =>
            setTimeout(
              () => history.push(getLocationObj(`/events/${eventId}`)),
              200
            )
          }
        >
          <div className={classes.cover}>
            <div className={classes.vignette}>
              <RibbonArray ribbonArray={ribbonArray} eventCardType={'small'} />
              <ClampLines
                text={event.title}
                lines={3}
                innerElement="h4"
                buttons={false}
              />
            </div>
          </div>
          <div className={classes.info}>
            <EventDateTime
              startTime={time.start.toDate().toString()}
              abbr={true}
            />
            <ClampLines
              text={location.name || 'Zoom'}
              lines={1}
              innerElement="p"
              buttons={false}
            />
          </div>
        </CardActionArea>
      </Card>
    </div>
  );
});

export default SmallEventCard;
