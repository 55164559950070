import firebase from 'firebase';
import 'firebase/functions';
import {
  SELECT_ACTIVE_REC_EVENT,
  ENGAGE_EVENT,
  CANCEL_ENGAGE_EVENT,
  RATE_EVENT_SUCCESSFUL,
  RATE_EVENT_ERROR,
} from './types';
import { engagements, tagRatings } from '../shared/constants';

export const selectActiveRecEvent = (index) => {
  return {
    type: SELECT_ACTIVE_REC_EVENT,
    payload: index,
  };
};

/**
 * Redux action creator thunk; successful event engagement was written to user in database;
 * Send POST to /rateEvent function.
 * @param {String} userId User ID
 * @param {Object} event Event object
 * @param {String} engagement Engagement type: 'going' || 'interested' || 'uninterested'
 */
export const engageEvent = (userId, event, engagement) => (dispatch) => {
  dispatch({
    type: ENGAGE_EVENT,
    payload: { eventId: event.eventId, type: engagement },
  });

  let rating;
  switch (engagement) {
    case engagements.GOING:
    case engagements.INTERESTED:
      rating = tagRatings.LIKE;
      break;
    case engagements.UNINTERESTED:
      rating = tagRatings.DISLIKE;
      break;
    default:
      throw new Error(`Unrecognized engagement type ${engagement}`);
  }

  const attributes = event?.filters?.attributes || [];
  const departments = event?.filters?.departments || [];
  dispatch(rateTags(userId, attributes.concat(departments), rating));
};

/**
 * Redux action creator thunk; event engagement was successfully removed from user in database;
 * Send POST to /rateEvent function if the cancelled engagement was "uninterested". Otherwise,
 * don't update /rateEvent.
 * @param {String} userId User ID
 * @param {Object} event Event object
 * @param {String} engagement Engagement type: 'going' || 'interested' || 'uninterested'
 */
export const cancelEngageEvent = (userId, event, engagement) => (dispatch) => {
  dispatch({
    type: CANCEL_ENGAGE_EVENT,
    payload: { eventId: event.eventId, type: engagement },
  });

  // Only update the tags rating if the user is cancelling "Show me less like this"
  const attributes = event?.filters?.attributes || [];
  const departments = event?.filters?.departments || [];
  if (engagement === engagements.UNINTERESTED) {
    let rating = tagRatings.UNDISLIKE;
    dispatch(rateTags(userId, attributes.concat(departments), rating));
  }
};

/**
 * Call rateTags
 * @param {String} _userId User ID
 * @param {String[]} tags Attributes to rate
 * @param {String} rating Rating type: 'like' || 'dislike' || 'unlike' || 'undislike'
 */
export const rateTags = (_userId, tags, rating) => async (dispatch) => {
  if (!Object.values(tagRatings).includes(rating)) {
    throw new Error(`Unrecognized tag rating type ${rating}`);
  }
  const rateTags = firebase.functions().httpsCallable('rateTags');
  try {
    await rateTags({ items: tags, type: rating });
    dispatch({
      type: RATE_EVENT_SUCCESSFUL,
      items: tags,
    });
  } catch (error) {
    console.error('Failed to rate event ', error);
    dispatch({
      type: RATE_EVENT_ERROR,
      error,
    });
  }
};
