import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import TagChip from '../shared/TagChip';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
}));

const TagsPicker = (props) => {
  const { allAttributes, selectedTags, setSelectedTags } = props;
  const classes = useStyles(props);

  const toggleTag = (attr) => {
    if (selectedTags[attr]) setSelectedTags({ ...selectedTags, [attr]: false });
    else setSelectedTags({ ...selectedTags, [attr]: true });
  };

  if (!allAttributes) return <div>Loading...</div>;
  return (
    <div className={classes.root}>
      {allAttributes.all.map((attr) => (
        <TagChip
          label={attr}
          key={attr}
          onClick={() => toggleTag(attr)}
          highlight={selectedTags[attr]}
        />
      ))}
    </div>
  );
};

export default compose(
  connect(({ firestore }) => ({
    allAttributes: firestore.data?.meta?.attributes,
  })),
  firestoreConnect(() => [{ collection: 'meta', doc: 'attributes' }])
)(TagsPicker);
