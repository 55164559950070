import React from 'react';
import OrgsPickerContainer from './OrgsPickerContainer';
import TabHeader from '../TabHeader';

const PickStudentOrgs = () => {
  return (
    <div>
      <TabHeader text="Pick Your Student Orgs" backButton />
      <p>
        Select any student organizations that you're involved in or spark your
        interest. You can always add and remove later, when you realize your
        lifelong dream of virtual hockey.
      </p>
      <OrgsPickerContainer isDepartment={false} rerouteOnDone="/onboarding/4" />
    </div>
  );
};

export default PickStudentOrgs;
