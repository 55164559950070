import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import CompleteProfile from '../components/Onboarding/CompleteProfile';
import PickAcademicOrgs from '../components/Onboarding/PickAcademicOrgs';
import PickStudentOrgs from '../components/Onboarding/PickStudentOrgs';
import PickInterests from '../components/Onboarding/PickInterests';
import TutorialPage from '../components/Onboarding/TutorialPage';
import { connect } from 'react-redux';

const OnboardingSwitch = (props) => {
  const { profile } = props;
  if (profile.isLoaded && !profile.onboarding && profile.hasSeenTutorial)
    return <Redirect to="/" />;
  return (
    <Switch>
      <Route path="/onboarding" exact>
        <Redirect to="/onboarding/1" />
      </Route>
      <Route path="/onboarding/1">
        <CompleteProfile />
      </Route>
      <Route path="/onboarding/2">
        <PickAcademicOrgs />
      </Route>
      <Route path="/onboarding/3">
        <PickStudentOrgs />
      </Route>
      <Route path="/onboarding/4">
        <PickInterests />
      </Route>
      <Route path="/onboarding/5">
        <TutorialPage />
      </Route>
    </Switch>
  );
};

export default connect(({ firebase }) => ({ profile: firebase.profile }))(
  OnboardingSwitch
);
