import React from 'react';
import { Link } from 'react-router-dom';
import SmallEventCard from '../shared/SmallEventCard';
import { makeStyles } from '@material-ui/core/styles';
import { sortEventsByStartTime } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-evenly',
    paddingTop: theme.spacing(3),
    '& .MuiCard-root': {
      marginBottom: theme.spacing(2),
    },
  },
  empty: {
    '& p': {
      textAlign: 'left',
      margin: theme.spacing(3),
    },
    '& a': {
      color: theme.palette.secondary.main,
    },
  },
}));

const EventsList = (props) => {
  const events = props.events || [];
  sortEventsByStartTime(events);
  const classes = useStyles();

  const EmptyDiv = () => (
    <div className={classes.empty}>
      <p>
        It's a bit empty here! Check out <Link to="/">events for you</Link> or{' '}
        <Link to="/events">browse events</Link>.
      </p>
    </div>
  );

  if (events.length === 0) return <EmptyDiv />;
  return (
    <div className={classes.root}>
      {events.map((event) => (
        <SmallEventCard
          eventId={event.eventId}
          key={event.eventId}
          type="EventsList"
        />
      ))}
    </div>
  );
};

export default EventsList;
