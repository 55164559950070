import {
  SELECT_ACTIVE_REC_EVENT,
  ENGAGE_EVENT,
  CANCEL_ENGAGE_EVENT,
  RATE_EVENT_ERROR,
  RATE_EVENT_SUCCESSFUL,
} from '../actions/types';

const initialState = {
  activeEventIdx: 0,
};

const recommendationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_ACTIVE_REC_EVENT:
      return { ...state, activeEventIdx: action.payload };
    case ENGAGE_EVENT:
      console.log(
        `User marked ${action.payload.type} to event with id ${action.payload.eventId}.`
      );
      return { ...state };
    case CANCEL_ENGAGE_EVENT:
      console.log(
        `User cancelled rating ${action.payload.type} on event with id ${action.payload.eventId}.`
      );
      return { ...state };
    case RATE_EVENT_SUCCESSFUL:
      return { ...state };
    case RATE_EVENT_ERROR:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};

export default recommendationsReducer;
