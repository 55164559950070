import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const AuthRoute = (props) => {
  const { loggedIn, children, component, redirectBase = '/', ...rest } = props;
  if (component && loggedIn) return <Route component={component} {...rest} />;
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return loggedIn ? (
          children
        ) : (
          <Redirect
            to={`${redirectBase}?redirect=${location.pathname}${location.search}`}
          />
        );
      }}
    />
  );
};

const mapStateToProps = ({ firebase }) => ({
  loggedIn: firebase.auth.uid ? true : false,
});

export default connect(mapStateToProps)(AuthRoute);
