import React, { useState } from 'react';
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  ListItemText,
  Paper,
  Chip,
  Button,
  Popper,
  ClickAwayListener,
  TextField,
} from '@material-ui/core';
import {
  StyledSelectorsWrapper,
  StyledFilterSelectorsWrapper,
  makeFilterStyles,
} from './styles';
import constants, { filterTypes, filterValues } from '../../shared/constants';

const useStyles = makeFilterStyles;

const getChipMapper = (selectedTags, handleTagsChange) => {
  return (tag) => {
    const isSelected = selectedTags.includes(tag);
    const handleSelect = () => handleTagsChange([...selectedTags, tag]);
    const handleDelete = () =>
      handleTagsChange(selectedTags.filter((item) => item !== tag));
    return (
      <Chip
        label={tag}
        key={tag}
        className={isSelected ? 'selected-tag' : null}
        onClick={handleSelect}
        onDelete={isSelected ? handleDelete : null}
      />
    );
  };
};

const selectedToDays = (selected, days) => {
  if (selected.length === 0) return null;
  else if (selected.length === 1) return days[selected[0]];
  else return `${days[selected[0]]} +${selected.length - 1}`;
};

const tagsToButtonDisplay = (tags) => {
  if (tags.length === 0) return null;
  else if (tags.length === 1) return tags[0];
  else return `${tags[0]} +${tags.length - 1}`;
};

const FilterSelectors = (props) => {
  const { setFilter, queryParams, allTags } = props;
  const { DAYS_OF_WEEK } = constants;
  const today = new Date().getDay();
  const days = DAYS_OF_WEEK.map((day, idx, arr) => {
    if (idx === 0) return 'Today';
    else if (idx === 1) return 'Tomorrow';
    else return arr[(today + idx) % arr.length];
  });

  const [locationType, setLocationType] = useState(
    queryParams[filterTypes.LOCATION_TYPE] || ''
  );
  const [price, setPrice] = useState(queryParams[filterTypes.PRICE] || '');
  const [dateArr, setDateArr] = useState(queryParams[filterTypes.DATE] || []);
  const [tags, setTags] = useState(queryParams[filterTypes.TAGS] || []);

  const [tagsOpen, setTagsOpen] = useState(false);
  const [popperAnchorEl, setPopperAnchorEl] = useState(null);
  const [tagsSearchValue, setTagsSearchValue] = useState('');
  const regex = new RegExp(tagsSearchValue, 'ig');

  const handleChange = (type) => {
    return (e) => {
      const value = e.target.value;
      const setLocalState = {
        [filterTypes.LOCATION_TYPE]: setLocationType,
        [filterTypes.PRICE]: setPrice,
        [filterTypes.DATE]: setDateArr,
        [filterTypes.TAGS]: setTags,
      }[type];
      setLocalState(value);
      setFilter({ type, value });
    };
  };

  // NOTE(CP): FORGIVE ME LORD, I HAVE SINNED
  // THESE HANDLERS ARE TERRIBLE
  const handleTagsOpen = (e) => {
    setTagsOpen(true);
    setPopperAnchorEl(e.currentTarget);
  };

  const handleClickAway = (e) => {
    setTimeout(() => {
      setTagsOpen(false);
      setPopperAnchorEl(null);
    }, 50);
  };

  const handleTagsSearchChange = (e) =>
    setTagsSearchValue(e.currentTarget.value);

  const handleLocationTypeChange = handleChange(filterTypes.LOCATION_TYPE);
  const handlePriceChange = handleChange(filterTypes.PRICE);
  const handleDatesChange = handleChange(filterTypes.DATE);
  const handleTagsChange = (value) => {
    handleChange(filterTypes.TAGS)({ target: { value } });
  };

  const toTagChip = getChipMapper(tags, handleTagsChange);

  const classes = useStyles();
  return (
    <StyledFilterSelectorsWrapper>
      <StyledSelectorsWrapper>
        <FormControl
          className={classes.formControl}
          margin="dense"
          variant="outlined"
        >
          <InputLabel id="filter-location-type-label" shrink={false}>
            {locationType ? '' : 'Type'}
          </InputLabel>
          <Paper
            className={`${classes.paper} ${locationType && classes.active}`}
            elevation={1}
          >
            <Select
              disableUnderline
              labelId="filter-location-type-label"
              id="filter-location-type"
              value={locationType}
              onChange={handleLocationTypeChange}
              variant="standard"
              classes={{ outlined: classes.outlined, select: classes.select }}
            >
              <MenuItem value="">Any</MenuItem>
              <MenuItem value={filterValues.LOCATION_TYPE.VIRTUAL}>
                Virtual
              </MenuItem>
              <MenuItem value={filterValues.LOCATION_TYPE.PLACE}>
                In Person
              </MenuItem>
            </Select>
          </Paper>
        </FormControl>
        <FormControl
          className={classes.formControl}
          margin="dense"
          variant="outlined"
        >
          <InputLabel id="filter-date-label" shrink={false}>
            {dateArr.length === 0 ? 'Date' : ''}
          </InputLabel>
          <Paper
            className={`${classes.paper} ${dateArr.length && classes.active}`}
            elevation={1}
          >
            <Select
              disableUnderline
              fullWidth
              labelId="filter-date-label"
              id="filter-date"
              multiple
              value={dateArr}
              onChange={handleDatesChange}
              renderValue={(vals) => selectedToDays(vals, days)}
              variant="standard"
              className={classes.select}
              classes={{ outlined: classes.outlined, select: classes.select }}
            >
              {days.map((day, idx) => {
                return (
                  <MenuItem key={day} value={idx}>
                    <Checkbox checked={dateArr.includes(idx)} />
                    <ListItemText primary={day} />
                  </MenuItem>
                );
              })}
            </Select>
          </Paper>
        </FormControl>
        <FormControl
          className={classes.formControl}
          margin="dense"
          variant="outlined"
        >
          <InputLabel id="filter-tags-label" shrink={false}>
            {tags.length ? '' : 'Tags'}
          </InputLabel>
          <Paper
            className={`${classes.paper} ${tags.length && classes.active}`}
            elevation={1}
            onClick={!tagsOpen ? handleTagsOpen : null}
          >
            <Button
              id="filter-tags-button"
              className={`${classes.outlined} ${classes.select} ${classes.tagsButton}`}
            >
              {tagsToButtonDisplay(tags)}
              <svg
                className="MuiSvgIcon-root MuiSelect-icon"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M7 10l5 5 5-5z"></path>
              </svg>
            </Button>
            <Popper
              open={tagsOpen}
              anchorEl={popperAnchorEl}
              placement="bottom-end"
            >
              <ClickAwayListener onClickAway={handleClickAway}>
                <Paper className={classes.popper}>
                  <div id="tags-select-header">
                    <h4>Tags</h4>
                    <TextField
                      variant="filled"
                      value={tagsSearchValue}
                      onChange={handleTagsSearchChange}
                    />
                  </div>
                  <div id="tags-select-list">
                    {(allTags || [])
                      .filter((tag) => tag.match(regex))
                      .sort((a, b) => {
                        if (tags.includes(a) && !tags.includes(b)) return -1;
                        else if (tags.includes(b) && !tags.includes(a))
                          return 1;
                        else return 0;
                      })
                      .map(toTagChip)}
                  </div>
                </Paper>
              </ClickAwayListener>
            </Popper>
          </Paper>
        </FormControl>
        <FormControl
          className={classes.formControl}
          margin="dense"
          variant="outlined"
        >
          <InputLabel id="filter-price-label" shrink={false}>
            {price ? '' : 'Price'}
          </InputLabel>
          <Paper
            className={`${classes.paper} ${price && classes.active}`}
            elevation={1}
          >
            <Select
              disableUnderline
              labelId="filter-price-label"
              id="filter-price"
              value={price}
              onChange={handlePriceChange}
              variant="standard"
              className={classes.select}
              classes={{ outlined: classes.outlined, select: classes.select }}
            >
              <MenuItem value="">Any</MenuItem>
              <MenuItem value={filterValues.PRICE.FREE}>Free</MenuItem>
              <MenuItem value={filterValues.PRICE.PAID}>Paid</MenuItem>
            </Select>
          </Paper>
        </FormControl>
      </StyledSelectorsWrapper>
    </StyledFilterSelectorsWrapper>
  );
};

export default FilterSelectors;
