import { makeStyles } from '@material-ui/core/styles';

export const makeSettingsBaseStyles = makeStyles((theme) => ({
  content: {
    padding: `0px ${theme.spacing(3)}px`,
  },
  item: {
    marginBottom: theme.spacing(3),
  },
}));

export const makeSettingsButtonStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    '& h3': {
      margin: '0px',
      textAlign: 'left',
      lineHeight: '20px',
      fontSize: '16px',
      fontWeight: '500',
    },
    '& p': {
      margin: `${theme.spacing(0.5)}px 0px 0px`,
      textAlign: 'left',
    },
  },
}));
