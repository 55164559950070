import React from 'react';
import { FilledInput } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  searchBar: {
    borderRadius: '8px',
    backgroundColor: '#F2F2F2',
    height: '40px',
    '& input': {
      padding: '15px 10px',
      fontSize: '12px',
    },
  },
}));

const SearchBar = (props) => {
  const { setSearchText } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FilledInput
        disableUnderline
        startAdornment={<SearchIcon />}
        fullWidth
        onChange={(e) => setSearchText(e.target.value.trim())}
        placeholder={props.placeholder || 'Search...'}
        className={classes.searchBar}
      />
    </div>
  );
};

SearchBar.propTypes = {
  setSearchText: PropTypes.func.isRequired,
};

export default SearchBar;
