import React from 'react';
import TabHeader from '../TabHeader';
import ProfileScreen from './profile.jpg';
import GetStartedButton from '../Welcome/GetStartedButton';

const ProfilePromo = (props) => {
  const promoText =
    'You and your stuff. Living together. Without sharing a bathroom.';
  return (
    <div>
      <TabHeader text="Profile" />
      <img
        style={{ height: 'auto', width: '100%' }}
        src={ProfileScreen}
        alt="profile screen"
      />
      <GetStartedButton buttonLabel="See Your Profile" promoText={promoText} />
    </div>
  );
};

export default ProfilePromo;
