import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import TagBrowse from '../EventsTab/TagBrowse';
import SmallCardCarousel from '../shared/SmallCardCarousel';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    '& h2': {
      display: 'inline',
      fontSize: '16px',
      margin: `${theme.spacing(0.5)}px 0px`,
    },
    '& a': {
      fontSize: '13px',
      color: theme.palette.secondary.main,
    },
    '& p': {
      margin: '0px',
    },
  },
  section: {
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0px`,
  },
}));

const PrefsViewer = (props) => {
  useFirestoreConnect({ collection: 'meta', doc: 'organizations' });
  const organizationSlicesMap =
    useSelector((state) => state.firestore.data.meta?.organizations) || {};
  const { interests, myOrgIds } = props;
  const myOrgs =
    myOrgIds?.map((orgId) => ({
      orgId,
      ...organizationSlicesMap[orgId],
    })) ?? [];
  const studentOrgs = myOrgs.filter((org) => !org.department);
  const academicOrgs = myOrgs.filter((org) => org.department);
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {studentOrgs.length !== 0 ? (
        <SmallCardCarousel
          type="orgs"
          title="Student Orgs"
          items={studentOrgs}
          showIfEmpty
          linkText="Edit"
          linkUrl="/profile/edit/student-orgs"
        />
      ) : (
        <div className={classes.section}>
          <h2>Student Orgs</h2>
          <p>
            <Link to="/profile/edit/student-orgs">Select student orgs</Link>{' '}
            that you are interested in.
          </p>
        </div>
      )}
      {academicOrgs.length !== 0 ? (
        <SmallCardCarousel
          type="orgs"
          title="Academic Orgs"
          items={academicOrgs}
          showIfEmpty
          linkText="Edit"
          linkUrl="/profile/edit/academic-orgs"
        />
      ) : (
        <div className={classes.section} style={{ paddingBottom: '24px' }}>
          <h2>Academic Orgs</h2>
          <p>
            <Link to="/profile/edit/academic-orgs">Select academic orgs</Link>{' '}
            that you are interested in.
          </p>
        </div>
      )}
      <TagBrowse title="Tags" tags={interests ?? []} editLink />
    </div>
  );
};

const mapStateToProps = ({ firebase }) => {
  return {
    uid: firebase.auth?.uid,
    myOrgIds: firebase.profile?.orgs,
    interests: firebase.profile?.interests,
  };
};

export default compose(connect(mapStateToProps))(PrefsViewer);
