import React from 'react';
import { ReactComponent as HeartIcon } from '../shared/images/heart.svg';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as GoingIcon } from '../shared/images/checkmark.svg';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  '@keyframes shake': {
    '10%, 90%': {
      transform: 'translate3d(-1px, 0, 0)',
    },
    '20%, 80%': {
      transform: 'translate3d(1px, 0, 0)',
    },
    '30%, 50%, 70%': {
      transform: 'translate3d(-1px, 0, 0)',
    },
    '40%, 60%': {
      transform: 'translate3d(1px, 0, 0)',
    },
  },
  smallButton: {
    border: 'none',
    position: 'absolute',
    top: '6px',
    right: '4px',
    height: '20px',
    minWidth: '20px',
    padding: '0px',
    zIndex: '1',
    '&:active': {
      animation: `$shake 0.82s cubic-bezier(.36,.07,.19,.97)`,
    },
  },
  largeButton: {
    border: 'none',
    position: 'absolute',
    top: '16px',
    height: '24px',
    minWidth: '24px',
    padding: '0px',
    left: '68vw',
    zIndex: '1',
    '&:active': {
      animation: `$shake 0.82s cubic-bezier(.36,.07,.19,.97)`,
    },
  },
  largeButtonGoing: {
    minWidth: '24px',
    borderRadius: '50%',
    background: (props) =>
      props.locationType === 'web' ? '#2F80ED' : '#F2C94C',
    border: 'none',
    position: 'absolute',
    top: '16px',
    height: '24px',
    padding: '0px',
    left: '68vw',
    zIndex: '1',
    '& svg': {
      color: '#FFFFFF',
    },
    '&:active': {
      animation: `$shake 0.82s cubic-bezier(.36,.07,.19,.97)`,
    },
  },
  smallButtonGoing: {
    border: 'none',
    borderRadius: '50%',
    position: 'absolute',
    top: '6px',
    right: '4px',
    height: '20px',
    minWidth: '20px',
    padding: '0px',
    zIndex: '1',
    background: (props) =>
      props.locationType === 'web' ? '#2F80ED' : '#F2C94C',
    '& svg': {
      color: '#FFFFFF',
    },
    '&:active': {
      animation: `$shake 0.82s cubic-bezier(.36,.07,.19,.97)`,
    },
  },
}));

const CardEngagementButton = (props) => {
  const { interestedHandler, goingHandler, isInterested, isGoing } = props;
  const { uid, type, locationType } = props;

  const classes = useStyles(props);
  if (type === 'LargeEventCard' && isGoing) {
    return (
      <Button disableRipple={true} className={classes.largeButtonGoing}>
        <GoingIcon onClick={goingHandler(isGoing)} />
      </Button>
    );
  } else if (type === 'LargeEventCard' && !isGoing) {
    return (
      <Button disableRipple={true} className={classes.largeButton}>
        <HeartIcon
          className="heartIcon"
          stroke={
            isInterested && locationType === 'web'
              ? '#2F80ED'
              : isInterested && locationType === 'place'
              ? '#F2C94C'
              : '#FFFFFF'
          }
          fill={
            isInterested && locationType === 'web'
              ? '#2F80ED'
              : isInterested && locationType === 'place'
              ? '#F2C94C'
              : 'none'
          }
          onClick={interestedHandler(isInterested)}
        />
      </Button>
    );
  } else if (!isGoing) {
    return (
      <Button
        disableRipple={true}
        style={!uid ? { visibility: 'hidden' } : null}
        className={classes.smallButton}
      >
        <HeartIcon
          stroke={
            isInterested && locationType === 'web'
              ? '#2F80ED'
              : isInterested && locationType === 'place'
              ? '#F2C94C'
              : '#FFFFFF'
          }
          fill={
            isInterested && locationType === 'web'
              ? '#2F80ED'
              : isInterested && locationType === 'place'
              ? '#F2C94C'
              : 'none'
          }
          onClick={interestedHandler(isInterested)}
          width="20px"
          height="20px"
        />
      </Button>
    );
  } else {
    return (
      <Button disableRipple={true} className={classes.smallButtonGoing}>
        <GoingIcon onClick={goingHandler(isGoing)} />
      </Button>
    );
  }
};

CardEngagementButton.propTypes = {
  interestedHandler: PropTypes.func,
  goingHandler: PropTypes.func,
  isInterested: PropTypes.bool,
  isGoing: PropTypes.bool,
  uid: PropTypes.string,
  type: PropTypes.oneOf([
    'LargeEventCard',
    'SmallCardCarousel',
    'EventDetails',
    'EventsList',
  ]),
  locationType: PropTypes.oneOf(['web', 'place']),
};

export default CardEngagementButton;
