/* Container */
import React from 'react';
import { useSelector } from 'react-redux';
import ReactHtmlParser from 'react-html-parser';
import PlaceholderImage from '../../shared/placeholder.jpg';
import EngagementButtonContainer from '../shared/EngagementButtonContainer';
import PeekRespondents from './PeekRespondents';
import OrganizationCard from '../shared/OrganizationCard';
import LoadingView from '../LoadingView';
import Header from './Header';
import { useHistory, useParams } from 'react-router-dom';
import JoinButtons from './JoinButtons';
import TagChip from '../shared/TagChip';
import { makeEventDetailsStyles as useStyles } from './styles';
import EventDateTime from '../shared/EventDateTime';
import { isEmpty, isLoaded } from 'react-redux-firebase';
import TwineLogo from '../shared/images/twine-logo.png';
import { getLocationObj } from '../utils';

const parseDescription = (description) => {
  return ReactHtmlParser(description);
};

const EventDetails = (props) => {
  const { isLoggedIn } = props;
  const { id: eventId } = useParams();
  const classes = useStyles();
  const history = useHistory();
  const events = useSelector(
    (state) => state.firestore.composite?.visibleEvents
  );
  const event = events?.[eventId];
  const eventUrl = `${window.location.origin}/events/${event.eventId}`;

  if (!event) {
    if (isLoaded(events) && isEmpty(event)) {
      setTimeout(() => {
        history.push(getLocationObj('/'));
      }, 5000);
      return (
        <React.Fragment>
          <img
            src={TwineLogo}
            alt="twine logo"
            style={{ height: '50px', margin: '20px auto 0px auto' }}
          />
          <h3>{'Event Not Found'}</h3>
          <p style={{ margin: 'auto 8px auto 8px' }}>
            {
              "The event you're looking for has already passed, or the link that you've clicked is invalid. We're redirecting you home now."
            }
          </p>
        </React.Fragment>
      );
    } else {
      return (
        <div>
          <LoadingView />
        </div>
      ); // Shows "Loading" until event is loaded
    }
  }

  const src = event.imageUrl || PlaceholderImage;
  const type = event.location.type || 'unknown';

  let locationName;
  if (event.location?.name) locationName = event.location.name;
  else if (type !== 'place') locationName = 'Online';
  else locationName = '';
  const eventZoomObject = event.zoom;
  const zoomLink = eventZoomObject?.url;
  const zoomPasscode = eventZoomObject?.passcode;
  const locationString = event.location.address || zoomLink;

  const TimeAndLocation = () => (
    <React.Fragment>
      <h3>{'Time & Location'}</h3>
      <div className={classes.timeAndLocation}>
        <EventDateTime
          startTime={event?.time.start.toDate().toString()}
          endTime={event?.time.end?.toDate().toString()}
        />
        <p>{locationName}</p>
      </div>
    </React.Fragment>
  );

  const EventUrl = () => (
    <React.Fragment>
      <h3>URL</h3>
      <p>
        <a href={event.url} target="_blank" rel="noopener noreferrer">
          {event.url}
        </a>
      </p>
    </React.Fragment>
  );

  const EventTags = () => (
    <React.Fragment>
      <h3>Event Tags</h3>
      <div className={classes.eventTags}>
        {event?.filters?.attributes?.map((tag) => (
          //<Link key={tag} to={`/search?tags[]=${tag}`}>
          <TagChip
            key={tag}
            label={tag}
            onClick={() =>
              history.push(`/search?tags[]=${tag}`, { fromTwine: true })
            }
          />
          // </Link>
        ))}
      </div>
    </React.Fragment>
  );

  const owner = event?.owner ?? {};
  const ownerExists = owner?.id !== undefined;

  const Organizer = () => {
    return (
      <React.Fragment>
        <h3>Organizer</h3>
        <div className={classes.organizer}>
          <OrganizationCard orgId={owner.id} />
        </div>
      </React.Fragment>
    );
  };

  return (
    <div className={classes.root}>
      <Header src={src} event={event} type={type} />
      <div className={classes.content}>
        <TimeAndLocation />
        <PeekRespondents eventId={eventId} disabled={!isLoggedIn} hasTitle />
        {type !== 'place' ? (
          <React.Fragment>
            <h3>Join Event</h3>
            <JoinButtons
              joinLink={locationString}
              isNonUser={!isLoggedIn}
              passcode={zoomPasscode}
              eventName={event.title}
              eventUrl={eventUrl}
            />
          </React.Fragment>
        ) : null}
        {type === 'place' ? (
          <React.Fragment>
            <h3>Address</h3>
            <div>
              <p>
                {locationName}
                {locationName ? <br /> : null}
                {locationString}
              </p>
            </div>
          </React.Fragment>
        ) : null}
        <h3>Event Details</h3>
        <div className={classes.description}>
          {parseDescription(event.description)}
        </div>
        {event.url && !(type !== 'place' && locationString) ? (
          <EventUrl />
        ) : null}
        {event.filters?.attributes ? <EventTags /> : null}
        {ownerExists ? (
          <Organizer />
        ) : owner.name !== undefined ? (
          <React.Fragment>
            <h3>Organizer</h3>
            <div>
              <p>{owner.name}</p>
            </div>
          </React.Fragment>
        ) : null}
      </div>
      <div style={{ height: '80px', width: '100%' }} />
      <div className={classes.engagementBar}>
        <EngagementButtonContainer
          style={{ margin: 'auto' }}
          zoomLink={zoomLink}
          event={event}
          disabled={!isLoggedIn}
          cardType="EventDetails"
        />
      </div>
    </div>
  );
};

export default EventDetails;
