const constants = {
  HOUR_LIMIT: 4,
  HOUR_TO_MILLISECONDS: 3600000,
  DAYS_OF_WEEK: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
};

export const BACKGROUND_COLORS = [
  '#f44336',
  '#E91E63',
  '#9C27B0',
  '#673AB7',
  '#3F51B5',
  '#2196F3',
  '#03A9F4',
  '#00BCD4',
  '#009688',
  '#4CAF50',
  '#8BC34A',
  '#FF9800',
  '#795548',
];

// Engagement types for engageEvent action
export const engagements = {
  GOING: 'going',
  INTERESTED: 'interested',
  UNINTERESTED: 'uninterested',
};

// Rating types for rateTags action
export const tagRatings = {
  LIKE: 'like',
  DISLIKE: 'dislike',
  UNLIKE: 'unlike',
  UNDISLIKE: 'undislike',
};

export const filterTypes = {
  MOST_POPULAR: 'mostPopular',
  DATE: 'date',
  LOCATION_TYPE: 'locationType',
  PRICE: 'price',
  TAGS: 'tags',
  SOCIAL: 'social',
  MY_ORGS: 'myOrgs',
};

export const filterValues = {
  DATE: {
    TODAY: 0,
    TOMORROW: 1,
  },
  LOCATION_TYPE: {
    VIRTUAL: 'virtual',
    PLACE: 'place',
  },
  PRICE: {
    FREE: 'free',
    PAID: 'paid',
  },
};

export const MAX_PHOTO_SIZE = 5;
export const MB_TO_BYTES = 1024 * 1024;

export default constants;
