import React, { useState } from 'react';
import styled from 'styled-components';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { MB_TO_BYTES, MAX_PHOTO_SIZE } from '../../shared/constants';

const StyledUploadButton = styled.div`
  position: relative;

  form {
    display: flex;
  }

  #upload-button {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  #upload-button-label {
    cursor: pointer;
    color: #2f80ed;
  }

  #upload-alert-text {
    position: absolute;
    top: 25px;
    left: 0px;
    width: max-content;
    line-height: 100%;
  }
`;

const UploadButton = (props) => {
  const firebase = useFirebase();
  const firestore = useFirestore();
  const { uid } = props;

  const states = {
    INITIAL: 'INITIAL',
    UPLOADING: 'UPLOADING',
    SIZE_EXCEED: 'SIZE_EXCEED',
    UPLOAD_FAIL: 'UPLOAD_FAIL',
    UPLOAD_SUCCESS: 'UPLOAD_SUCESS',
  };
  const [state, setState] = useState(states.INITIAL);

  const imgUrlUpdate = (url) => {
    firestore.update(
      { collection: 'users', doc: uid },
      {
        photoUrl: url,
      }
    );
  };

  const handleFileUpload = async (event) => {
    const img = event.target.files[0];
    if (!img) return;

    if (img.size / MB_TO_BYTES > MAX_PHOTO_SIZE) {
      setState(states.SIZE_EXCEED);
      return;
    }
    const storageRef = firebase.storage().ref();
    const imgRef = storageRef.child(`users/${uid}`);
    try {
      setState(states.UPLOADING);
      await imgRef.put(img);
      const imgUrl = await imgRef.getDownloadURL();
      await imgUrlUpdate(imgUrl);
    } catch (err) {
      setState(states.UPLOAD_FAIL);
      console.error('Error uploading image: ', err);
      return;
    }
    setState(states.UPLOAD_SUCCESS);
  };

  let alertText;
  switch (state) {
    case states.SIZE_EXCEED:
      alertText = 'File size exceeds 5 MB';
      break;
    case states.UPLOADING:
      alertText = 'Uploading...';
      break;
    case states.UPLOAD_FAIL:
      alertText = 'Error occurred';
      break;
    default:
      alertText = null;
  }
  return (
    <StyledUploadButton>
      <form onSubmit={handleFileUpload}>
        <input
          type="file"
          onChange={handleFileUpload}
          id="upload-button"
          accept="image/*"
        />
        <label htmlFor="upload-button" id="upload-button-label">
          Change Photo
        </label>
      </form>
      <div id="upload-alert-text">{alertText}</div>
    </StyledUploadButton>
  );
};

export default UploadButton;
