import React from 'react';
import TabHeader from '../TabHeader';
import ForYouScreen from './foryou.jpg';
import GetStartedButton from './GetStartedButton';

const Welcome = () => {
  const promoText = 'Twine recommends experiences based on your interests.';
  return (
    <div>
      <TabHeader text="For You" />
      <img
        style={{ height: 'auto', width: 'calc(95% - 6.4vw)' }}
        src={ForYouScreen}
        alt="for you screen"
      />
      <GetStartedButton
        buttonLabel="See Your Recommendations"
        promoText={promoText}
      />
    </div>
  );
};

export default Welcome;
