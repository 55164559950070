import React, { useState } from 'react';
import { Badge } from '@material-ui/core';
import LoadingView from '../LoadingView';
import { makeStyles } from '@material-ui/core/styles';
import FollowButton from '../SocialTab/FollowButton';
import PropTypes from 'prop-types';
import UserAvatar from '../shared/UserAvatar';
import { useSelector } from 'react-redux';
import { generateUserSlices } from '../utils';
import EditProfile from '../ProfileTab/EditProfile';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '80px',
    width: '100%',
    boxSizing: 'border-box',
    padding: '0px 24px',
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridGap: '4px 16px',
    gridTemplateColumns: '64px auto',
    gridTemplateAreas: '"avatar name" "avatar follow-button"',
    justifyItems: 'left',
    '& .MuiBadge-root': {
      gridArea: 'avatar',
      alignSelf: 'center',
      justifySelf: 'center',
      '& .MuiBadge-badge': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  name: {
    margin: '0',
    display: 'inline-block',
    alignSelf: 'end',
    fontSize: '20px',
    fontWeight: '500',
  },
  followButton: {
    margin: '0',
    textTransform: 'capitalize',
    alignSelf: 'start',
    padding: '0',
  },
  editProfile: {
    alignSelf: 'start',
    margin: '0',
    padding: '0',
    fontSize: '13px',
    color: theme.palette.secondary.main,
  },
}));

const ProfileHeader = (props) => {
  const { profile, uid, isOwnProfile } = props;
  const allUsers = useSelector(({ firestore }) => firestore.data?.meta?.users);
  const classes = useStyles();
  const [showEditProfile, toggleShowEditProfile] = useState(false);
  if (!uid) return <LoadingView />;
  const slice = generateUserSlices([uid], allUsers)[0] ?? {};
  const { firstName, lastName, classYear } = slice;
  const badgeContent = classYear ? (
    <p>{`'${classYear.toString().slice(2)}`}</p>
  ) : null;

  const handleClick = () =>
    !showEditProfile ? toggleShowEditProfile(true) : null;

  const ActionButton = () => {
    if (isOwnProfile)
      return (
        <button className={classes.editProfile} onClick={handleClick}>
          Edit Profile
        </button>
      );
    else
      return (
        <FollowButton
          className={classes.followButton}
          friendUid={uid}
          profile={profile}
        />
      );
  };

  return (
    <div className={classes.root}>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        color="secondary"
        className={classes.avatar}
        badgeContent={badgeContent}
      >
        <UserAvatar slice={slice} size="large" />
      </Badge>
      <h3 className={classes.name}>{`${firstName} ${lastName}`}</h3>
      <ActionButton />
      <EditProfile open={showEditProfile} toggle={toggleShowEditProfile} />
    </div>
  );
};

ProfileHeader.propTypes = {
  uid: PropTypes.string.isRequired,
  isOwnProfile: PropTypes.bool,
};

export default ProfileHeader;
