import React from 'react';
import OrgsPickerContainer from './OrgsPickerContainer';
import TabHeader from '../TabHeader';

const PickAcademicOrgs = () => {
  return (
    <div>
      <TabHeader text="Pick Your Academic Orgs" backButton />
      <p>
        Select any academic organizations you might be interested in. (This is
        liberal arts college. Don't play yourself by picking just one.)
      </p>
      <OrgsPickerContainer isDepartment={true} rerouteOnDone="/onboarding/3" />
    </div>
  );
};

export default PickAcademicOrgs;
