import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';

var config = {
  apiKey: 'AIzaSyCgiLhxEWJhzoT6fw60TXNRF7003wUfHzo',
  authDomain: 'obielocal-1541269219020.firebaseapp.com',
  databaseURL: 'https://obielocal-1541269219020.firebaseio.com',
  projectId: 'obielocal-1541269219020',
  storageBucket: 'obielocal-1541269219020.appspot.com',
  messagingSenderId: '88223254036',
  appId: '1:88223254036:web:353aee178d17b6d1',
  measurementId: 'G-81662GZVSE',
};

export default config;
