import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';
import recommendationsReducer from './recommendationsReducer';
import socialReducer from './socialReducer';
import uiReducer from './uiReducer';

export default combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  recommendations: recommendationsReducer,
  social: socialReducer,
  ui: uiReducer,
});
