import React from 'react';
import { ReactComponent as TutorialIcon } from './feather_book.svg';
import { IconButton } from '@material-ui/core';
import { makeSettingsButtonStyles } from './styles';
import { useHistory } from 'react-router';
import { getLocationObj } from '../utils';

const useStyles = makeSettingsButtonStyles;

const TutorialButton = ({ className, ...props }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={`${classes.root} ${className}`}>
      <div>
        <h3>Tutorial</h3>
        <p>View the Twine tutorial.</p>
      </div>
      <IconButton
        onClick={() =>
          history.push(getLocationObj('/profile/settings/tutorial'))
        }
      >
        <TutorialIcon />
      </IconButton>
    </div>
  );
};

export default TutorialButton;
