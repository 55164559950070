import {
  filterTypes,
  filterValues,
  BACKGROUND_COLORS,
} from '../shared/constants';

/**
 * Return the 0-based day out of the year. (January 1st returns 0)
 * @param {Date} date
 */
export const getDayOfYear = (date) => {
  if (!date) return -1;
  var start = new Date(date.getFullYear(), 0, 0);
  var diff =
    date -
    start +
    (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  return day;
};

export const pseudoRandomGenerator = (seed) => {
  return () => {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };
};

const dayInWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const dayInWeekAbbr = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const monthInYear = [
  'Jan',
  'Feb',
  'March',
  'April',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const dateTime = require('node-datetime');

export function getEventTimeString(startTimeInput, endTimeInput) {
  /* Note that the Date constructor automatically adjusts for timezone */
  const startTime = new Date(startTimeInput);

  /*format times to display hour, minute, and period in 12 hour time*/
  let startHourString = dateTime.create(startTime, 'I:M p').format();
  let startHour = startTime.getHours();
  if ((startHour > 0 && startHour < 10) || (startHour > 12 && startHour < 22))
    startHourString = startHourString.slice(1);
  else if (startHour === 0) startHourString = '';

  let endTime;
  if (endTimeInput) {
    const endTimeUTC = new Date(endTimeInput);
    endTime = dateTime.create(endTimeUTC, 'I:M p').format();
  }
  endTime = endTime ? ` - ${endTime}` : '';

  return `${startHourString}${endTime}`;
}

export function getEventDateString(startTimeInput, abbr = false) {
  const startTime = new Date(startTimeInput);
  if (!startTime) return 'Time unknown';
  let date;
  if (isToday(startTime)) {
    if (startTime.getHours() < 18) date = 'Today';
    else date = 'Tonight';
  } else if (isTomorrow(startTime)) {
    date = 'Tomorrow';
  } else if (abbr) {
    date = `
    ${dayInWeekAbbr[startTime.getDay()]},  ${
      monthInYear[startTime.getMonth()]
    } ${startTime.getDate()}`;
  } else {
    date = `
    ${dayInWeek[startTime.getDay()]},  ${
      monthInYear[startTime.getMonth()]
    } ${startTime.getDate()}`;
  }
  return date;
}

export function getEventDateStringAbbr(startTimeInput) {
  return getEventDateString(startTimeInput, true);
}

export function isToday(time) {
  const now = new Date();
  return (
    now.getFullYear() === time.getFullYear() &&
    now.getMonth() === time.getMonth() &&
    now.getDate() === time.getDate()
  );
}

export function isTomorrow(time) {
  const now = new Date();
  const tmr = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1);
  return (
    tmr.getFullYear() === time.getFullYear() &&
    tmr.getMonth() === time.getMonth() &&
    tmr.getDate() === time.getDate()
  );
}

/**
 * Create a Google calendar template render from an event.
 * @param {Object} event Event object from fs
 * @param {String} zoomLink Optional zoom link
 */
export function getCalendarLink(event, zoomLink) {
  const {
    title,
    time: { start, end },
    location,
  } = event;
  let address = location?.address ?? null;

  let dateToTimeStr = (date) =>
    `${date.toISOString().replace(/[.:-]/g, '').slice(0, -4)}Z`;

  const startDate = start.toDate();
  const startStr = dateToTimeStr(startDate);
  let endStr;
  if (end) endStr = dateToTimeStr(end.toDate());
  else {
    const startTime = startDate.getTime();
    const newEndDate = new Date(startDate.setTime(startTime + 3600000));
    endStr = dateToTimeStr(newEndDate);
  }

  let str = `https://www.google.com/calendar/event?action=TEMPLATE`;
  str = `${str}&text=${encodeURIComponent(title)}`;
  str = `${str}&dates=${startStr}/${endStr}`;
  str = `${str}&location=${encodeURIComponent(address || zoomLink || '')}`;
  str = `${str}&details=${encodeURIComponent(event.description)}`;
  return str;
}

// Detects if device is on iOS
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
};
// Detects if device is in standalone mode
export const isInStandaloneMode = () =>
  ('standalone' in window.navigator && window.navigator.standalone) ||
  window.matchMedia('(display-mode: standalone)').matches;

// Detects if using mobile browser
export const isMobileBrowser = () => {
  let feature = navigator.userAgent || navigator.vendor || window.opera;
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      feature
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw-(n|u)|c55\/|capi|ccwa|cdm-|cell|chtm|cldc|cmd-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc-s|devi|dica|dmob|do(c|p)o|ds(12|-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(-|_)|g1 u|g560|gene|gf-5|g-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd-(m|p|t)|hei-|hi(pt|ta)|hp( i|ip)|hs-c|ht(c(-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i-(20|go|ma)|i230|iac( |-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|-[a-w])|libw|lynx|m1-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|-([1-8]|c))|phil|pire|pl(ay|uc)|pn-2|po(ck|rt|se)|prox|psio|pt-g|qa-a|qc(07|12|21|32|60|-[2-7]|i-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h-|oo|p-)|sdk\/|se(c(-|0|1)|47|mc|nd|ri)|sgh-|shar|sie(-|m)|sk-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h-|v-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl-|tdg-|tel(i|m)|tim-|t-mo|to(pl|sh)|ts(70|m-|m3|m5)|tx-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas-|your|zeto|zte-/i.test(
      feature.substr(0, 4)
    )
  )
    return true;
  else return false;
};

export const stringHashCode = (s) => {
  for (var i = 0, h = 0; i < s.length; i++)
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return h;
};

export const generateAvatarColor = (uid) => {
  const random = pseudoRandomGenerator(stringHashCode(uid));
  const idx = Math.floor((random() * 100) % BACKGROUND_COLORS.length);
  return BACKGROUND_COLORS[idx];
};

/**
 * Generates a function to pass to Array.prototype.map, mapping a set of events based on a certain filter.
 * @param {Object} filter Filter by which to map events
 * @param {string} filter.type Filter type
 * @param {(string|string[]|number[])} filter.value Filter value
 */
export const filterEvent = (filter) => {
  // Check for invalid filter type (don't apply it if it's void)
  if (!Object.values(filterTypes).includes(filter.type)) return () => true;

  switch (filter.type) {
    case filterTypes.MY_ORGS:
      return (event) => {
        if (filter.value.orgs?.includes(event?.owner?.id)) {
          return true;
        }
      };
    case filterTypes.MOST_POPULAR:
      return () => true;
    case filterTypes.PRICE:
      const { FREE, PAID } = filterValues.PRICE;
      return (event) =>
        (event.cost && filter.value === PAID) ||
        (!event.cost && filter.value === FREE);
    case filterTypes.LOCATION_TYPE:
      const { PLACE, VIRTUAL } = filterValues.LOCATION_TYPE;
      return (event) => {
        if (filter.value === PLACE) return event.location?.type === 'place';
        else if (filter.value === VIRTUAL)
          return event.location?.type !== 'place';
        else return false;
      };
    case filterTypes.DATE:
      const dateArr = filter.value;
      const todayDate = getDayOfYear(new Date());
      return (event) => {
        const eventStart = event.time && event.time.start;
        if (!eventStart) return false;
        const eventDate = getDayOfYear(new Date(eventStart.seconds * 1000));
        return dateArr.includes(eventDate - todayDate);
      };
    case filterTypes.TAGS:
      return filterByTags(filter.value);
    case filterTypes.SOCIAL:
      return filterByFriend(
        filter.value.userEvents,
        filter.value.friendEvents,
        filter.value.friendFirstName
      );
    default:
      return () => true;
  }
};

/**
 * Generates a function to pass to Array.prototype.map, mapping a set of events
 * based similarity in preferences, intentions, and recommendation of user and a friend
 * @param {object} userEvents events field of the firestore doc of the current user
 * @param {object} friendEvents events field of the firestore doc of a friend of the user
 */
export const filterByFriend = (userEvents, friendEvents, friendFirstName) => {
  if (!friendEvents || !userEvents) return (_event) => false;
  var going1 = userEvents.going ?? [];
  var interested1 = userEvents.interested ?? [];
  var recommended1 = userEvents.recommended ?? [];
  var going2 = friendEvents.going ?? [];
  var interested2 = friendEvents.interested ?? [];
  var recommended2 = friendEvents.recommended ?? [];

  const eventScores = {};

  const calculateScore = (list1, list2, score) => {
    const l1 = [...list1];
    const l2 = [...list2];
    l1.forEach((eventId, currentIdx) => {
      const idx = l2.indexOf(eventId);
      if (idx !== -1) {
        l1.splice(currentIdx, 1);
        l2.splice(idx, 1);
        eventScores[eventId] = eventScores[eventId]
          ? eventScores[eventId]
          : score;
      }
    });
    return [l1, l2];
  };

  [going1, going2] = calculateScore(going1, going2, 5);
  [going1, interested2] = calculateScore(going1, interested2, 4);
  [interested1, going2] = calculateScore(interested1, going2, 4);
  [going1, recommended2] = calculateScore(going1, recommended2, 3);
  [recommended1, going2] = calculateScore(recommended1, going2, 3);
  [interested1, interested2] = calculateScore(interested1, interested2, 2);
  [interested1, recommended2] = calculateScore(interested1, recommended2, 1);
  [recommended1, interested2] = calculateScore(recommended1, interested2, 1);
  calculateScore(recommended1, recommended2, 0);

  const randomByDay = pseudoRandomGenerator(getDayOfYear(new Date()));
  const randomByString = pseudoRandomGenerator(stringHashCode(friendFirstName));
  if (Object.keys(eventScores).length < 3)
    return (event, idx) =>
      eventScores[event.eventId] ||
      (idx + (Math.floor((randomByDay() + randomByString()) * 10000000) % 3)) %
        3 ===
        0;

  return (event) => eventScores[event.eventId];
};

/**
 * Generates a function to pass to Array.prototype.map, mapping a set of events based on a certain set of tags.
 * @param {string[]} tags Tag array by which to filter events
 */
export const filterByTags = (tags) => {
  return (event) => {
    const filters = event.filters || {};
    const attributes = filters?.attributes ?? [];
    const departments = filters?.departments ?? [];
    const combinedFilters = attributes.concat(departments);
    return tags.every((tag) => combinedFilters.includes(tag));
  };
};

/**
 * A user slice, to be used for displaying user details outside of a profile.
 * @typedef {Object} UserSlice
 * @property {string} uid - Unique user ID.
 * @property {string} firstName - User first name.
 * @property {string} lastName - User last name.
 * @property {number} classYear - User class year.
 * @property {string} [photoUrl=""] - Permanent avatar image url
 */

// TODO(CP): Should use middleware instead of passing state here.
/**
 * Retrieves user slices for an array of uids.
 * @param {string[]} uids Array of userIds to retrieve
 * @param {Object} allUsers meta users document object
 * @returns {UserSlice[]} Array of user slices
 */
export const generateUserSlices = (uids = [], allUsers = {}) => {
  const slices = [];
  for (let uid of uids) {
    if (allUsers[uid]) slices.push({ uid, ...allUsers[uid] });
  }
  return slices;
};

/**
 * Retrieves user slices for an array of uids.
 * @param {string} uids uid to retrieve
 * @param {Object} allUsers meta users document object
 * @returns {UserSlice} User slice
 */
export const generateOneUserSlice = (uid = '', allUsers = {}) =>
  allUsers[uid] ? { uid, ...allUsers[uid] } : null;

/**
 * Sort events by start time
 * @param {Object[]} events array of events to sort
 * @returns {Object[]} sorted array of events
 */
export const sortEventsByStartTime = (events = []) =>
  events.sort((event1, event2) => {
    return event1.time.start.seconds - event2.time.start.seconds;
  });

/**
 * Takes a path and returns location
 * @param {string} pathname
 * @returns {Object} location object
 */
export const getLocationObj = (pathname) => {
  const location = {
    pathname: pathname,
    state: { fromTwine: true },
  };
  return location;
};
