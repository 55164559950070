import React from 'react';
import TwineWordmark from '../shared/images/twine-wordmark-400w.png';

const SplashHeader = ({ className }) => (
  <div className={className}>
    <h2 style={{ margin: '0px 0px 8px 0px' }}>Welcome to</h2>
    <img src={TwineWordmark} alt="twine" style={{ width: '140px' }} />
  </div>
);

export default SplashHeader;
