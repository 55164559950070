import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getLocationObj } from '../utils';

// TODO(CP): Obviously should be refactored to include retrieveImage hook in this component
const useStyles = makeStyles((theme) => ({
  root: {
    height: (props) =>
      props.large ? '160px' : props.orangeHighlight ? '94px' : '88px',
    width: (props) =>
      props.large ? '312px' : props.orangeHighlight ? '162px' : '156px',
    border: (props) =>
      props.large || props.orangeHighlight ? '4px transparent' : '4px solid',
    display: 'flex',
    flexFlow: 'column nowrap',
    position: 'relative',
    borderRadius: '4px',
    borderColor: (props) =>
      props.blueHighlight ? theme.palette.secondary.main : 'transparent',
    boxShadow: (props) => {
      if (props.large) return 'rgb(100 100 100) 0px 0px 6px 5px';
      else if (props.orangeHighlight) return '#ff521e 0px 0px 6px 5px';
      else return 'transparent';
    },
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  name: {
    color: 'white',
    margin: '8px',
    position: 'absolute',
    bottom: '0',
    left: '0',
    fontSize: (props) => (props.large ? '25px' : '16px'),
    fontWeight: (props) => (props.large ? '600' : '400'),
    textAlign: 'left',
  },
  vignette: {
    width: '100%',
    height: '100%',
    boxShadow: 'inset 0px -64px 68px -36px rgba(10, 0, 0, 1)',
    borderRadius: '4px',
  },
  picture: {
    width: '100%',
    height: '100%',
    backgroundImage: (props) =>
      ` url("${props.org?.imageUrl}"), linear-gradient(45deg, #FFCE00 10.02%, #FF521E 50.02%, #C7003A 89.98%)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    borderRadius: '4px',
  },
}));

const OrganizationCard = (props) => {
  const { org, orgId } = props;
  const history = useHistory();
  const classes = useStyles(props);
  let { onClick } = props;

  if (!onClick)
    onClick = () => history.push(getLocationObj(`/organizations/${orgId}`));
  return (
    <div className={classes.root} onClick={onClick}>
      <div className={classes.picture}>
        <div className={classes.vignette} />
      </div>
      <h4 className={classes.name}>{org?.name}</h4>
    </div>
  );
};

OrganizationCard.propTypes = {
  orgId: PropTypes.string.isRequired,
  blueHighlight: PropTypes.bool,
  onClick: PropTypes.func,
  large: PropTypes.bool,
  orangeHighlight: PropTypes.bool,
};

const mapStateToProps = ({ firestore }, { orgId }) => {
  return {
    org: firestore.data.meta?.organizations?.[orgId],
  };
};

export default connect(mapStateToProps)(OrganizationCard);
