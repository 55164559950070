import React from 'react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ShareIconButton } from '../shared/ShareCopyButton.js';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import ClampLines from 'react-clamp-lines';
import PropTypes from 'prop-types';
import { makeHeaderStyles as useStyles } from '../EventDetails/styles';
import { Button } from '@material-ui/core';
import { useFirestore } from 'react-redux-firebase';
import DoneIcon from '@material-ui/icons/Done';
import AddIcon from '@material-ui/icons/Add';
import { useLocation } from 'react-router-dom';
import { getLocationObj } from '../utils';

const Header = (props) => {
  let buttonText, StartIcon;
  const { org, uid } = props;
  const history = useHistory();
  const orgUrl = `${window.location.origin}/organizations/${org.orgId}`;
  const classes = useStyles(props);
  const firestore = useFirestore();
  let isFollowing;
  let disabled;
  const location = useLocation();
  let handler;
  if (location.state?.fromTwine) {
    handler = () => history.goBack();
  } else {
    handler = () => history.push(getLocationObj('/'));
  }

  if (!uid) {
    disabled = true;
  }

  const followHandler = () => async () => {
    try {
      if (isFollowing) {
        await firestore.update(
          { collection: 'organizations', doc: org.orgId },
          { followers: firestore.FieldValue.arrayRemove(uid) }
        );
        await firestore.update(
          { collection: 'users', doc: uid },
          { orgs: firestore.FieldValue.arrayRemove(org.orgId) }
        );
      } else {
        await firestore.update(
          { collection: 'organizations', doc: org.orgId },
          { followers: firestore.FieldValue.arrayUnion(uid) }
        );
        await firestore.update(
          { collection: 'users', doc: uid },
          { orgs: firestore.FieldValue.arrayUnion(org.orgId) }
        );
      }
    } catch (err) {
      console.error('Something went wrong with the Follow button:');
      console.error(err);
    }
  };

  if (org?.followers?.includes(uid)) {
    isFollowing = true;
    buttonText = 'Following';
    StartIcon = <DoneIcon />;
  } else {
    isFollowing = false;
    buttonText = 'Follow';
    StartIcon = <AddIcon />;
  }

  return (
    <div className={classes.root}>
      <div className={classes.vignette}>
        <div className={classes.wrapper}>
          <div className={classes.buttons}>
            <IconButton onClick={handler}>
              <ArrowBackIcon fontSize="large" />
            </IconButton>
            <ShareIconButton title={org.name} url={orgUrl} />
          </div>
          <div className={classes.bottomWrapper}>
            <ClampLines
              text={org.name}
              lines={4}
              innerElement="h1"
              buttons={false}
            />
            {!disabled ? (
              <Button
                disabled={disabled}
                onClick={followHandler()}
                variant={'contained'}
                className={classes.button}
                size={'small'}
                startIcon={StartIcon}
              >
                {buttonText}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  org: PropTypes.shape({
    name: PropTypes.string.isRequired,
    orgId: PropTypes.string.isRequired,
  }),
  src: PropTypes.string.isRequired,
  uid: PropTypes.string,
};

export default Header;
