import React, { useState } from 'react';
import { Drawer, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as XIcon } from '../shared/images/x.svg';
import PropTypes from 'prop-types';
import EditProfilePicture from '../EditProfilePicture';
import EditProfileForm from '../Onboarding/EditProfileForm';
import BigPrimaryButton from '../shared/BigPrimaryButton';
import { connect } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: `calc(100% - ${theme.spacing(6)}px)`,
    boxSizing: 'border-box',
    padding: '16px 24px',
    '& h3': {
      margin: `${theme.spacing(1)}px 0px`,
    },
  },
  header: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h1': {
      display: 'inline-block',
      position: 'relative',
      fontSize: '24px',
      lineHeight: '31px',
      color: theme.palette.primary.main,
      margin: '0px',
    },
  },
  uploader: {
    textAlign: 'left',
    margin: '0px 24px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    width: '100%',
    bottom: '48px',
    left: '0px',
    textAlign: 'center',
    pointerEvents: 'none',
    '& button': {
      pointerEvents: 'auto',
    },
  },
}));

const ErrorMessage = () => (
  <div>
    <p style={{ fontSize: '16px', color: 'red' }}>
      Sorry, you need to fill out all of these fields for your profile!
    </p>
  </div>
);

const EditProfile = (props) => {
  const { open, toggle, profile } = props;
  const firebase = useFirebase();
  const firestore = useFirestore();
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    classYear: '',
  });
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [profileIsLoaded, setProfileIsLoaded] = useState(false);
  if (profile.isLoaded && !profileIsLoaded) {
    setFormState({
      ...formState,
      firstName: profile.firstName || '',
      lastName: profile.lastName || '',
      classYear: profile.classYear || '',
    });
    setProfileIsLoaded(true);
  }

  const handleChange = (e, fieldName) => {
    let value = e.target.value;
    setFormState({
      ...formState,
      [fieldName]: value,
    });
  };
  const handleSubmit = () => {
    const { firstName, lastName, classYear } = formState;
    if (!firstName || !lastName || !classYear) {
      setDisplayErrorMessage(true);
      return;
    }
    firebase.auth().currentUser.updateProfile({ displayName: firstName });
    firestore
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .update({
        uid: firebase.auth().currentUser.uid,
        firstName,
        lastName,
        classYear,
      })
      .then(() => {
        toggle(false);
      })
      .catch((err) => console.error(`ERROR: Account Update\n ${err.message}`));
  };

  const classes = useStyles();
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => toggle(false)}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.header}>
        <h1>Edit Profile</h1>
        <IconButton onClick={() => toggle(false)}>
          <XIcon />
        </IconButton>
      </div>
      <div>
        <h3>Profile Picture</h3>
        <EditProfilePicture />
        <EditProfileForm handleChange={handleChange} formState={formState} />
        {displayErrorMessage ? <ErrorMessage /> : null}
        <div className={classes.buttonWrapper}>
          <BigPrimaryButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Continue
          </BigPrimaryButton>
        </div>
      </div>
    </Drawer>
  );
};

EditProfile.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func.isRequired,
};

export default connect(({ firebase }) => ({ profile: firebase.profile }))(
  EditProfile
);
