import { makeStyles } from '@material-ui/core/styles';

export const makeEventDetailsStyles = makeStyles((theme) => ({
  root: {
    textAlign: 'left',
    paddingBottom: '45px',
  },
  content: {
    padding: `0px ${theme.spacing(3)}px`,
    '& h3': {
      margin: `${theme.spacing(3)}px 0px 0px 0px`,
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0px',
      fontWeight: '500',
    },
    '& p': {
      margin: `${theme.spacing(0.5)}px 0px 0px 0px`,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      lineHeight: '20px',
      '& a': {
        color: theme.palette.secondary.main,
      },
    },
  },
  timeAndLocation: {
    paddingTop: theme.spacing(0.5),
    '& p': {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '500',
      '& span': {
        color: '#c7003a',
      },
    },
  },
  eventTags: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: theme.spacing(1),
  },
  organizer: { marginTop: theme.spacing(1) },
  engagementBar: {
    position: 'fixed',
    bottom: `calc(85px + ${theme.spacing(2)}px)`,
    width: '100%',
    justifyContent: 'spaceAround',
    backgroundColor: 'white',
    alignItems: 'center',
  },
}));

export const makeHeaderStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: (props) => `url("${props.src}")`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: '100%',
    color: 'white',
    height: '240px',
    top: '0px',
    position: 'relative',
  },
  vignette: {
    width: '100%',
    height: '100%',
    margin: '0px',
    boxShadow: 'inset 0px -125px 117px -19px rgba(10, 0, 0, 1)',
  },
  buttons: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    position: 'relative',
    zIndex: '1',
    '& .MuiIconButton-root': {
      padding: '0',
      color: 'white',
    },
  },
  eventType: {
    backgroundColor: 'orange',
    width: 'fit-content',
    fontSize: '18pt',
    padding: '1%',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5px',
  },
  wrapper: {
    padding: '24px',
    boxSizing: 'border-box',
    width: '100%',
    height: '100%',
  },
  bottomWrapper: {
    position: 'absolute',
    bottom: '0px',
    marginBottom: theme.spacing(3),
    //marginRight: theme.spacing(3),
    width: '88%',
    '& h1': {
      margin: `${theme.spacing(1)}px 0px 0px 0px`,
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: '700',
    },
  },
}));
