import React from 'react';
import { TextField, FormControl, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '85vw',
    textAlign: 'left',
    margin: 'auto',
    '& .MuiFormControl-root': {
      width: '100%',
      margin: `${theme.spacing(1)}px 0px 0px 0px`,
      textAlign: 'left',
    },
    '& .MuiInputBase-root': {
      backgroundColor: '#F2F2F2',
    },
    '& .MuiInputBase-input': {
      padding: theme.spacing(1.5),
    },
    '& label': {
      display: 'block',
      margin: `${theme.spacing(3)}px 0px 0px 0px`,
      fontSize: '16px',
      lineHeight: '20px',
      fontWeight: '500',
    },
  },
}));

const EditProfileForm = (props) => {
  const { formState, handleChange } = props;
  const classes = useStyles();
  return (
    <form className={classes.root}>
      <label>First Name</label>
      <TextField
        id="signup-firstName"
        variant="filled"
        size="small"
        margin="dense"
        onChange={(e) => handleChange(e, 'firstName')}
        value={formState.firstName}
        InputProps={{ disableUnderline: true }}
      />
      <label>Last Name</label>
      <TextField
        id="signup-lastName"
        variant="filled"
        size="small"
        margin="dense"
        onChange={(e) => handleChange(e, 'lastName')}
        value={formState.lastName}
        InputProps={{ disableUnderline: true }}
      />
      <label>Class Year</label>
      <FormControl variant="filled">
        <Select
          labelId="demo-simple-select-filled-label"
          id="demo-simple-select-filled"
          value={formState.classYear}
          onChange={(e) => handleChange(e, 'classYear')}
          disableUnderline
        >
          <MenuItem value="">
            <em>Choose</em>
          </MenuItem>
          <MenuItem value={2021}>2021</MenuItem>
          <MenuItem value={2022}>2022</MenuItem>
          <MenuItem value={2023}>2023</MenuItem>
          <MenuItem value={2024}>2024</MenuItem>
          <MenuItem value={2025}>2025</MenuItem>
        </Select>
      </FormControl>
    </form>
  );
};

EditProfileForm.propTypes = {
  formState: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    classYear: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  handleChange: PropTypes.func.isRequired,
};

export default EditProfileForm;
