import React from 'react';
import TabHeader from '../TabHeader';
import { useHistory, useParams } from 'react-router-dom';
import TwineLytics from '../../twineLytics';
import { getFirebase } from 'react-redux-firebase';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TwineWordmark from '../shared/images/twine-wordmark-400w.png';
import jwt_decode from 'jwt-decode';
import { getLocationObj } from '../utils';
import PropTypes from 'prop-types';

const DOMAIN = 'oberlin.edu';

const useStyles = makeStyles((theme) => ({
  content: {
    position: 'absolute',
    top: '35%',
    width: '100%',
    '& img': {
      display: 'block',
      width: '60%',
      margin: 'auto',
      marginBottom: '36px',
    },
    '& p': {
      margin: '15px',
    },
  },
}));

const validateToken = (idToken, allowlist) => {
  const token = jwt_decode(idToken);
  return token.hd === DOMAIN || allowlist?.includes(token.email);
};

const googleSignIn = (hostDomain) => {
  let firebase = getFirebase();
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ hd: hostDomain });
  firebase.auth().signInWithRedirect(provider);
};

const handleRedirectFromSignin = async (history, allowlist) => {
  const firebase = getFirebase();
  const result = await firebase.auth().getRedirectResult();
  const tl = new TwineLytics();
  if (!result.user) return;
  else {
    if (!validateToken(result.credential.idToken, allowlist)) {
      console.error(
        'Error: Invalid host domain. Please log in using your oberlin.edu account.'
      );
      window.alert(
        'Invalid host domain. Please log in using your oberlin.edu account.'
      );
      firebase
        .auth()
        .signOut()
        .then(() => history.push(getLocationObj('/')));
    } else if (result.additionalUserInfo?.isNewUser) {
      tl.logEvent('sign_up');
      history.push(getLocationObj('/onboarding'));
    } else history.push(getLocationObj('/'));
  }
};

const Login = (props) => {
  const history = useHistory();
  const { allowlist } = props;
  const { email } = useParams();
  handleRedirectFromSignin(history, allowlist);

  const signIn = () => {
    if (allowlist && allowlist.includes(email)) return googleSignIn('*');
    else return googleSignIn(DOMAIN);
  };
  const prompt =
    allowlist && allowlist.includes(email)
      ? `Please sign in using the email ${email}.`
      : 'Twine works with your existing Oberlin account.';
  const classes = useStyles();
  return (
    <div>
      <TabHeader text="Sign In" backButton />
      <div className={classes.content}>
        <img src={TwineWordmark} alt="twine" />
        <Button variant="contained" onClick={signIn} color="secondary">
          Sign In
        </Button>
        <p>{prompt}</p>
      </div>
    </div>
  );
};

Login.propTypes = {
  allowlist: PropTypes.arrayOf(PropTypes.string),
};

export default Login;
