import styled from 'styled-components';

export const StyledWrapperDiv = styled.div`
  display: flex;
  background: whitesmoke;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 85px;
  font-size: 12px;
  z-index: 2;

  a {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    line-height: 8px;
    height: 100%;
    width: 20%;
    color: #a1a1a1;
  }

  .tab-selected {
    color: #ff521e;
  }
`;
