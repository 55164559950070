import React from 'react';
import PersonalizedIllustration from './splash1.svg';
import HubIllustration from './splash2.svg';
import ExperienceIllustration from './splash3.svg';
// eslint-disable-next-line no-unused-vars
import styles from 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .carousel .slider-wrapper': { paddingBottom: '40px' },
    '& .carousel .slide': {
      touchAction: 'pan-x',
      background: 'white',
      '& img': {
        width: '160px',
      },
    },
    '& .carousel .control-dots': {
      '& .dot': {
        background: '#E0E0E0',
        width: '4px',
        height: '4px',
        boxShadow: 'none',
        margin: '0px 4px',
      },
      '& .dot.selected': {
        background: theme.palette.primary.main,
        width: '8px',
        borderRadius: '25px',
      },
    },
    '& p': {
      fontSize: '14px',
      lineHeight: '20px',
      textAlign: 'center',
      width: '280px',
      margin: 'auto',
    },
    '& h2': {
      fontSize: '20px',
      lineHeight: '30px',
      margin: `${theme.spacing(3)}px 0px 0px`,
    },
  },
}));

const SplashCarousel = ({ className }) => {
  const classes = useStyles();
  return (
    <Carousel
      className={`${className} ${classes.root}`}
      showArrows={false}
      showStatus={false}
      infiniteLoop={false}
      showThumbs={false}
    >
      <div>
        <img src={PersonalizedIllustration} alt="Personzlized Experience" />
        <h2>Personalized Experience</h2>
        <p>Less time figuring out stuff to do - more time spent doing.</p>
      </div>
      <div>
        <img src={HubIllustration} alt="Personzlized Experience" />
        <h2>Campus Life Hub</h2>
        <p>Wait, really? I'm actually going to know what's going on?</p>
      </div>
      <div>
        <img src={ExperienceIllustration} alt="Personzlized Experience" />
        <h2>Always Intuitive</h2>
        <p>Less scrolling = happy thumbs.</p>
      </div>
    </Carousel>
  );
};

export default SplashCarousel;
