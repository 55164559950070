import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import SmallCardCarousel from '../shared/SmallCardCarousel';
import OrgPlaceholder from '../shared/images/placeholder-org.png';
import { makeOrganizationDetailsStyles as useStyles } from './styles';
import LoadingView from '../LoadingView';
import { generateOneUserSlice } from '../utils';
import UnconnectedUserCard from '../SocialTab/UnconnectedUserCard';
import UserCard from '../SocialTab/UserCard';
import Header from './Header';

function OrganizationDetails(props) {
  const { allUsers = [], org = {}, eventsMap, uid } = props;
  const classes = useStyles();
  const contactEmail = org?.contactEmail || org?.contact?.email;
  const leadership = org?.leadership;
  const hasOldFormat = !leadership;
  const socialMedia = org?.socialMedia;
  const eventList = [];

  const allEvents = [];
  if (eventsMap)
    Object.keys(eventsMap).forEach((eventId) =>
      allEvents.push(eventsMap[eventId])
    );

  for (const event of allEvents) {
    if (event?.owner?.id === org.orgId) {
      eventList.push(event.eventId);
    }
  }

  if (!org || Object.keys(org).length === 0) return <LoadingView />;

  const LeadershipCards = () => {
    let children;
    if (hasOldFormat && org.contact)
      children = <UnconnectedUserCard fullName={org.contact.name} />;
    else if (!hasOldFormat && leadership.length > 0) {
      children = leadership.map((str) => {
        let slice = generateOneUserSlice(str, allUsers);
        if (slice)
          return <UserCard key={slice.uid} uid={slice.uid} disableLink />;
        else return <UnconnectedUserCard key={str} fullName={str} />;
      });
    } else children = <p>None</p>;
    return <div className={classes.leadershipCards}>{children}</div>;
  };

  const SocialMedia = () => {
    if (!socialMedia) return null;
    const { facebook, instagram } = socialMedia;
    if (!facebook && !instagram) return <p>None</p>;
    const fbRegex = /\.com\/\w+/i;
    const fbLabel = fbRegex.test(facebook)
      ? facebook?.match(fbRegex)[0].substr(5)
      : null;
    const httpsProtocolRegex = /https?:\/\//;
    const fbUrl = httpsProtocolRegex.test(facebook)
      ? facebook
      : `https://${facebook}`;

    const igLabel = instagram;
    const igUrl = `https://instagram.com/${igLabel.substr(1)}/`;
    return (
      <p>
        {fbLabel ? (
          <React.Fragment>
            Facebook:{' '}
            <a href={fbUrl} target="_blank" rel="noopener noreferrer">
              {fbLabel}
            </a>
            <br />
          </React.Fragment>
        ) : null}
        {igLabel ? (
          <React.Fragment>
            Instagram:{' '}
            <a href={igUrl} target="_blank" rel="noopener noreferrer">
              {igLabel}
            </a>
            <br />
          </React.Fragment>
        ) : null}
      </p>
    );
  };

  return (
    <div className={classes.root}>
      <Header org={org} src={org.imageUrl || OrgPlaceholder} uid={uid} />
      <div className={classes.content}>
        <h3>Upcoming Events</h3>
        {eventList.length !== 0 ? (
          <SmallCardCarousel items={eventList} type="events" />
        ) : (
          <p>No upcoming events found.</p>
        )}
        <h3>Introduction</h3>
        {org.description ? <p>{org.description}</p> : <p>None</p>}
        <h3>{org.department ? 'Administrative Assistant' : 'Leadership'}</h3>
        <LeadershipCards />
        <h3>Contact Email</h3>
        {contactEmail ? (
          <p>
            <a
              href={`mailto:${contactEmail}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {contactEmail}
            </a>
          </p>
        ) : (
          <p>None</p>
        )}
        {!org.department ? (
          <React.Fragment>
            <h3>Social Media</h3>
            {org.socialMedia ? <SocialMedia /> : <p>None</p>}
          </React.Fragment>
        ) : null}
      </div>
    </div>
  );
}

export default compose(
  firestoreConnect((props) => [
    { collection: 'organizations', doc: props.match.params.id },
  ]),
  connect(({ firestore, firebase }, ownProps) => ({
    org: firestore.data.organizations?.[ownProps.match.params.id],
    allUsers: firestore.data.meta?.users,
    uid: firebase.auth.uid,
    eventsMap: firestore?.composite?.visibleEvents,
  }))
)(OrganizationDetails);
