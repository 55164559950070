import React, { useState } from 'react';
import TabHeader from '../TabHeader';
import SearchBar from '../SearchBar';
import { IconButton } from '@material-ui/core';
import { ReactComponent as FriendsListIcon } from './feather_friendslist.svg';
import { ReactComponent as FriendsListBadgeIcon } from './feather_friendslist-newrequest.svg';
import { filterTypes } from '../../shared/constants';
import SmallCardCarousel from '../shared/SmallCardCarousel';
import {
  filterEvent,
  pseudoRandomGenerator,
  getDayOfYear,
  generateUserSlices,
  sortEventsByStartTime,
  getLocationObj,
} from '../utils';
import { firestoreConnect } from 'react-redux-firebase';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import UserList from './UserList';
import UserAvatar from '../shared/UserAvatar';

export const getRandomFriendSlices = (profile = {}) => {
  const friends = profile.friends || [];
  const slices = [];
  const random = pseudoRandomGenerator(getDayOfYear(new Date()));
  while (slices.length < 4 && friends.length) {
    const num = random();
    const idx = Math.floor(num * 10000) % friends.length;
    const slice = friends.splice(idx, 1).pop();
    slices.push(slice);
  }
  return slices;
};

const getRegexFilter = (regex) => (entry) => {
  const [, { firstName, lastName }] = entry;
  return `${firstName} ${lastName}`.match(regex);
};

const SocialTab = (props) => {
  const { profile, eventsMap, friendDocs, allUsers = {} } = props;
  const allEvents = [];
  if (eventsMap)
    Object.keys(eventsMap).forEach((eventId) =>
      allEvents.push(eventsMap[eventId])
    );
  const friends = profile?.friends ?? [];
  const [searchText, setSearchText] = useState('');
  const history = useHistory();

  const slices = generateUserSlices(friends, allUsers);
  const carouselFilters = slices?.map((slice) => {
    return {
      title: `${slice.firstName} & You Might Enjoy`,
      filter: {
        type: filterTypes.SOCIAL,
        value: {
          uid: slice.uid,
          userEvents: profile.events,
          friendEvents: friendDocs && friendDocs[slice.uid]?.events,
          friendFirstName: slice.firstName,
        },
      },
      slice,
    };
  });

  const carouselProps = carouselFilters?.map((carouselData) => {
    const { title, filter, slice } = carouselData;
    let events;
    if (filter) events = allEvents.filter(filterEvent(filter));
    else events = allEvents;
    sortEventsByStartTime(events);
    events = events.map((event) => event.eventId);
    return {
      title,
      items: events,
      type: 'events',
      headerAvatar: <UserAvatar slice={slice} size="small" />,
      slice,
      linkUrl: `/user/${filter?.value?.uid}`,
    };
  });

  const EventCarousels = () => {
    if (profile.isLoaded && carouselProps.length === 0) {
      return (
        <div>
          <p style={{ margin: '24px 24px', textAlign: 'left' }}>
            Use the search bar above to search for your friends and send friend
            requests. You'll see events recommended for you here.
          </p>
        </div>
      );
    } else
      return (
        carouselProps?.map((props) => (
          <SmallCardCarousel key={props.title} {...props} />
        )) ?? null
      );
  };
  const FriendsListButton = (
    <IconButton
      aria-label="go to friends list"
      onClick={() =>
        history.push(getLocationObj(`${history.location.pathname}/list`))
      }
    >
      {profile?.friendRequests?.length > 0 ? (
        <FriendsListBadgeIcon />
      ) : (
        <FriendsListIcon />
      )}
    </IconButton>
  );

  const regex = new RegExp(searchText, 'ig');
  const matchesRegex = getRegexFilter(regex);
  const filterEntries = (entry) =>
    matchesRegex(entry) && profile?.uid !== entry[0];

  const filteredUsers = Object.entries(allUsers ?? {})
    .filter(filterEntries)
    .map(([uid]) => uid);

  return (
    <div className="socialTab">
      <TabHeader text="Go With Friends" actionButton={FriendsListButton} />
      <SearchBar
        setSearchText={setSearchText}
        placeholder="Search for new friends..."
      />
      {searchText ? (
        <UserList uids={filteredUsers}>
          <p>No users found.</p>
        </UserList>
      ) : (
        <EventCarousels />
      )}
    </div>
  );
};

const mapPropsToFirestore = ({ uid, profile }) => {
  let friends = profile?.friends ?? [];
  let friendUids = friends?.length > 0 ? friends : [];
  const queryArray = [
    { collection: 'users', doc: uid },
    { collection: 'meta', doc: 'users' },
  ];
  friendUids.forEach((friendUid) => {
    queryArray.push({ collection: 'users', doc: friendUid });
  });
  return queryArray;
};

export default compose(
  connect(({ firebase, firestore }) => ({
    eventsMap: firestore?.composite?.visibleEvents,
    profile: firebase.profile,
    uid: firebase.auth?.uid,
    allUsers: firestore.data?.meta?.users,
    friendDocs: firestore.data?.users,
  })),
  firestoreConnect(mapPropsToFirestore)
)(SocialTab);
