import { Card, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';

export const StyledEventType = styled.div`
  width: fit-content;
  font-size: 18pt;
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 10px;
  border-radius: 2px;
  background-color: ${(props) => props.backgroundColor};
  strong {
    margin-left: 5px;
    font-size: 16pt;
  }
`;
export const StyledGridItem = styled(Grid)({
  minWidth: '83vw',
});

export const StyledLargeEventCard = styled(Card)({
  height: '390px',
  touchAction: 'none',
});

export const StyledHeader = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  color: white;
  background-image: url(${(props) => props.url});
  height: 240px;
  width: 100%;
  position: relative;
`;
export const StyledVignette = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 30%),
    linear-gradient(to top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 60%);
`;

export const StyledContent = styled.div`
  position: relative;
  text-align: left;
`;
export const StyledEventTitle = styled.div`
  position: absolute;
  bottom: 0px;

  h1 {
    font-size: 24px;
    line-height: 32px;
    text-align: left;
    margin: 0px 16px 16px 16px;
  }
`;
export const cardStyle = {
  display: 'block',
  width: '100%',
  height: '100%',
  position: 'relative',
};

export const useStyles = makeStyles((theme) => ({
  ribbon: {
    position: 'absolute',
    left: theme.spacing(2),
    top: '0px',
    borderRadius: '0px 0px 2px 2px',
    width: ({ item }) => (item?.location?.type !== 'place' ? '75px' : '93px'),
    backgroundColor: ({ item }) =>
      item?.location?.type !== 'place'
        ? theme.palette.secondary.main
        : '#F2C94C',
    height: '40px',
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '-0.25px',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-around',
    padding: '8px',
    boxSizing: 'border-box',
    '& svg': {
      height: '16px',
      width: '16px',
      position: 'absolute',
      bottom: theme.spacing(1),
      left: theme.spacing(1),
      stroke: 'white',
    },
    '& span': {
      position: 'absolute',
      bottom: theme.spacing(1),
      right: theme.spacing(1),
    },
  },
  buttion: {
    maxWidth: '24px',
  },
  cardContent: {
    height: `calc(50% - 16px)`,
    paddingBottom: '0px',
  },
  timeAndLocation: {
    paddingTop: theme.spacing(0.5),
    '& p': {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: '500',
      margin: '0px',
      '& span': {
        color: '#c7003a',
      },
    },
  },
  respondentsWrapper: {
    marginTop: theme.spacing(1),
  },
  tags: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flex-start',
    marginTop: `calc(${theme.spacing(2)}px - 4px)`,
    marginLeft: '-4px',
  },
  textOverflow: {
    position: 'absolute',
    bottom: '0px',
    height: '80px',
    width: '100%',
    backgroundImage: 'linear-gradient(to bottom, #ffffff00, #ffffff)',
  },
  description: {
    overflow: 'hidden',
    maxHeight: '116px',
    '& p': {
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '-0.25px',
      color: '#333333',
    },
  },
}));
