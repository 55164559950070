export const writeFriendRequest = async (firestore, uid, friendUid) => {
  await Promise.all([
    firestore.update(
      { collection: 'users', doc: uid },
      {
        sentRequests: firestore.FieldValue.arrayUnion(friendUid),
      }
    ),
    firestore.update(
      { collection: 'users', doc: friendUid },
      {
        friendRequests: firestore.FieldValue.arrayUnion(uid),
      }
    ),
  ]);
};

export const unwriteFriendRequest = async (firestore, uid, friendUid) => {
  await Promise.all([
    firestore.update(
      { collection: 'users', doc: uid },
      {
        sentRequests: firestore.FieldValue.arrayRemove(friendUid),
      }
    ),
    firestore.update(
      { collection: 'users', doc: friendUid },
      {
        friendRequests: firestore.FieldValue.arrayRemove(uid),
      }
    ),
  ]);
};

export const writeUnfriend = async (firestore, uid, friendUid) => {
  await Promise.all([
    firestore.update(
      { collection: 'users', doc: uid },
      {
        friends: firestore.FieldValue.arrayRemove(friendUid),
      }
    ),
    firestore.update(
      { collection: 'users', doc: friendUid },
      {
        friends: firestore.FieldValue.arrayRemove(uid),
      }
    ),
  ]);
};

export const writeAcceptFriendRequest = async (firestore, uid, friendUid) => {
  await Promise.all([
    firestore.update(
      { collection: 'users', doc: uid },
      {
        friendRequests: firestore.FieldValue.arrayRemove(friendUid),
        friends: firestore.FieldValue.arrayUnion(friendUid),
      }
    ),
    firestore.update(
      { collection: 'users', doc: friendUid },
      {
        sentRequests: firestore.FieldValue.arrayRemove(uid),
        friends: firestore.FieldValue.arrayUnion(uid),
      }
    ),
  ]);
};

export const writeDenyFriendRequest = (firestore, uid, friendUid) => {
  unwriteFriendRequest(firestore, friendUid, uid);
};
