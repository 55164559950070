import React from 'react';
import { useFirebase } from 'react-redux-firebase';
import { ReactComponent as LogOutIcon } from './feather_log-out.svg';
import { IconButton } from '@material-ui/core';
import { makeSettingsButtonStyles } from './styles';

const useStyles = makeSettingsButtonStyles;

const signOut = (firebase) => {
  firebase.auth().signOut().then(console.log('User signed out.'));
};

const LogOutButton = ({ className, ...props }) => {
  const firebase = useFirebase();
  const classes = useStyles();
  return (
    <div className={`${classes.root} ${className}`}>
      <div>
        <h3>Sign Out</h3>
        <p>Sign out of your account.</p>
      </div>
      <IconButton onClick={() => signOut(firebase)}>
        <LogOutIcon />
      </IconButton>
    </div>
  );
};

export default LogOutButton;
