import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TabHeader from '../TabHeader';
import SearchBar from '../SearchBar';
import EventsList from '../ProfileTab/EventsList';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect } from 'react-redux-firebase';
import { filterEvent, sortEventsByStartTime } from '../utils';
import FilterSelectors from './FilterSelectors';

const qs = require('query-string');

const getFilteredEvents = (events = [], queryParams, profile) => {
  for (const [type, value] of Object.entries(queryParams))
    events = events.filter(filterEvent({ type, value }));
  return events;
};

const FilterEvents = (props) => {
  const { eventsMap, allTags, location, profile } = props;
  const allEvents = [];
  if (eventsMap)
    Object.keys(eventsMap).forEach((eventId) =>
      allEvents.push(eventsMap[eventId])
    );
  const history = useHistory();
  const qsParseParams = { parseNumbers: true, arrayFormat: 'bracket' };
  const qsStringifyParams = { arrayFormat: 'bracket' };

  const [searchText, setSearchText] = useState('');
  const regex = new RegExp(searchText, 'ig');

  const queryParams = qs.parse(location.search, qsParseParams);
  let events = getFilteredEvents(allEvents, queryParams, profile);
  events = events.filter((event) => event.title.match(regex));
  sortEventsByStartTime(events);

  const setFilter = (filter) => {
    const type = filter.type;
    const value = filter.value || undefined;
    const newQueryParams = { ...queryParams, [type]: value };
    const newSearchString = qs.stringify(newQueryParams, qsStringifyParams);
    history.push({
      ...location,
      search: newSearchString,
      state: { fromTwine: true },
    });
  };

  return (
    <div>
      <TabHeader text="Search" backButton />
      <SearchBar {...{ setSearchText }} />
      <FilterSelectors {...{ setFilter, queryParams, allTags }} />
      <EventsList {...{ events }} />
    </div>
  );
};

const mapStateToProps = ({ firestore, firebase }) => ({
  eventsMap: firestore?.composite?.visibleEvents || [],
  allTags: firestore.data.meta?.attributes?.all,
  profile: firebase.profile,
});

export default compose(
  firestoreConnect(() => [{ collection: 'meta', doc: 'attributes' }]),
  connect(mapStateToProps)
)(FilterEvents);
