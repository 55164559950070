import React from 'react';
import { Tabs, Tab, IconButton } from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import ProfileHeader from '../shared/ProfileHeader';
import TabHeader from '../TabHeader';
import EventsList from './EventsList';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { selectActiveProfileTab } from '../../actions/uiActions';
import PrefsViewer from './PrefsViewer';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LoadingView from '../LoadingView';
import { getLocationObj } from '../utils';

const StyledTabsWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  background: white;
`;

const useStyles = makeStyles((theme) => ({
  tabs: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#BDBDBD',
    minHeight: '28px',
    '& .Mui-selected': {
      color: 'black',
    },
    lineHeight: '21px',
    '& .MuiTab-root': {
      textTransform: 'capitalize',
      whiteSpace: 'pre-line',
      minHeight: '34px',
    },
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      backgroundColor: theme.palette.primary.main,
      maxWidth: 40,
      width: '100%',
    },
  },
}));

const ProfileTab = (props) => {
  const { profile, activeProfileTab, isLoaded, eventsMap } = props;
  const { selectActiveProfileTab } = props;
  const allEvents = [];
  if (eventsMap)
    Object.keys(eventsMap).forEach((eventId) =>
      allEvents.push(eventsMap[eventId])
    );
  const history = useHistory();
  const classes = useStyles();

  const goingEvents = [];
  const interestedEvents = [];
  const goingEventsIds = profile?.events?.going || [];
  const interestedEventsIds = profile?.events?.interested || [];

  for (const event of allEvents) {
    if (goingEventsIds.includes(event.eventId)) goingEvents.push(event);
    else if (interestedEventsIds.includes(event.eventId))
      interestedEvents.push(event);
  }

  const handleChange = (event, newValue) => {
    selectActiveProfileTab(newValue);
  };

  const viewSwitch = {
    0: <EventsList events={goingEvents} />,
    1: <EventsList events={interestedEvents} />,
    2: <PrefsViewer />,
  }[activeProfileTab];

  const SettingsButton = (
    <IconButton
      aria-label="go to settings"
      onClick={() =>
        history.push(getLocationObj(`${history.location.pathname}/settings`))
      }
    >
      <SettingsIcon />
    </IconButton>
  );

  if (!isLoaded || !profile.isLoaded)
    return (
      <div>
        <LoadingView />
      </div>
    );

  return (
    <div>
      <TabHeader text="Profile" actionButton={SettingsButton} />
      <ProfileHeader uid={profile.uid} isOwnProfile />
      <StyledTabsWrapper>
        <Tabs
          variant="fullWidth"
          value={activeProfileTab}
          onChange={handleChange}
          classes={{ root: classes.tabs, indicator: classes.indicator }}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label="Going" />
          <Tab label="Interested" />
          <Tab label="My Interests" />
        </Tabs>
      </StyledTabsWrapper>
      {viewSwitch}
    </div>
  );
};

export default connect(
  ({ firebase, firestore, ui }) => {
    return {
      profile: firebase.profile,
      eventsMap: firestore?.composite?.visibleEvents,
      activeProfileTab: ui.activeProfileTab,
      isLoaded: firebase.auth.isLoaded,
    };
  },
  { selectActiveProfileTab }
)(ProfileTab);
