import React from 'react';
import OrgsPickerContainer from '../Onboarding/OrgsPickerContainer';
import TabHeader from '../TabHeader';

const EditStudentOrgs = () => {
  return (
    <div>
      <TabHeader text="Student Orgs" backButton />
      <OrgsPickerContainer isDepartment={false} rerouteOnDone="/profile" />
    </div>
  );
};

export default EditStudentOrgs;
