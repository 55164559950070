import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { getLocationObj } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
  },
  header: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h1': {
      display: 'inline-block',
      position: 'relative',
      fontSize: '24px',
      lineHeight: '31px',
      color: theme.palette.primary.main,
      margin: '0px',
    },
    '& .MuiIconButton-root': {
      padding: '0px',
    },
  },
  back: {
    textAlign: 'left',
    marginLeft: '-4px',
    '& .MuiIconButton-root': {
      padding: '7px 0px 9px 0px',
    },
  },
}));

// TODO(CP): Unexpected back button behavior if you have arrived through an external page
const TabHeader = (props) => {
  const { backButton, actionButton, text } = props;
  const classes = useStyles();
  const history = useHistory();
  let handler;
  if (history.location.state?.fromTwine) {
    handler = () => history.goBack();
  } else {
    handler = () => history.push(getLocationObj('/'));
  }
  return (
    <div className={classes.root}>
      {backButton ? (
        <div className={classes.back}>
          <IconButton onClick={handler}>
            <ArrowBackIcon />
          </IconButton>
        </div>
      ) : null}
      <div className={classes.header}>
        <h1>{text}</h1>
        {actionButton}
      </div>
    </div>
  );
};

TabHeader.propTypes = {
  backButton: PropTypes.bool,
  backLink: PropTypes.string,
  actionButton: PropTypes.element,
  text: PropTypes.string.isRequired,
};

export default TabHeader;
