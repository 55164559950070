import React from 'react';
import { connect } from 'react-redux';
import { setShowInstallPrompt } from '../../actions/uiActions';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IosShareIcon from '../shared/images/ios-share.png';
import TwineLogo from '../shared/images/twine-logo.png';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAddToHomescreenPrompt from './useAddToHomescreenPrompt';
import { isIos } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: '100%',
    '& .MuiButton-root': {
      display: 'flex',
      margin: 'auto',
      marginTop: '30px',
    },
  },
  info: {
    '& img': {
      display: 'inline',
      height: '18px',
    },
    '& ol': {
      fontSize: '18px',
      margin: '0',
    },
    '& span': {
      fontWeight: 'bold',
      whiteSpace: 'pre',
    },
  },
  bottomDiv: {
    position: 'absolute',
    bottom: '0px',
    width: '100%',
    textAlign: 'center',
    '& a, .MuiButton-root': {
      display: 'inline-block',
      marginBottom: '20px',
      color: theme.palette.secondary.main,
      fontSize: '16px',
      textTransform: 'none',
      fontWeight: 'unset',
    },
  },
  innerCard: {
    width: '100%',
    background: '#ffdcd2',
    boxShadow: '0px -4px 14px #00000045',
  },
  grid: {
    display: 'grid',
    height: '40%',
    grid: '2fr 1fr / 20% auto',
    gridTemplateAreas: '"logo title" "logo url"',
    justifyItems: 'center',
    alignItems: 'center',
    padding: '12px 2px',
    '& img': {
      gridArea: 'logo',
      height: '50px',
    },
    '& h4': {
      gridArea: 'title',
      display: 'inline-block',
      margin: '0',
      justifySelf: 'left',
    },
    '& p': {
      gridArea: 'url',
      margin: '0',
      justifySelf: 'left',
    },
  },
}));

const InstallPrompt = (props) => {
  const classes = useStyles();
  const { onContinue } = props;
  const [prompt, promptToInstall] = useAddToHomescreenPrompt();

  const handlePrompt = () => {
    promptToInstall().catch((e) => console.error(e));
  };

  const Header = () => (
    <React.Fragment>
      <h2>Wait a minute!</h2>
      <p>
        Twine works best when it's installed on your homescreen. Here's how to
        do it.
      </p>
    </React.Fragment>
  );

  const BottomDiv = () => (
    <div className={classes.bottomDiv}>
      {onContinue ? (
        <Button onClick={onContinue} component="a">
          Continue anyway
        </Button>
      ) : (
        <Link to="/login">Continue anyway</Link>
      )}
      <div className={classes.innerCard}>
        <div className={classes.grid}>
          <img src={TwineLogo} alt="twine logo" />
          <h4>Twine</h4>
          <p>gettwine.app</p>
        </div>
      </div>
    </div>
  );

  let InstallInfo;

  if (isIos()) {
    InstallInfo = () => (
      <div className={classes.info}>
        <ol>
          <li>Open Twine in Safari</li>
          <li>
            Tap the share button <img src={IosShareIcon} alt="share" /> located
            on the bottom toolbar
          </li>
          <li>
            Choose <span>"Add to Home Screen"</span> near the bottom of the list
          </li>
        </ol>
      </div>
    );
  } else {
    if (prompt) {
      InstallInfo = () => (
        <Button
          variant="contained"
          color="secondary"
          style={{ margin: 'auto' }}
          onClick={handlePrompt}
        >
          Install
        </Button>
      );
    } else {
      InstallInfo = () => (
        <div className={classes.info}>
          <ol>
            <li>Tap the menu button located on the toolbar</li>
            <li>
              Choose <span>"Add to Home Screen"</span>
            </li>
          </ol>
        </div>
      );
    }
  }

  return (
    <div className={classes.root}>
      <Header />
      <InstallInfo />
      <BottomDiv />
    </div>
  );
};

InstallPrompt.propTypes = {
  onContinue: PropTypes.func,
};

export default connect(undefined, { setShowInstallPrompt })(InstallPrompt);
