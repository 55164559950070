import React, { useEffect } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { selectActiveOrgTab } from '../../actions/uiActions';
import OrgsList from './OrgsList';
import { connect } from 'react-redux';
import OrganizationCard from '../shared/OrganizationCard';

const useStyles = makeStyles((theme) => ({
  featuredOrg: {
    '& h3': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(1),
      fontSize: '20px',
      color: theme.palette.primary.main,
    },
    '& p': {
      fontSize: '12px',
      letterSpacing: '-0.25px',
      lineHeight: '16px',
      margin: '16px 24px',
    },
  },

  tabsWrapper: {
    position: 'sticky',
    top: '0',
    zIndex: '1',
    background: 'white',
    padding: '10px 0px',
    marginTop: '-12px',
  },
  tabs: {
    fontSize: '16px',
    fontWeight: '500',
    color: '#BDBDBD',
    minHeight: '28px',
    '& .Mui-selected': {
      color: 'black',
    },
    lineHeight: '21px',
    '& .MuiTab-root': {
      textTransform: 'capitalize',
      whiteSpace: 'pre-line',
      minHeight: '34px',
    },
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      backgroundColor: theme.palette.primary.main,
      maxWidth: 40,
      width: '100%',
    },
  },
  link: {
    color: theme.palette.secondary.main,
    display: 'block',
    marginTop: theme.spacing(2),
  },
}));

// TODO: Adjust to conform to firestore collection specs
const OrgTabs = (props) => {
  const {
    dep: departments,
    org: studentOrgs,
    loggedIn,
    featuredOrg,
    featuredOrgDescription,
    orgsToNumEvents,
  } = props;
  const { activeOrgTab, selectActiveOrgTab } = props;
  const classes = useStyles();

  useEffect(() => {
    if (!loggedIn) selectActiveOrgTab(1);
  }, [loggedIn, selectActiveOrgTab]);

  const handleChange = (event, newValue) => {
    selectActiveOrgTab(newValue);
  };

  const orgList = {
    0: studentOrgs,
    1: departments,
  }[activeOrgTab];

  return (
    <div>
      {!featuredOrg ? null : (
        <div className={classes.featuredOrg}>
          <h3>Featured Org</h3>
          <OrganizationCard large={true} orgId={featuredOrg} />
          <p>{featuredOrgDescription}</p>
        </div>
      )}
      <div className={classes.tabsWrapper}>
        <Tabs
          variant="fullWidth"
          value={activeOrgTab}
          onChange={handleChange}
          classes={{ root: classes.tabs, indicator: classes.indicator }}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label="Student Orgs" disabled={!loggedIn} />
          <Tab label="Academic Orgs" />
        </Tabs>
      </div>
      <OrgsList orgsToNumEvents={orgsToNumEvents} orgs={orgList} />
      {activeOrgTab === 0 ? (
        <a
          className={classes.link}
          href="https://forms.gle/g8cSEwniXpMQ25HS8"
          target="_blank"
          rel="noopener noreferrer"
        >
          Can't find your org?
        </a>
      ) : null}
    </div>
  );
};

export default connect(({ ui }) => ({ activeOrgTab: ui.activeOrgTab }), {
  selectActiveOrgTab,
})(OrgTabs);
