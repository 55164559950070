import React from 'react';
//import ShareIcon from '@material-ui/icons/Share';
import { Button, IconButton } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { ReactComponent as ShareIcon } from '../shared/images/share.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  shareEventCard: {
    position: 'absolute',
    top: '246px',
    left: '66vw',
    right: '24px',
    zIndex: '1',
  },
  shareOrgDetails: {
    color: 'white',
    padding: '0',
  },
}));

const CopyButton = (props) => {
  const { disabled, copyString } = props;
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(copyString);
      document.getElementById('statement').innerHTML = 'Link Copied!';
      console.log('Copied to clipboard: ', copyString);
    } catch (err) {
      console.error('Error writing event link to clipboard: ', err.message);
    }
  };

  return (
    <Paper style={{ marginRight: '5px', width: '110px' }}>
      {' '}
      <Button
        variant="outlined"
        style={{ padding: '5px', width: '100%' }}
        onClick={copyToClipboard}
        disabled={disabled}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <LinkIcon />
          <div id="statement">Copy Link</div>
        </div>
      </Button>
    </Paper>
  );
};

const ShareIconButton = (props) => {
  const { title, url, type } = props;
  const onClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({ title, url });
        console.log('Shared!');
      } catch (err) {
        console.error('Share failed:', err.message);
      }
    } else {
      console.error('Web Share not supported!');
    }
  };

  const clickHandler = (e) => {
    e.stopPropagation();
    onClick();
  };

  const classes = useStyles();

  if (type === 'eventDetails') {
    return (
      <Paper style={{ marginRight: '5px', width: '110px' }}>
        {' '}
        <Button
          variant="outlined"
          style={{ padding: '6px 3px 5px 3px', width: '100%' }}
          onClick={onClick}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ShareIcon height="20px" />
            <div
              id="statement"
              style={{ fontSize: '14px', padding: '3px 0px 0px 0px' }}
            >
              Share Event
            </div>
          </div>
        </Button>
      </Paper>
    );
  } else if (type === 'largeEventCard') {
    return (
      <IconButton onClick={clickHandler} className={classes.shareEventCard}>
        <ShareIcon height="22px" />
      </IconButton>
    );
  } else {
    return (
      <Button onClick={clickHandler} className={classes.shareOrgDetails}>
        <ShareIcon height="28px" width="28px" />
      </Button>
    );
  }
};

ShareIconButton.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export { ShareIconButton, CopyButton };
