import React, { useState } from 'react';
import TabHeader from '../TabHeader';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import BigPrimaryButton from '../shared/BigPrimaryButton';
import { useHistory } from 'react-router-dom';
import EditProfilePicture from '../EditProfilePicture';
import EditProfileForm from './EditProfileForm';
import { getLocationObj } from '../utils';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: `0px ${theme.spacing(3)}px`,
    '& h3': {
      margin: '0px',
      textAlign: 'left',
    },
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    width: '100%',
    bottom: '48px',
    left: '0px',
    textAlign: 'center',
    pointerEvents: 'none',
    '& button': {
      pointerEvents: 'auto',
    },
  },
}));

const ErrorMessage = () => (
  <div>
    <p style={{ fontSize: '16px', color: 'red' }}>
      Sorry, you need to fill out all of these fields to make your profile!
    </p>
  </div>
);

const CompleteProfile = (props) => {
  const { profile } = props;
  const firebase = useFirebase();
  const firestore = useFirestore();

  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    classYear: '',
  });
  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);

  const [profileIsLoaded, setProfileIsLoaded] = useState(false);
  if (profile.isLoaded && !profileIsLoaded) {
    setFormState({
      ...formState,
      firstName: profile.firstName || '',
      lastName: profile.lastName || '',
      classYear: profile.classYear || '',
    });
    setProfileIsLoaded(true);
  }

  const handleChange = (e, fieldName) => {
    let value = e.target.value;
    setFormState({
      ...formState,
      [fieldName]: value,
    });
  };

  const history = useHistory();
  const handleSubmit = () => {
    const { firstName, lastName, classYear } = formState;
    if (!firstName || !lastName || !classYear) {
      setDisplayErrorMessage(true);
      return;
    }
    firebase.auth().currentUser.updateProfile({ displayName: firstName });
    firestore
      .collection('users')
      .doc(firebase.auth().currentUser.uid)
      .update({
        uid: firebase.auth().currentUser.uid,
        firstName,
        lastName,
        classYear,
      })
      .then(() => {
        history.push(getLocationObj('/onboarding/2'));
      })
      .catch((err) => console.error(`ERROR: Account Update\n ${err.message}`));
  };

  const classes = useStyles();
  return (
    <div>
      <TabHeader text="Complete Your Profile" backButton />
      <div className={classes.content}>
        <h3>Profile Picture</h3>
        <EditProfilePicture />
        <EditProfileForm handleChange={handleChange} formState={formState} />
        {displayErrorMessage ? <ErrorMessage /> : null}
        <div className={classes.buttonWrapper}>
          <BigPrimaryButton
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Continue
          </BigPrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default connect(({ firebase: { profile } }) => ({ profile }))(
  CompleteProfile
);
