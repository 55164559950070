import React from 'react';
import { Avatar } from '@material-ui/core';
import PropTypes from 'prop-types';
import { generateAvatarColor } from '../utils';
import { makeStyles } from '@material-ui/core/styles';

// NOTE: This component is not to be used in @material-ui/lab/AvatarGroup due to the lab implementation,
// with which it is incompatible

const useStyles = makeStyles((theme) => ({
  size: (props) => {
    const { size } = props;
    const map = {
      small: ['24px', '12px'],
      medium: ['32px', '14px'],
      large: ['64px', '26px'],
    };
    let prop;
    if (!size) prop = map.medium;
    else prop = map[size];
    return { width: prop[0], height: prop[0], fontSize: prop[1] };
  },
}));

const UserAvatar = ({ className, ...props }) => {
  const { slice } = props;
  const { firstName, lastName, uid = '', photoUrl } = slice;
  let initials = '';
  if (firstName) initials += firstName.charAt(0);
  if (lastName) initials += lastName.charAt(0);

  const classes = useStyles(props);
  return (
    <Avatar
      src={photoUrl ? photoUrl : null}
      alt={`${firstName} avatar`}
      className={`${classes.size} ${className}`}
      style={{ backgroundColor: generateAvatarColor(uid || firstName) }}
    >
      {initials}
    </Avatar>
  );
};

UserAvatar.propTypes = {
  slice: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    uid: PropTypes.string,
    classYear: PropTypes.number,
    photoUrl: PropTypes.string,
  }).isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

export default UserAvatar;
