import React from 'react';
import PlaceholderImage from '../../shared/placeholder.jpg';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, CardActionArea } from '@material-ui/core';
import TagChip from '../shared/TagChip';
import PeekRespondents from '../EventDetails/PeekRespondents';
import EngagementButtonContainer from '../shared/EngagementButtonContainer.js';
import { ShareIconButton } from '../shared/ShareCopyButton.js';
import RibbonArray from '../shared/RibbonArray.js';
import { getLocationObj } from '../utils';
import { useSelector } from 'react-redux';
import _ from 'underscore';

import {
  StyledContent,
  StyledGridItem,
  StyledLargeEventCard,
  StyledVignette,
  cardStyle,
  StyledHeader,
  StyledEventTitle,
  useStyles,
} from './styles';
import ClampLines from 'react-clamp-lines';
import EventDateTime from '../shared/EventDateTime';

const LargeEventCard = React.memo((props) => {
  const { itemId, idx, downloadUrl, handlers } = props;
  const history = useHistory();
  const classes = useStyles(props);
  const item = useSelector(
    (state) => state.firestore?.composite?.visibleEvents[itemId],
    _.isEqual
  );
  const { eventId: id, title, location } = item;
  const type = location?.type ?? 'web';
  let locationName;
  if (location?.name) locationName = location.name;
  else if (type !== 'place') locationName = 'Online';
  else locationName = '';

  const eventUrl = `${window.location.origin}/events/${id}`;

  let tags = item?.filters?.attributes ?? [];
  tags = tags.length < 3 ? tags : tags.slice(0, 3);
  const { click, touchStart, touchMove, touchEnd, touchCancel } = handlers;
  const src = downloadUrl ? downloadUrl : PlaceholderImage;

  let ribbonArray = [location?.type];
  if (item?.visibility === 'organization') ribbonArray.push(item?.visibility);
  return (
    <StyledGridItem item xs={8} key={id}>
      <StyledLargeEventCard
        key={id}
        onClick={() => click(idx)}
        onTouchStart={(e) => touchStart(e)}
        onTouchMove={(e) => touchMove(idx, e)}
        onTouchEnd={(e) => touchEnd(e)}
        onTouchCancel={(e) => touchCancel(e)}
      >
        <Card style={cardStyle}>
          <EngagementButtonContainer
            className={classes.button}
            event={item}
            cardType="LargeEventCard"
            locationType={location.type}
          />
          <ShareIconButton title={title} url={eventUrl} type="largeEventCard" />
          <CardActionArea
            style={{ height: '100%' }}
            onClick={() =>
              setTimeout(
                () => history.push(getLocationObj(`/events/${id}`)),
                250
              )
            }
          >
            <StyledHeader url={src}>
              <StyledVignette>
                <RibbonArray
                  ribbonArray={ribbonArray}
                  eventCardType={'large'}
                />
                <StyledEventTitle>
                  <ClampLines
                    text={title}
                    lines={4}
                    innerElement="h1"
                    buttons={false}
                  />
                </StyledEventTitle>
              </StyledVignette>
            </StyledHeader>
            <CardContent className={classes.cardContent}>
              <StyledContent>
                <div className={classes.timeAndLocation}>
                  <EventDateTime
                    startTime={item?.time.start.toDate().toString()}
                  />
                  <ClampLines
                    text={locationName}
                    lines={1}
                    innerElement="p"
                    buttons={false}
                  />
                </div>
                <div className={classes.respondentsWrapper}>
                  <PeekRespondents eventId={id} disableOnClick />
                </div>
                <div className={classes.tags}>
                  {tags.map((tag) => (
                    <TagChip size="small" label={tag} key={tag}></TagChip>
                  ))}
                </div>
              </StyledContent>
            </CardContent>
          </CardActionArea>
        </Card>
      </StyledLargeEventCard>
    </StyledGridItem>
  );
});

export default LargeEventCard;
