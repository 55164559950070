import React, { useState } from 'react';
import TutorialCarousel from './TutorialCarousel';
import BigPrimaryButton from '../shared/BigPrimaryButton';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { getLocationObj } from '../utils';

const useStyles = makeStyles((theme) => ({
  tutorialCarousel: {
    boxSizing: 'border-box',
    width: '100%',
    margin: 'auto',
  },
  header: {
    fontSize: '20px',
    lineHeight: '31px',
    color: theme.palette.primary.main,
    margin: '20px 0px 0px 0px',
  },
  button: {
    position: 'absolute',
    bottom: '20px',
    margin: 'auto',
    left: '0px',
    right: '0px',
  },
}));

const TutorialPage = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const firestore = useFirestore();
  const profile = useSelector((state) => state.firebase.profile);
  const [tutorialComplete, setTutorialComplete] = useState(false);
  const { notOnboarding } = props;

  const finalizeProfile = async () => {
    try {
      await firestore.update(
        { collection: 'users', doc: profile.uid },
        {
          onboarding: false,
          hasSeenTutorial: true,
        }
      );
      console.log('Profile finalized!');
    } catch (error) {
      console.log('Error finalizing profile', error.toString());
    }
  };

  let handleClick;
  if (notOnboarding) {
    handleClick = () => history.goBack();
  } else {
    handleClick = () => {
      finalizeProfile().then(() => history.push(getLocationObj('/')));
    };
  }

  return (
    <div>
      <TutorialCarousel
        className={classes.tutorialCarousel}
        setTutorialComplete={setTutorialComplete}
      />
      <BigPrimaryButton
        variant="contained"
        color="primary"
        onClick={handleClick}
        disabled={!tutorialComplete && !notOnboarding}
        className={classes.button}
      >
        {notOnboarding ? 'Done' : 'Continue'}
      </BigPrimaryButton>
    </div>
  );
};

TutorialPage.propTypes = {
  notOnboarding: PropTypes.bool,
};

export default TutorialPage;
