import {
  SELECT_ACTIVE_PROFILE_TAB,
  SELECT_ACTIVE_ORG_TAB,
  SET_SHOW_INSTALL_PROMPT,
  SET_SHOW_UNFRIEND_CONFIRM,
  LOAD_ORGS_TO_NUM_EVENTS,
} from './types';

export const selectActiveProfileTab = (index) => {
  return {
    type: SELECT_ACTIVE_PROFILE_TAB,
    payload: index,
  };
};

/**
 * @param {boolean} show Whether the install prompt is showing
 */
export const setShowInstallPrompt = (show) => ({
  type: SET_SHOW_INSTALL_PROMPT,
  payload: show,
});

export const selectActiveOrgTab = (index) => ({
  type: SELECT_ACTIVE_ORG_TAB,
  payload: index,
});

export const setShowUnfriendConfirm = (show) => ({
  type: SET_SHOW_UNFRIEND_CONFIRM,
  payload: show,
});

export const loadOrgsToNumEvents = (map) => ({
  type: LOAD_ORGS_TO_NUM_EVENTS,
  payload: map,
});
