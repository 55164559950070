/* Container */
import React, { useEffect } from 'react';
import './styles/App.css';
import TabBar from './components/TabBar';
import SocialTab from './components/SocialTab';
import EventsTab from './components/EventsTab';
import EventDetails from './components/EventDetails';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import OnboardingSwitch from './routes/OnboardingSwitch';
import Splash from './components/Splash';
import Welcome from './components/Welcome';
import AuthRoute from './routes/AuthRoute';
import OrganizationsTab from './components/OrganizationsTab';
import OrganizationDetails from './components/OrganizationDetails';
import ProfileTab from './components/ProfileTab';
import Settings from './components/Settings';
import FilterEvents from './components/EventsTab/FilterEvents';
import ProfilePromo from './components/ProfileTab/ProfilePromo';
import SocialPromo from './components/SocialTab/SocialPromo';
import LoadingView from './components/LoadingView';
import { useCookies } from 'react-cookie';
import { Drawer } from '@material-ui/core';
import Login from './components/Welcome/Login';
import FriendsList from './components/SocialTab/FriendsList';
import OtherProfile from './components/SocialTab/OtherProfile';
import { isInStandaloneMode, isMobileBrowser } from './components/utils';
import { setShowInstallPrompt } from './actions/uiActions';
import InstallPrompt from './components/Welcome/InstallPrompt';
import { makeGetStartedButtonStyles } from './components/Welcome/styles';
import EditAcademicOrgs from './components/ProfileTab/EditAcademicOrgs';
import EditStudentOrgs from './components/ProfileTab/EditStudentOrgs';
import EditInterests from './components/ProfileTab/EditInterests';
import TutorialPage from './components/Onboarding/TutorialPage';
import TwineLytics from './twineLytics';
import { useFirestore } from 'react-redux-firebase';

const useStyles = makeGetStartedButtonStyles;

export const App = (props) => {
  const firestore = useFirestore();
  const [cookies, setCookie] = useCookies();
  const { isLoaded, isLoggedIn, profile, allowlistedEmails } = props;
  const { showInstallPrompt, setShowInstallPrompt } = props;
  const classes = useStyles();

  const handleCloseInstallPrompt = () => {
    setShowInstallPrompt(false);
    setCookie('disableHomeScreen', 'true', { path: '/', maxAge: 3600 * 4 });
  };

  const location = useLocation();
  useEffect(() => {
    const tl = new TwineLytics();
    tl.logEvent('page_view', {
      standalone: location.search.includes('isPWA=true'),
    });
  }, [location.search]);

  useEffect(() => {
    firestore.setListener({
      collection: 'events',
      where: [['visibility', '==', 'public']],
      storeAs: 'visibleEvents',
    });
    if (profile.isLoaded && isLoggedIn) {
      const orgsFollowing = profile?.orgs ?? [];
      let orgsFollowingSliced = [];
      let index = 0;
      if (orgsFollowing?.length >= 10) {
        while (
          orgsFollowingSliced.length < Math.floor(orgsFollowing.length / 10)
        ) {
          orgsFollowingSliced[index] = orgsFollowing.slice(
            index * 10,
            index * 10 + 10
          );
          index++;
        }
      } else {
        orgsFollowingSliced[index] = orgsFollowing;
        index = 1;
      }

      firestore.setListener({
        collection: 'events',
        where: [['visibility', '==', 'private']],
        storeAs: 'visibleEvents',
      });
      while (index > 0 && orgsFollowing?.length > 0) {
        firestore.setListener({
          collection: 'events',
          where: [
            ['visibility', '==', 'organization'],
            ['owner.id', 'in', orgsFollowingSliced[index - 1]],
          ],
          storeAs: 'visibleEvents',
        });
        index--;
      }
    }
  }, [profile, firestore, isLoggedIn]);

  if (!isLoaded || allowlistedEmails === undefined)
    return (
      <div className="App">
        <LoadingView />
      </div>
    );
  if (
    isMobileBrowser() &&
    !isInStandaloneMode() &&
    isLoggedIn &&
    !profile.onboarding &&
    profile.hasSeenTutorial
  )
    if (!cookies.disableHomeScreen) setShowInstallPrompt(true);

  const requestsBadge = profile?.friendRequests?.length > 0 ?? false;

  return (
    <div className="App">
      <Switch>
        <Route path="/begin" exact>
          {isLoggedIn ? <Redirect to="/" /> : null}
          <Splash />
        </Route>
        {!isLoggedIn &&
        !cookies.pastSplash &&
        !window.location.href.includes('/events/') ? (
          <Redirect to="/begin" />
        ) : null}
        <Route path="/login" exact>
          {isLoggedIn ? <Redirect to="/" /> : null}
          <Login />
        </Route>
        <Route path="/login/:email">
          {isLoggedIn ? <Redirect to="/" /> : null}
          <Login allowlist={allowlistedEmails} />
        </Route>
        <Route path="/signup" exact>
          <Redirect to="/login" />
        </Route>
        <AuthRoute path="/onboarding" redirectBase="/login">
          <OnboardingSwitch />
        </AuthRoute>
        <AuthRoute path="/profile/edit/academic-orgs" redirectBase="/profile">
          <EditAcademicOrgs />
        </AuthRoute>
        <AuthRoute path="/profile/edit/student-orgs" redirectBase="/profile">
          <EditStudentOrgs />
        </AuthRoute>
        <AuthRoute path="/profile/edit/tags" redirectBase="/profile">
          <EditInterests />
        </AuthRoute>
        {isLoggedIn && profile.onboarding ? (
          <Redirect to="/onboarding" />
        ) : null}
        {isLoggedIn && !profile.hasSeenTutorial ? (
          <Redirect to="/onboarding/5" />
        ) : null}
        <AuthRoute
          path="/profile/settings/tutorial"
          redirectBase="/profile"
          exact
        >
          <TutorialPage notOnboarding />
        </AuthRoute>
        <Route>
          <Switch>
            <Route path="/" exact>
              {isLoggedIn ? <EventsTab /> : <Welcome />}
            </Route>
            <Route path="/events" exact>
              <Redirect to="/" />
            </Route>
            <Route path="/search" component={FilterEvents} />
            <Route path="/events/:id">
              <EventDetails isLoggedIn={isLoggedIn} />
            </Route>
            <Route path="/organizations" exact>
              <OrganizationsTab />
            </Route>
            <Route path="/organizations/:id" component={OrganizationDetails} />
            <Route path="/social/list">
              <FriendsList />
            </Route>
            <Route path="/social">
              {isLoggedIn ? <SocialTab /> : <SocialPromo />}
            </Route>
            <AuthRoute
              path="/user/:uid"
              redirectBase="/user"
              component={OtherProfile}
            />
            <AuthRoute path="/profile/settings" redirectBase="/profile">
              <Settings />
            </AuthRoute>
            <Route path="/profile" exact>
              {isLoggedIn ? <ProfileTab /> : <ProfilePromo />}
            </Route>
            <Redirect to="/" />
          </Switch>
          <Drawer
            anchor="bottom"
            open={showInstallPrompt}
            onClose={handleCloseInstallPrompt}
            classes={{ paper: classes.paper }}
          >
            <InstallPrompt onContinue={handleCloseInstallPrompt} />
          </Drawer>
          <div style={{ height: '13vh' }} />
          <TabBar requestsBadge={requestsBadge} />
        </Route>
      </Switch>
    </div>
  );
};

export default compose(
  connect(
    ({ firebase, ui, firestore }) => ({
      allowlistedEmails: firestore.data.meta?.allowlist?.foreignEmails,
      isLoaded: firebase.profile.isLoaded,
      isLoggedIn: firebase.auth.uid ? true : false,
      profile: firebase.profile,
      showInstallPrompt: ui.showInstallPrompt,
    }),
    { setShowInstallPrompt }
  ),
  firestoreConnect(() => [
    { collection: 'meta', doc: 'users' },
    { collection: 'meta', doc: 'organizations' },
    { collection: 'meta', doc: 'allowlist' },
  ])
)(App);
