import React, { useState } from 'react';
import TabHeader from '../TabHeader';
import { connect } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import BigPrimaryButton from '../shared/BigPrimaryButton';
import { useHistory } from 'react-router-dom';
import TagsPicker from './TagsPicker';
import { getLocationObj } from '../utils';

const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    width: '100%',
    position: 'fixed',
    bottom: '48px',
    pointerEvents: 'none',
    '& button': {
      pointerEvents: 'auto',
    },
  },
}));

const PickInterests = (props) => {
  const { profile } = props;
  const history = useHistory();
  const [selectedTags, setSelectedTags] = useState({});
  const firestore = useFirestore();

  const [profileIsLoaded, setProfileIsLoaded] = useState(false);
  if (profile.isLoaded && !profileIsLoaded) {
    const profileTags = profile.interests ?? [];
    const tagsToUpdate = {};
    profileTags.forEach((attr) => (tagsToUpdate[attr] = true));
    setSelectedTags({
      ...selectedTags,
      ...tagsToUpdate,
    });
    setProfileIsLoaded(true);
  }

  const handleAddInterests = async (attrs) => {
    try {
      await firestore.update(
        { collection: 'users', doc: profile.uid },
        {
          interests: attrs,
        }
      );
      console.log('Interests added');
    } catch (error) {
      console.log('Error adding interests', error.toString());
    }
  };

  const isProfileComplete = () =>
    !(!profile.firstName || !profile.lastName || !profile.classYear);

  const handleContinue = () => {
    const attrs = [];
    for (let attr in selectedTags) {
      if (selectedTags[attr]) attrs.push(attr);
    }
    handleAddInterests(attrs).then(() => {
      if (isProfileComplete()) history.push('/onboarding/5');
      else {
        console.error(
          'Profile not finalized -- missing information. \nRedirecting to beginning of onboarding'
        );
        history.push(getLocationObj('/onboarding/1'));
      }
    });
  };

  const classes = useStyles();
  return (
    <div>
      <TabHeader text="Anything Else?" backButton />
      <p>
        Select any tags you like. It will help us do a better job at
        recommending things to you.
      </p>
      <TagsPicker
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <div className={classes.buttonWrapper}>
        <BigPrimaryButton
          variant="contained"
          color="primary"
          onClick={handleContinue}
        >
          Finish!
        </BigPrimaryButton>
      </div>
    </div>
  );
};

export default connect(({ firebase }) => ({
  profile: firebase.profile,
}))(PickInterests);
