import React from 'react';
import OrgsPickerContainer from '../Onboarding/OrgsPickerContainer';
import TabHeader from '../TabHeader';

const EditAcademicOrgs = () => {
  return (
    <div>
      <TabHeader text="Academic Orgs" backButton />
      <OrgsPickerContainer isDepartment={true} rerouteOnDone="/profile" />
    </div>
  );
};

export default EditAcademicOrgs;
