import React from 'react';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { CopyButton, ShareIconButton } from '../shared/ShareCopyButton.js';
import { Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledIcon = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const JoinButtons = (props) => {
  const { passcode, joinLink = '', isNonUser, eventName, eventUrl } = props;
  const disabled = isNonUser || !joinLink;

  return (
    <div style={{ background: isNonUser ? 'darkgray' : 'unset' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          paddingTop: '8px',
        }}
      >
        <Paper style={{ marginRight: '5px', width: '110px' }}>
          {' '}
          <Button
            disabled={disabled}
            variant="outlined"
            style={{ padding: '5px', width: '100%' }}
            href={'' + joinLink}
          >
            <StyledIcon>
              <PhoneIphoneIcon />
              Join Now
            </StyledIcon>
          </Button>
        </Paper>
        <CopyButton disabled={disabled} copyString={joinLink} />
        <ShareIconButton
          title={eventName}
          url={eventUrl}
          type={'eventDetails'}
        />
      </div>
      {passcode ? <p>{`Passcode: ${passcode}`}</p> : null}
      {isNonUser ? (
        <p>
          <span style={{ fontStyle: 'italic' }}>
            You'll need to sign in to view the link to this event.
          </span>
        </p>
      ) : null}
    </div>
  );
};

JoinButtons.propTypes = {
  passcode: PropTypes.string,
  joinLink: PropTypes.string,
  isNonUser: PropTypes.bool,
};

export default JoinButtons;
