import React from 'react';
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  IconButton,
} from '@material-ui/core';
import UserAvatar from '../shared/UserAvatar';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { makeUserCardStyles } from './styles';
import { connect, useSelector } from 'react-redux';
import TwineLytics from '../../twineLytics';
import { useHistory } from 'react-router-dom';
import { useFirestore } from 'react-redux-firebase';
import PropTypes from 'prop-types';
import { generateUserSlices, getLocationObj } from '../utils';
import {
  writeAcceptFriendRequest,
  writeDenyFriendRequest,
} from './socialUtils';

const useStyles = makeUserCardStyles();

const UserCard = (props) => {
  const { uid, isRequest, disableLink } = props;
  const { ownUserDoc } = props;

  const allUsers = useSelector(({ firestore }) => firestore.data?.meta?.users);
  const slice = generateUserSlices([uid], allUsers)[0] ?? {};
  const { firstName, lastName, classYear } = slice;

  const history = useHistory();
  const classes = useStyles();
  const firestore = useFirestore();

  const ownUid = ownUserDoc?.uid;
  const tl = new TwineLytics();
  return (
    <Card className={classes.root}>
      <CardActionArea
        onClick={() => history.push(getLocationObj(`/user/${uid}`))}
        disabled={disableLink}
      >
        <UserAvatar slice={slice} />
        <CardContent className={classes.content}>
          <h4>{`${firstName} ${lastName}`}</h4>
          {classYear ? (
            <p>{`Class of '${classYear.toString().slice(-2)}`}</p>
          ) : null}
        </CardContent>
      </CardActionArea>
      {isRequest ? (
        <CardActions>
          {/* STYLE(ML): Format after setting up prettier */}
          <IconButton
            onClick={() => {
              tl.logEvent('decline_request');
              writeDenyFriendRequest(firestore, ownUid, uid);
            }}
          >
            <ClearIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              tl.logEvent('accept_request');
              writeAcceptFriendRequest(firestore, ownUid, uid);
            }}
          >
            <CheckIcon />
          </IconButton>
        </CardActions>
      ) : null}
    </Card>
  );
};

UserCard.propTypes = {
  uid: PropTypes.string.isRequired,
  isRequest: PropTypes.bool,
  disableLink: PropTypes.bool,
};

export default connect(({ firebase }) => ({
  ownUserDoc: firebase.profile,
}))(UserCard);
