import React from 'react';
import { Grid } from '@material-ui/core';
import UserCard from './UserCard';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '5vw',
  },
  empty: {
    textAlign: 'left',
    '& p': {
      margin: theme.spacing(3),
    },
  },
}));

const UserList = ({ children, ...props }) => {
  const { uids = [], isRequests } = props;
  const classes = useStyles();

  if (uids.length === 0) return <div className={classes.empty}>{children}</div>;

  return (
    <Grid
      className={classes.root}
      direction="column"
      justify="flex-start"
      container
      spacing={1}
    >
      {uids.map((uid) => (
        <Grid item key={uid}>
          <UserCard uid={uid} isRequest={isRequests} />
        </Grid>
      ))}
    </Grid>
  );
};

UserList.propTypes = {
  uids: PropTypes.arrayOf(PropTypes.string).isRequired,
  isRequests: PropTypes.bool,
};

export default UserList;
