import React, { useState } from 'react';
import BigPrimaryButton from '../shared/BigPrimaryButton';
import { Drawer } from '@material-ui/core';
import { getLocationObj, isInStandaloneMode, isMobileBrowser } from '../utils';
import { useHistory } from 'react-router-dom';
import InstallPrompt from './InstallPrompt';
import PropTypes from 'prop-types';
import { makeGetStartedButtonStyles } from './styles';

const useStyles = makeGetStartedButtonStyles;

const GetStartedButton = ({ onClick, ...props }) => {
  const { buttonLabel, promoText } = props;
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    if (onClick) onClick();
    if (isMobileBrowser() && !isInStandaloneMode()) setShowInstallPrompt(true);
    else setTimeout(() => history.push(getLocationObj('/login')), 250);
  };

  return (
    <div className={classes.root}>
      {promoText ? <p>{promoText}</p> : null}
      <BigPrimaryButton
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        {buttonLabel}
      </BigPrimaryButton>
      <Drawer
        anchor="bottom"
        open={showInstallPrompt}
        onClose={() => setShowInstallPrompt(false)}
        classes={{ paper: classes.paper }}
      >
        <InstallPrompt />
      </Drawer>
    </div>
  );
};

GetStartedButton.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  promoText: PropTypes.string,
};

export default GetStartedButton;
