import React, { useState } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import { connect, useSelector } from 'react-redux';
import { makePeopleViewerStyles } from './styles';
import UserList from './UserList';
import { generateUserSlices } from '../utils';

const useStyles = makePeopleViewerStyles();

const emptyUserListTexts = [
  `You can search for your friends and add them from the Friends tab!`,
  `You have no pending friend requests.`,
];

const PeopleViewer = (props) => {
  const [selectedView, setSelectedView] = useState(0);
  const { profile, searchText = '' } = props;
  const handleChange = (event, newValue) => {
    setSelectedView(newValue);
  };

  const allUsers = useSelector(({ firestore }) => firestore.data?.meta?.users);

  const regex = new RegExp(searchText, 'ig');
  const matchRegex = ({ firstName, lastName }) =>
    `${firstName} ${lastName}`.match(regex);
  const sliceToUid = (slice) => slice.uid;

  const friends = profile?.friends || [];
  const friendRequests = profile?.friendRequests || [];

  const friendSlices = generateUserSlices(friends, allUsers);
  const friendIds = friendSlices.filter(matchRegex).map(sliceToUid);

  const friendRequestSlices = generateUserSlices(friendRequests, allUsers);
  const requestIds = friendRequestSlices.filter(matchRegex).map(sliceToUid);

  const displayedList = [friendIds, requestIds][selectedView];
  const classes = useStyles();
  return (
    <div>
      <Tabs
        variant="fullWidth"
        value={selectedView}
        onChange={handleChange}
        classes={{ root: classes.root, indicator: classes.indicator }}
        TabIndicatorProps={{ children: <span /> }}
      >
        <Tab label={`Friends\n(${friendIds.length})`} />
        <Tab label={`Requests\n(${requestIds.length})`} />
      </Tabs>
      <UserList uids={displayedList} isRequests={selectedView === 1}>
        <p>{emptyUserListTexts[selectedView]}</p>
      </UserList>
    </div>
  );
};

export default connect(({ firebase }) => ({
  profile: firebase.profile,
}))(PeopleViewer);
