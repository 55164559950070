import React from 'react';
import { connect } from 'react-redux';
import UserAvatar from '../shared/UserAvatar';
import UploadButton from './UploadButton';
import RemovePhotoButton from './RemovePhotoButton';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'flexStart',
    alignContent: 'center',
    boxSizing: 'border-box',
    height: '64px',
    paddingTop: theme.spacing(1),
  },
  avatar: {
    marginRight: theme.spacing(2),
    width: '64px',
    height: '64px',
  },
  buttons: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    '& div, form': {
      display: 'inline',
    },
  },
}));

const getSliceFromProfile = (profile) => {
  const { firstName = '', lastName = '', uid = '' } = profile;
  const { classYear, photoUrl = '' } = profile;
  return { firstName, lastName, uid, classYear, photoUrl };
};

const EditProfilePicture = (props) => {
  const { profile } = props;
  const slice = getSliceFromProfile(profile);
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <UserAvatar className={classes.avatar} slice={slice} size="large" />
      <div className={classes.buttons}>
        <UploadButton className="edit-avatar-upload" uid={profile.uid} />
        <span style={{ whiteSpace: 'pre-wrap' }}>{' / '}</span>
        <RemovePhotoButton className="edit-avatar-remove" uid={profile.uid} />
      </div>
    </div>
  );
};

export default connect(({ firebase }) => ({
  profile: firebase.profile,
}))(EditProfilePicture);
