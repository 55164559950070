import {
  SELECT_ACTIVE_PROFILE_TAB,
  SELECT_ACTIVE_ORG_TAB,
  SET_SHOW_INSTALL_PROMPT,
  SET_SHOW_UNFRIEND_CONFIRM,
  LOAD_ORGS_TO_NUM_EVENTS,
} from '../actions/types';

const initialState = {
  activeProfileTab: 0,
  activeOrgTab: 0,
  showInstallPrompt: false,
  showUnfriendConfirm: false,
  orgsToNumEvents: undefined,
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECT_ACTIVE_PROFILE_TAB:
      return { ...state, activeProfileTab: action.payload };
    case SELECT_ACTIVE_ORG_TAB:
      return { ...state, activeOrgTab: action.payload };
    case SET_SHOW_INSTALL_PROMPT:
      return { ...state, showInstallPrompt: action.payload };
    case SET_SHOW_UNFRIEND_CONFIRM:
      return { ...state, showUnfriendConfirm: action.payload };
    case LOAD_ORGS_TO_NUM_EVENTS:
      return { ...state, orgsToNumEvents: action.payload };
    default:
      return state;
  }
};

export default uiReducer;
