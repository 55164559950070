import React, { useState } from 'react';
import SearchBar from '../SearchBar';
import OrgTabs from './OrgTabs';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { firestoreConnect } from 'react-redux-firebase';
import TabHeader from '../TabHeader';
import LoadingView from '../LoadingView';
import { loadOrgsToNumEvents } from '../../actions/uiActions';

// TODO: Adjust to conform to firestore collection specs
const OrganizationsTab = (props) => {
  const {
    allOrgs = {},
    loggedIn,
    eventsMap,
    featuredOrg,
    featuredOrgDescription,
  } = props;
  const [searchText, setSearchText] = useState('');
  const regex = new RegExp(searchText, 'ig');
  const { orgsToNumEvents, loadOrgsToNumEvents } = props;
  const allEvents = [];
  if (eventsMap)
    Object.keys(eventsMap).forEach((eventId) =>
      allEvents.push(eventsMap[eventId])
    );

  const orgs = Object.entries(allOrgs).map(([orgId, orgData]) => ({
    orgId,
    ...orgData,
  }));

  const departments = orgs.filter(
    (slice) => slice.department && slice.name.match(regex)
  );
  const studentOrgs = orgs.filter(
    (slice) => !slice.department && slice.name.match(regex)
  );
  if (allEvents.length === 0 || Object.keys(allOrgs).length === 0) {
    return <LoadingView />;
  }

  if (orgsToNumEvents === undefined) {
    const map = {};
    for (const event of allEvents) {
      if (event?.owner?.id === undefined) {
        continue;
      }
      for (const org of orgs) {
        const orgId = org.orgId ?? null;
        if (event?.owner?.id === orgId) {
          if (!map[orgId]) {
            map[orgId] = 1;
          } else {
            map[orgId] += 1;
          }
        }
      }
    }
    loadOrgsToNumEvents(map);
  }

  return (
    <div>
      <TabHeader text="Organizations" />
      <SearchBar setSearchText={setSearchText} />
      {departments.length !== 0 || studentOrgs.length !== 0 ? (
        <OrgTabs
          dep={departments}
          org={studentOrgs}
          loggedIn={loggedIn}
          featuredOrg={featuredOrg}
          featuredOrgDescription={featuredOrgDescription}
          orgsToNumEvents={orgsToNumEvents}
        />
      ) : (
        <LoadingView />
      )}
    </div>
  );
};

const mapStateToProps = ({ firebase, firestore, ui }) => {
  return {
    allOrgs: firestore.data.meta?.organizations,
    loggedIn: firebase.auth.uid ? true : false,
    featuredOrg: firestore.data.meta?.appState?.featuredOrg,
    featuredOrgDescription:
      firestore.data.meta?.appState?.featuredOrgDescription,
    eventsMap: firestore?.composite?.visibleEvents,
    orgsToNumEvents: ui.orgsToNumEvents,
  };
};

export default compose(
  firestoreConnect(() => [
    { collection: 'meta', doc: 'organizations' },
    { collection: 'meta', doc: 'appState' },
  ]),
  connect(mapStateToProps, { loadOrgsToNumEvents })
)(OrganizationsTab);
