import React from 'react';
import { NavLink } from 'react-router-dom';
import { StyledWrapperDiv } from './styles';
import { ReactComponent as EventsIcon } from './calendar.svg';
import { ReactComponent as OrgsIcon } from './book.svg';
import { ReactComponent as FriendsIcon } from './users.svg';
import { ReactComponent as ProfileIcon } from './smile.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  link: {
    position: 'relative',
  },
  badge: {
    width: '4px',
    height: '4px',
    background: theme.palette.primary.main,
    position: 'absolute',
    top: '23px',
    right: '25px',
    borderRadius: '25%',
  },
}));

const TabBar = function (props) {
  const { requestsBadge } = props;
  const classes = useStyles();
  const badge = <div className={classes.badge} />;
  return (
    <StyledWrapperDiv>
      <NavLink to="/" exact activeClassName="tab-selected">
        <EventsIcon /> <br />
        Events
      </NavLink>
      <NavLink to="/organizations" activeClassName="tab-selected">
        <OrgsIcon /> <br />
        Orgs
      </NavLink>
      <NavLink
        to="/social"
        className={classes.link}
        activeClassName="tab-selected"
      >
        <FriendsIcon /> <br />
        {requestsBadge ? badge : null}
        Friends
      </NavLink>
      <NavLink to="/profile" activeClassName="tab-selected">
        <ProfileIcon /> <br />
        Profile
      </NavLink>
    </StyledWrapperDiv>
  );
};

export default TabBar;
