import React from 'react';
import {
  getEventTimeString,
  getEventDateString,
  getEventDateStringAbbr,
} from '../utils';

const EventDateTime = (props) => {
  const { startTime, endTime, abbr } = props;
  const date = abbr
    ? getEventDateStringAbbr(startTime)
    : getEventDateString(startTime);
  const time = getEventTimeString(startTime, endTime);
  return (
    <p>
      <span>{date}</span>
      <span>{time ? `, ${time}` : ''}</span>
    </p>
  );
};

export default EventDateTime;
