import React, { useState } from 'react';
import { Tabs, Tab, Drawer, IconButton } from '@material-ui/core';
import UserList from '../SocialTab/UserList';
import { makePeopleViewerStyles } from '../SocialTab/styles';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as XIcon } from '../shared/images/x.svg';
import PropTypes from 'prop-types';

const useViewerStyles = makePeopleViewerStyles();
const useStyles = makeStyles((theme) => ({
  paper: {
    height: `calc(100% - ${theme.spacing(6)}px)`,
    boxSizing: 'border-box',
    padding: '16px 24px',
  },
  header: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h1': {
      display: 'inline-block',
      position: 'relative',
      fontSize: '24px',
      lineHeight: '31px',
      color: theme.palette.primary.main,
      margin: '0px',
    },
  },
}));

const AttendeesViewer = (props) => {
  const { goingUsers, interestedUsers, open, toggle } = props;
  const [selectedView, setSelectedView] = useState(0);
  const handleChange = (event, newValue) => {
    setSelectedView(newValue);
  };

  const totalResponded = goingUsers.length + interestedUsers.length;
  let displayedList = [goingUsers, interestedUsers][selectedView];
  const viewerClasses = useViewerStyles();
  const classes = useStyles();

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => toggle(false)}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.header}>
        <h1>{`${
          totalResponded === 1 ? `1 Friend` : `${totalResponded} Friends`
        }  Responded`}</h1>
        <IconButton onClick={() => toggle(false)}>
          <XIcon />
        </IconButton>
      </div>
      <div>
        <Tabs
          variant="fullWidth"
          value={selectedView}
          onChange={handleChange}
          classes={{
            root: viewerClasses.root,
            indicator: viewerClasses.indicator,
          }}
          TabIndicatorProps={{ children: <span /> }}
        >
          <Tab label={`Going (${goingUsers.length})`} />
          <Tab label={`Interested (${interestedUsers.length})`} />
        </Tabs>
        <UserList uids={displayedList} isRequests={selectedView === 2}>
          <p>
            None of your friends are in this list yet. Share this event with
            them!
          </p>
        </UserList>
      </div>
    </Drawer>
  );
};

AttendeesViewer.propTypes = {
  goingUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  interestedUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

export default AttendeesViewer;
