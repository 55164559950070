import React from 'react';
import { Chip } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const TagChip = styled(({ highlight, ...other }) => <Chip {...other} />)({
  margin: '4px',
  fontSize: '12px',
  height: '24px',
  borderRadius: '25px',
  color: (props) => (props.highlight ? 'white' : 'black'),
  backgroundColor: (props) => (props.highlight ? '#2f80ed' : '#f2f2f2'),
  '&:focus': {
    backgroundColor: (props) => (props.highlight ? '#2f80ed' : '#f2f2f2'),
  },
});

export default TagChip;
