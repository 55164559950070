import React from 'react';
import TabHeader from '../TabHeader';
import LogOutButton from './LogOutButton';
import { connect } from 'react-redux';
import TutorialButton from './TutorialButton';
import { makeSettingsBaseStyles } from './styles';

const useStyles = makeSettingsBaseStyles;

const Settings = () => {
  const classes = useStyles();
  return (
    <div>
      <TabHeader text="Settings" backButton />
      <div className={classes.content}>
        <TutorialButton className={classes.item} />
        <LogOutButton className={classes.item} />
      </div>
    </div>
  );
};

export default connect(({ firebase }) => ({
  profile: firebase.profile,
}))(Settings);
