import React, { useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { firestoreConnect, useFirestore } from 'react-redux-firebase';
import { makeStyles } from '@material-ui/core/styles';
import BigPrimaryButton from '../shared/BigPrimaryButton';
import { useHistory } from 'react-router-dom';
import OrgsPicker from './OrgsPicker';
import { getLocationObj } from '../utils';

const useStyles = makeStyles({
  buttonWrapper: {
    width: '100%',
    position: 'fixed',
    bottom: '48px',
    pointerEvents: 'none',
    '& button': {
      pointerEvents: 'auto',
    },
  },
});

const OrgsPickerContainer = (props) => {
  const { allOrgs, profile, isDepartment, rerouteOnDone } = props;
  const history = useHistory();
  const [selectedOrgs, setSelectedOrgs] = useState({});
  const firestore = useFirestore();

  const classes = useStyles();

  const [profileIsLoaded, setProfileIsLoaded] = useState(false);
  if (profile.isLoaded && !profileIsLoaded) {
    const profileOrgIds = profile.orgs || [];
    const orgsToUpdate = {};
    profileOrgIds
      .filter((id) => allOrgs[id]?.department === isDepartment)
      .forEach((orgId) => (orgsToUpdate[orgId] = true));
    setSelectedOrgs({
      ...selectedOrgs,
      ...orgsToUpdate,
    });
    setProfileIsLoaded(true);
  }

  const handleUpdateStudentOrgs = async (orgIds) => {
    var batch = firestore.batch();
    const allRelevantOrgs = Object.entries(allOrgs)
      .filter(([_id, org]) => org.department === isDepartment)
      .map(([id, _]) => id);
    try {
      var userRemove = firestore.collection('users').doc(profile.uid);
      batch.update(userRemove, {
        orgs: firestore.FieldValue.arrayRemove(...allRelevantOrgs),
      });

      for (const orgId of allRelevantOrgs) {
        var orgRemove = firestore.collection('organizations').doc(orgId);
        batch.update(orgRemove, {
          followers: firestore.FieldValue.arrayRemove(profile.uid),
        });
      }
      if (Array.isArray(orgIds) && orgIds.length > 0) {
        var userAdd = firestore.collection('users').doc(profile.uid);
        batch.update(userAdd, {
          orgs: firestore.FieldValue.arrayUnion(...orgIds),
        });
        for (const orgId of orgIds) {
          var orgAdd = firestore.collection('organizations').doc(orgId);
          batch.update(orgAdd, {
            followers: firestore.FieldValue.arrayUnion(profile.uid),
          });
        }
      }
      await batch.commit();
    } catch (error) {
      console.error('Error following orgs', error.toString());
    }
  };

  const updateProfile = () => {
    handleUpdateStudentOrgs(
      Object.keys(selectedOrgs).filter((orgId) => selectedOrgs[orgId])
    );
    history.push(getLocationObj(rerouteOnDone));
  };

  if (!allOrgs) return <div>Loading...</div>;
  return (
    <>
      <OrgsPicker
        selectedOrgs={selectedOrgs}
        setSelectedOrgs={setSelectedOrgs}
        orgs={Object.entries(allOrgs)
          .filter(([_, org]) => org.department === isDepartment)
          .map(([orgId, org]) => ({ orgId, ...org }))}
      />
      <div className={classes.buttonWrapper}>
        <BigPrimaryButton
          variant="contained"
          color="primary"
          onClick={updateProfile}
        >
          Done
        </BigPrimaryButton>
      </div>
    </>
  );
};

const mapStateToProps = ({ firebase, firestore }) => {
  return {
    allOrgs: firestore.data.meta?.organizations ?? {},
    profile: firebase.profile,
  };
};
export default compose(
  connect(mapStateToProps),
  firestoreConnect(() => [{ collection: 'meta', doc: 'organizations' }])
)(OrgsPickerContainer);
